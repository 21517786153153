import React, { createContext, useContext, useState, useEffect } from 'react';
import { checkSubdomainValid } from '../../auth/_requests';

type DomainStatus = 'valid' | 'invalid' | null;

interface DomainStatusContextType {
  domainStatus: DomainStatus;
  setDomainStatus: React.Dispatch<React.SetStateAction<DomainStatus>>;
}

const DomainStatusContext = createContext<DomainStatusContextType | undefined>(undefined);

export const DomainStatusProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // Initialize state from localStorage or with 'valid' as a fallback
  const [domainStatus, setDomainStatus] = useState<DomainStatus>(() => {
    const storedStatus = localStorage.getItem('domainStatus');
    return storedStatus ? (storedStatus as DomainStatus) : 'valid';
  });
  useEffect(() => {
    const checkStatus = async () => {
      try {
        await checkSubdomainValid(setDomainStatus);
      } catch (error) {
        console.error('Error checking subdomain status:', error);
        setDomainStatus('invalid'); // Set to invalid on error
      }
    };
    checkStatus();
  }, [setDomainStatus]);
  useEffect(() => {
    if (domainStatus !== null) {
      localStorage.setItem('domainStatus', domainStatus);
    } else {
      localStorage.removeItem('domainStatus');
    }
  }, [domainStatus]);

  return (
    <DomainStatusContext.Provider value={{ domainStatus, setDomainStatus }}>
      {children}
    </DomainStatusContext.Provider>
  );
};

export const useDomainStatus = () => {
  const context = useContext(DomainStatusContext);
  if (context === undefined) {
    throw new Error('useDomainStatus must be used within a DomainStatusProvider');
  }
  return context;
};
