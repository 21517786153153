import { useState } from 'react';
import styles from './AddCard.module.css';
import { useModal } from '../../../../helpers/hooks/modalContext';
import { deleteCard } from '../../../../auth/_requests';
import { useAuth } from '../../../../auth/Auth';
import toast, { Toaster } from 'react-hot-toast';
import { useTheme } from '../../../../helpers/hooks/ThemeContext';

const RemoveCard = () => {
  const { modalState, setModal } = useModal();
  const { auth } = useAuth();
  const { editCard } = modalState || {};
  const [loading, setLoading] = useState(false);
  const { theme } = useTheme();

  const handleConfirm = async () => {
    if (!editCard) return;

    const tenantPaymentOptionId = editCard?.id;

    setLoading(true);

    try {
      const response = await deleteCard(auth?.data.token, tenantPaymentOptionId);
      if (response.message === 'success') {
        toast.remove();
        toast.success('Card removed successfully! Reloading....', {
          duration: 8000,
          style: {
            maxWidth: '327px',
            background: `${theme === 'dark' && '#000'}`,
            color: `${theme === 'dark' ? '#f5f5f5' : `#020b28`}`,
            border: `${theme === 'dark' && '1px solid #f5f5f5'}`,
          },
        });
        setTimeout(() => {
          window.location.reload();
        }, 5000);
        setModal(null);
      }
    } catch (err) {
      toast.remove();
      toast.error('Error deleting the payment option: ' + err, {
        duration: 8000,
        style: {
          maxWidth: '327px',
          background: `${theme === 'dark' && '#000'}`,
          color: `${theme === 'dark' ? '#f5f5f5' : `#020b28`}`,
          border: `${theme === 'dark' && '1px solid #f5f5f5'}`,
        },
      });
      return;
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setModal(null);
  };

  return (
    <>
      <Toaster position='top-center' reverseOrder={false} />
      <section
        className={`${styles.section} ${theme === 'dark' && styles.sectionDark} ${
          styles.sectionRemove
        }`}
      >
        <main
          className={`${styles.main} ${theme === 'dark' && styles.mainDark} ${styles.mainRemove}`}
        >
          <div className={styles.headingBox}>
            <div className={`${styles.heading} ${theme === 'dark' && styles.headingDark}`}>
              Remove Card!
            </div>
          </div>
          <button
            className={`${styles.cross} ${theme === 'dark' && styles.crossDark}`}
            onClick={handleCancel}
          >
            ✕
          </button>

          <div
            className={`${styles.confirmationText} ${
              theme === 'dark' && styles.confirmationTextDark
            }`}
          >
            Are you sure you want to remove the registered card?
          </div>

          <div className={styles.buttonContainer}>
            <button className={styles.cancelButton} onClick={handleCancel}>
              Cancel
            </button>
            <button className={styles.confirmButton} onClick={handleConfirm} disabled={loading}>
              {loading ? 'Removing...' : 'Remove'}
            </button>
          </div>
        </main>
      </section>
    </>
  );
};

export default RemoveCard;
