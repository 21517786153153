import bg from '../../assets/pages/dashboard/bg4.jpg';
import { useAuth } from '../../auth/Auth';
interface props {
  isLogOut?: boolean;
}
const BackgroundImage = ({ isLogOut = false }: props) => {
  const { logout } = useAuth();
  return (
    <>
      {isLogOut && (
        <button
          className={'absolute top-[20px] right-[20px] text-[var(--primary-color)] font-semibold'}
          onClick={() => {
            logout();
          }}
        >
          SignOut?
        </button>
      )}
      <div
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'top',
          height: '100vh',
          width: '100vw',
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: -1,
        }}
      ></div>
    </>
  );
};

export default BackgroundImage;
