import styles from './PaymentNav.module.css';
import { useTheme } from '../../../helpers/hooks/ThemeContext';
import visaImage from '../../../assets/pages/payment/visa.png';
import masterCardImage from '../../../assets/pages/payment/mastercard.png';
// import amexImage from '../../../assets/pages/payment/amex.png';
const PaymentNav = () => {
  const { theme } = useTheme();

  return (
    <main className={styles.main}>
      <section className={styles.section}>
        <h1 className={`${styles.title} ${theme === 'dark' && styles.titleDark}`}>
          Payment details
        </h1>
        <div className={styles.imageOuter}>
          <p className={`${styles.payText} ${theme === 'dark' && styles.payTextDark}`}>Pay with:</p>
          <div className='flex items-center'>
            <img src={visaImage} alt='Visa' className='h-6 ' />
            <img src={masterCardImage} alt='Mastercard' className='h-6 mx-4' />
            {/* <img src={amexImage} alt='Amex' className='w-[42px]' /> */}
          </div>
        </div>
      </section>
    </main>
  );
};
export default PaymentNav;
