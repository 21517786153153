import {FC, useState, useEffect, createContext, useContext, useRef, Dispatch, SetStateAction} from 'react';
import {LayoutSplashScreen} from '../helpers/MetronicSplashScreen';
import {AuthModel, UserModel} from '../auth/_models';
import * as authHelper from '../helpers/AuthHelpers';
import {getUserByToken} from './_requests';
import {WithChildren} from '../helpers/react18MigrationHelpers';

type AuthContextProps = {
    auth: AuthModel | undefined;
    saveAuth: (auth: AuthModel | undefined) => void;
    currentUser: UserModel | undefined;
    setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>;
    logout: () => void;
};

const initAuthContextPropsState = {
    auth: authHelper.getAuth(),
    saveAuth: () => {},
    currentUser: undefined,
    setCurrentUser: () => {},
    logout: () => {},
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
    return useContext(AuthContext);
};

const AuthProvider: FC<WithChildren> = ({children}) => {
    const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth());
    const [currentUser, setCurrentUser] = useState<UserModel | undefined>();
    const saveAuth = (auth: AuthModel | undefined) => {
        setAuth(auth);
        if (auth) {
            authHelper.setAuth(auth);
        } else {
            authHelper.removeAuth();
        }
    };

    const logout = () => {
        saveAuth(undefined);
        setCurrentUser(undefined);
        localStorage.removeItem('isSignUp');
        document.cookie = 'rememberMe=false; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    };

    return <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>{children}</AuthContext.Provider>;
};

const AuthInit: FC<WithChildren> = ({children}) => {
    const {auth, logout, setCurrentUser} = useAuth();
    const didRequest = useRef(false);
    const [showSplashScreen, setShowSplashScreen] = useState(true);
    // We should request user by authToken (auth.data.token) before rendering the application
    useEffect(() => {
        const requestUser = async (apiToken: string) => {
            try {
                if (!didRequest.current) {
                    const {data} = await getUserByToken(apiToken);
                    if (data) {
                        setCurrentUser(data);
                    }
                }
            } catch (error) {
                console.error(error);
                if (!didRequest.current) {
                    logout();
                }
            } finally {
                setShowSplashScreen(false);
            }

            return () => (didRequest.current = true);
        };

        if (auth && auth.data.token) {
            requestUser(auth.data.token);
        } else {
            logout();
            setShowSplashScreen(false);
        }
    }, []);

    return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

export {AuthProvider, AuthInit, useAuth};
