import React, { createContext, useContext, useState } from 'react';
import logoImage from '../../assets/pages/login/logo.png';
interface LogoContextType {
  logoUrl: string;
  setLogoUrl: React.Dispatch<React.SetStateAction<string>>;
  logoLoading: boolean;
  setLogoLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const LogoContext = createContext<LogoContextType | undefined>(undefined);

export const LogoProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [logoUrl, setLogoUrl] = useState<string>(logoImage);
  const [logoLoading, setLogoLoading] = useState<boolean>(true);

  return (
    <LogoContext.Provider value={{ logoUrl, setLogoUrl, logoLoading, setLogoLoading }}>
      {children}
    </LogoContext.Provider>
  );
};

export const useLogo = () => {
  const context = useContext(LogoContext);
  if (context === undefined) {
    throw new Error('useLogo must be used within a LogoProvider');
  }
  return context;
};
