import * as Yup from 'yup';
export const rntSchema = Yup.object().shape({
  property: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Property is required')
    .test('hasText', 'Property cannot be only spaces', (value) => {
      return /\S/.test(value);
    }),
  address: Yup.string()
    .min(8, 'Minimum 8 characters')
    .required('Address is required')
    .test('hasText', 'Address cannot be only spaces', (value) => {
      return /\S/.test(value);
    }),
  street: Yup.string()
    .min(1, 'Minimum 1 character')
    .max(50, 'Maximum 50 characters')
    .required('Street is required')
    .test('hasText', 'Street cannot be only spaces', (value) => {
      return /\S/.test(value);
    }),
  uniteNo: Yup.string()
    .matches(/^[a-zA-Z0-9]*$/, 'Unit number can only contain letters & numbers')
    .min(1, 'Minimum 1 character')
    .max(50, 'Maximum 50 characters'),
  house: Yup.string()
    .matches(/^[a-zA-Z0-9]*$/, 'Street number can only contain letters & numbers')
    .min(1, 'Minimum 1 character')
    .max(50, 'Maximum 50 characters'),
  city: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('City is required')
    .test('hasText', 'City cannot be only spaces', (value) => {
      return /\S/.test(value);
    }),
  province: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Province is required')
    .test('hasText', 'Province cannot be only spaces', (value) => {
      return /\S/.test(value);
    }),
  country: Yup.string().required('Country is required'),
  postal: Yup.string()
    .matches(/^[a-zA-Z0-9 ]*$/, 'Postal code can contain letters, numbers, and spaces')
    .min(3, 'Postal code must be at least 3 characters long')
    .max(10, 'Postal code cannot exceed 10 characters')
    .required('Postal code is required')
    .test('hasText', 'Postal code cannot be only spaces', (value) => {
      return /\S/.test(value);
    }),
  rnt: Yup.string()
    .matches(/^(RNT)\d{11}$/, 'TenantPay account number must start with RNT and be 11 digits')
    .required('TenantPay account number is required'),
  rent: Yup.string()
    .required('Rent Amount is required')
    .test(
      'is-greater-than-zero',
      'Rent amount must be greater than zero',
      (value) => parseFloat(value) > 0
    )
    .test('has-max-two-decimals', 'Rent amount cannot have more than two decimal places', (value) =>
      /^\d+(\.\d{0,2})?$/.test(value)
    )
    .test(
      'is-less-than-max',
      'Rent amount must be less than or equal to 1000000',
      (value) => parseFloat(value) <= 1000000
    )
    .typeError('Rent Amount must be a valid number'),
  leaseStart: Yup.string()
    .required('Lease start date is required')
    .matches(/^\d{2} [A-Z][a-z]{2} \d{4}$/, 'Invalid date format (DD MMM YYYY)'),
  leaseEnd: Yup.string()
    .required('Lease end date is required')
    .matches(/^\d{2} [A-Z][a-z]{2} \d{4}$/, 'Invalid date format (DD MMM YYYY)')
    .test('is-after-start', 'Lease End Date must be after Lease Start Date', function (value) {
      const leaseStart = this.parent.leaseStart;
      return new Date(value) > new Date(leaseStart);
    }),
  dueDay: Yup.string(),
});
