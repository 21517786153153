import styles from './AddPropertyModal.module.css';
import { useFormik } from 'formik';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { ApiResponse } from '../../utils/types';
import { addBuilding, checkPayNumber, getPresignedUrl, uploadToS3 } from '../../../auth/_requests';
import arrow from '../../../assets/pages/addProperty/personal-info/arrow.svg';
import { useAuth } from '../../../auth/Auth';
import { Spinner } from '../../Spinner/Spinner';
import countryIcon from '../../../assets/pages/addProperty/personal-info/canada.png';
import { FetchAddress, standardizeDate } from '../../utils/utils';
import ClickAwayListener from 'react-click-away-listener';
import uploadIcon from '../../../assets/pages/addProperty/rentalInfo/upload.svg';
import attach from '../../../assets/pages/addProperty/rentalInfo/attachment.svg';
import deleteIcon from '../../../assets/pages/addProperty/rentalInfo/delete.svg';
import { EditIcon } from 'lucide-react';
import { useModal } from '../../hooks/modalContext';
import { rntSchema } from '../../utils/addPropertyScehmas/rntSchema';
// import { useDomainStatus } from '../../hooks/DomainStatus';
import { addDays } from 'date-fns';
import DateCalendar from '../../DateCalendar/DateCalendar';
import { useTheme } from '../../hooks/ThemeContext';
import cross from '../../../assets/helpers/modals/addProperty/cross.png';
import crossWhite from '../../../assets/helpers/modals/addProperty/crossWhite.png';
import { Button, Tooltip, Whisper } from 'rsuite';
import info from '../../../assets/pages/payment/about.png';

const initialValues = {
  uniteNo: '',
  rnt: '',
  property: '',
  address: '',
  street: '',
  house: '',
  city: '',
  province: '',
  postal: '',
  country: 'Canada',
  rent: null as number | null,
  dueDay: 1,
  leaseStart: '',
  leaseEnd: '',
  document: '',
};

const AddProperty = () => {
  const { auth } = useAuth();
  const { theme } = useTheme();
  const [loading, setLoading] = useState(false);
  // const { domainStatus } = useDomainStatus();
  const { setModal, modalState } = useModal();
  const [formattedRent, setFormattedRent] = useState<string>('');
  const addressInputRef = useRef(null);
  const [isMenuOpen, setMenu] = useState(false);
  const [selectedFile, setSelectedFile] = useState('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [dueDay, setDueDay] = useState<number>(1);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [fileName, setFileName] = useState<string | undefined>('');
  const [isUploading, setIsUploading] = useState(false);
  const [docUrl, setdocumentUrl] = useState('');
  const [isManualAddress, setIsManualAddress] = useState(false);
  const [isTrigger, setIsTrigger] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isZoomedOut, setIsZoomedOut] = useState(false);
  const [isAutoCompleteOpen, setIsAutocompleteOpen] = useState(false);
  const [isCompany, setIsCompany] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: rntSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const requestBody = {
          buildingDetails: {
            payNumber: values.rnt,
            unitNumber: values.uniteNo,
            buildingName: values.property,
            rentAmount: Number(values.rent),
            dueDate: values.dueDay,
            leaseStartDate: standardizeDate(values.leaseStart),
            leaseEndDate: standardizeDate(values.leaseEnd),
            leaseDocumentUrl: selectedFile,
          },
          addressDetails: {
            buildingNumber: values.house,
            streetName: values.street,
            city: values.city,
            province: values.province,
            country: values.country,
            countryCode: 'CA',
            postalCode: values.postal,
            fullAddress: values.address,
          },
        };
        if (formik.isValid) {
          await addBuilding(auth?.data.token, requestBody);
          setStatus('');
          setLoading(false);
          setModal('property-successful');
        } else {
          setStatus('Please fill all required fields');
          setLoading(false);
        }
      } catch (error) {
        const customError = error as ApiResponse;
        if (customError.message === 'payNumber_not_found') {
          setStatus(`We can't find that TenantPay account number. Please verify and try again.`);
          formik.setFieldValue('rnt', '');
          formik.setFieldTouched('rnt');
        } else {
          setStatus('Please fill all required fields');
        }
        console.error('Error Adding buildings:', error);
        setSubmitting(false);
        setLoading(false);
      }
    },
  });
  const openDocument = () => {
    if (selectedFile) {
      window.open(docUrl, '_blank');
    }
  };
  const validatePayNumber = async (payNumber: string) => {
    if (/^RNT\d{11}$/.test(payNumber)) {
      // Validate format
      try {
        const response = await checkPayNumber(auth?.data.token, payNumber);
        if (response.message === 'pay_number_exists') {
          setIsValid(true); // Set as valid
        } else {
          setIsValid(false); // Set as invalid
        }
      } catch (error) {
        if (
          error instanceof Error &&
          (error.message === 'pay_number_not_found' ||
            error.message === 'pay_number_does_not_belong_to_the_company')
        ) {
          setIsValid(false);
          if (error.message === 'pay_number_does_not_belong_to_the_company') {
            setIsCompany(true);
          }
        } else {
          console.error('An unexpected error occurred:', (error as Error).message);
        }
      }
    } else {
      setIsValid(false); // Invalid format
    }
  };
  const handleRentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    value = value.replace(/[^0-9.]/g, '');
    if (value && value !== '0') {
      const parts = value.split('.');
      if (parts.length === 2) {
        setFormattedRent(`${parseInt(parts[0], 10).toLocaleString()}.${parts[1]}`);
      } else {
        setFormattedRent(parseInt(value, 10).toLocaleString());
      }
      formik.setFieldValue('rent', value);
    } else {
      setFormattedRent(value);
      formik.setFieldValue('rent', value);
    }
  };
  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    formik.setFieldValue('document', file);
    const { url, uploadUrl, downloadUrl } = await getPresignedUrl(
      file?.name as string,
      file?.type as string,
      auth?.data.token,
      'lease-document'
    );
    setFileName(file?.name);
    setIsUploading(true);
    await uploadToS3(file as File, uploadUrl);
    setIsUploading(false);
    setdocumentUrl(downloadUrl);
    setSelectedFile(url);
  };
  useEffect(() => {
    if (modalState?.payNumber) {
      setIsValid(true);
      formik.setFieldValue('rnt', modalState.payNumber);
      formik.setTouched({ rnt: true });
    } else {
      formik.setFieldValue('rnt', '');
    }
  }, []);
  useEffect(() => {
    const handleZoom = () => {
      const zoomLevel = Math.round((window.outerWidth / window.innerWidth) * 100);
      setIsZoomedOut(zoomLevel < 70);
    };

    window.addEventListener('resize', handleZoom);
    handleZoom();

    return () => {
      window.removeEventListener('resize', handleZoom);
    };
  }, []);
  if (loading) {
    return <Spinner />;
  }
  return (
    <section className={styles.section}>
      <FetchAddress
        addressInputRef={addressInputRef}
        formik={formik}
        isSaveManually={setIsManualAddress}
        setIsAutocompleteOpen={setIsAutocompleteOpen}
      />
      <main
        className={`${styles.main} ${theme === 'dark' && styles.mainDark}  ${
          isZoomedOut ? styles.relative : styles.unset
        } ${isAutoCompleteOpen && formik.values.address !== '' && styles.mainHidden}`}
      >
        <div className={styles.headingBox}>
          <div className={`${styles.heading} ${theme === 'dark' && styles.headingDark}`}>
            Add Property
          </div>
        </div>
        <button className={`${styles.cross} `} onClick={() => setModal(null)}>
          <img src={theme === 'dark' ? crossWhite : cross} alt='cross' className={styles.cross} />
        </button>
        {formik.status && (
          <div className={styles.statusBox}>
            <div className={styles.status}>{formik.status}</div>
          </div>
        )}
        <div className={styles.formOuter}>
          <form className={styles.form} noValidate onSubmit={formik.handleSubmit}>
            <div className={styles.formInner}>
              <div className={styles.formBoxes}>
                <div className={styles.tenantBox}>
                  <div
                    className={styles.upperBox}
                    title={`TenantPay account Number starts with 'RNT' followed by 11 digits`}
                  >
                    <div className={`${styles.tenantHead} ${theme === 'dark' && styles.headDark}`}>
                      TenantPay Account Number<span className={styles.mandatory}>*</span>
                    </div>
                    <div className={styles.subHeadWrap}>
                      <div className={styles.tenantSubhead}>
                        Starts with letters RNT followed by 11 digits
                      </div>
                      <div className={styles.linkBox}>
                        <button
                          // onClick={(e) => {
                          //   e.preventDefault();
                          //   {
                          //     domainStatus === 'invalid'
                          //       ? navigate('/add-property-manager')
                          //       : navigate('/generate-rnt/select-building');
                          //   }
                          // }}
                          onClick={(e) => {
                            e.preventDefault();
                            setModal('rnt-not-found');
                          }}
                        >
                          {' '}
                          Don't have a TenantPay account number?
                        </button>
                      </div>
                    </div>
                    <input
                      type='text'
                      autoComplete='off'
                      disabled={modalState?.disableRNT}
                      {...formik.getFieldProps('rnt')}
                      placeholder='RNT12345678912'
                      className={clsx(
                        formik.touched.rnt && !isValid ? styles.isInvalid : '',
                        formik.touched.rnt && isValid ? styles.valid : '',
                        `${styles.tenantInput} ${theme === 'dark' && styles.darkInput}`
                      )}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                        }
                      }}
                      onChange={(event) => {
                        const value = event.target.value;
                        const regex = /^[a-zA-Z0-9]*$/;
                        if (regex.test(value)) {
                          validatePayNumber(value);
                          formik.setFieldValue('rnt', value);
                        }
                      }}
                    />
                    {formik.touched.rnt && formik.errors.rnt && !modalState?.payNumber && (
                      <div className={styles.error}>
                        <span>{formik.errors.rnt}</span>
                      </div>
                    )}
                    {formik.touched.rnt && !isValid && !formik.errors.rnt && (
                      <div className={styles.error}>
                        <span>
                          {!isCompany
                            ? 'TenantPay account number not found. Please check it and try again.'
                            : 'TenantPay account number does not belong to the company'}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.lastName} title='Enter the name of your rental property'>
                  <label className={styles.label}>
                    Property Name<span className={styles.mandatory}>*</span>
                  </label>
                  <div
                    className={clsx(
                      formik.touched.property && formik.errors.property ? styles.isInvalid : '',
                      formik.touched.property && !formik.errors.property ? styles.valid : '',
                      `${styles.passwordWrapper}`,
                      styles.propertyOuter
                    )}
                  >
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('property')}
                      placeholder='Greenview Apartments'
                      className={`${styles.phoneInput} ${
                        theme === 'dark' && styles.phoneInputDark
                      }`}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                        }
                      }}
                    />
                  </div>
                  {formik.touched.property && formik.errors.property && (
                    <div className={styles.error}>
                      <span>{formik.errors.property}</span>
                    </div>
                  )}
                </div>

                <div
                  className={styles.firstName}
                  title='Enter the full address of your rental property'
                >
                  <div className={styles.labelOuter}>
                    <label className={styles.label}>
                      Address<span className={styles.mandatory}>*</span>
                    </label>
                    <button
                      type='button'
                      onClick={() => setIsManualAddress(!isManualAddress)}
                      className={styles.buttonLabel}
                    >
                      <div className={styles.label}>
                        {isManualAddress ? 'Save' : 'Enter manually'}
                      </div>
                      {!isManualAddress && (
                        <EditIcon className={styles.editIcon} stroke='#868c99' />
                      )}
                    </button>
                  </div>
                  <input
                    type={'text'}
                    ref={addressInputRef}
                    autoComplete='off'
                    {...formik.getFieldProps('address')}
                    placeholder='360 Saint-Antoine St W, Montreal, Quebec H2Y 3X4, Canada'
                    className={clsx(
                      'form-control bg-transparent',
                      formik.touched.address && formik.errors.address ? styles.isInvalid : '',
                      formik.touched.address && !formik.errors.address ? styles.valid : '',
                      styles.passwordWrapper,
                      styles.addressFull,
                      theme === 'dark' && styles.darkInput
                    )}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                      }
                    }}
                  />

                  {formik.touched.address && formik.errors.address && (
                    <div className={styles.error}>
                      <span>{formik.errors.address}</span>
                    </div>
                  )}
                </div>
                {isManualAddress && (
                  <>
                    <div className={styles.nameBox}>
                      <div
                        className={styles.firstName}
                        title='Enter the building or street number of your rental property'
                      >
                        <label className={styles.label}>Building/Street Number</label>
                        <input
                          type='text'
                          autoComplete='off'
                          {...formik.getFieldProps('house')}
                          placeholder='360'
                          className={clsx(
                            formik.touched.house &&
                              formik.errors.house &&
                              formik.values.house !== ''
                              ? styles.isInvalid
                              : '',
                            formik.touched.house &&
                              !formik.errors.house &&
                              formik.values.house !== ''
                              ? styles.valid
                              : '',
                            `${styles.inputBox}  ${theme === 'dark' && styles.phoneInputDark}`
                          )}
                          onKeyDown={(e) => {
                            if (
                              (e.key === ' ' && !(e.target as HTMLInputElement).value) ||
                              e.key === 'Enter'
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />

                        {formik.touched.house && formik.errors.house && (
                          <div className={styles.error}>
                            <span>{formik.errors.house}</span>
                          </div>
                        )}
                      </div>
                      <div className={styles.firstName} title='Enter the unit number if applicable'>
                        <label className={styles.label}>Unit Number</label>
                        <div
                          className={clsx(
                            formik.touched.uniteNo &&
                              formik.errors.uniteNo &&
                              formik.values.uniteNo !== ''
                              ? styles.isInvalid
                              : '',
                            formik.touched.uniteNo &&
                              !formik.errors.uniteNo &&
                              formik.values.uniteNo !== ''
                              ? styles.valid
                              : '',
                            styles.passwordWrapper
                          )}
                        >
                          <input
                            type={'text'}
                            autoComplete='off'
                            {...formik.getFieldProps('uniteNo')}
                            className={clsx(
                              'form-control bg-transparent',
                              `${styles.passwordBox}  ${theme === 'dark' && styles.phoneInputDark}`
                            )}
                            placeholder='456'
                            onKeyDown={(e) => {
                              if (
                                (e.key === ' ' && !(e.target as HTMLInputElement).value) ||
                                e.key === 'Enter'
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                        {formik.touched.uniteNo && formik.errors.uniteNo && (
                          <div className={styles.error}>
                            <span>{formik.errors.uniteNo}</span>
                          </div>
                        )}
                      </div>
                    </div>

                    <div
                      className={styles.lastName}
                      title='Enter the street name of your rental property'
                    >
                      <label className={styles.label}>
                        Street Name<span className={styles.mandatory}>*</span>
                      </label>
                      <input
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('street')}
                        placeholder='Saint-Antoine St W'
                        className={clsx(
                          formik.touched.street && formik.errors.street ? styles.isInvalid : '',
                          formik.touched.street && !formik.errors.street ? styles.valid : '',
                          `${styles.inputBox}  ${theme === 'dark' && styles.phoneInputDark}`,
                          styles.propertyOuter
                        )}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            event.preventDefault();
                          }
                        }}
                      />
                      {formik.touched.street && formik.errors.street && (
                        <div className={styles.error}>
                          <span>{formik.errors.street}</span>
                        </div>
                      )}
                    </div>

                    <div className={styles.nameBox}>
                      <div
                        className={styles.firstName}
                        title='Enter the city where your rental property is located'
                      >
                        <label className={styles.label}>
                          City<span className={styles.mandatory}>*</span>
                        </label>
                        <input
                          type='text'
                          autoComplete='off'
                          {...formik.getFieldProps('city')}
                          placeholder='Montreal'
                          className={clsx(
                            formik.touched.city && formik.errors.city ? styles.isInvalid : '',
                            formik.touched.city && !formik.errors.city ? styles.valid : '',
                            `${styles.inputBox}  ${theme === 'dark' && styles.phoneInputDark}`
                          )}
                          onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                              event.preventDefault();
                            }
                          }}
                        />
                        {formik.touched.city && formik.errors.city && (
                          <div className={styles.error}>
                            <span>{formik.errors.city}</span>
                          </div>
                        )}
                      </div>
                      <div
                        className={styles.lastName}
                        title='Enter the province of your rental property'
                      >
                        <label className={styles.label}>
                          Province<span className={styles.mandatory}>*</span>
                        </label>
                        <div
                          className={clsx(
                            formik.touched.province && formik.errors.province
                              ? styles.isInvalid
                              : '',
                            formik.touched.province && !formik.errors.province ? styles.valid : '',
                            `${styles.phoneInputBox}`
                          )}
                        >
                          <input
                            type='text'
                            autoComplete='off'
                            {...formik.getFieldProps('province')}
                            placeholder='Quebec'
                            className={`${styles.phoneInput} ${
                              theme === 'dark' && styles.phoneInputDark
                            }`}
                            onKeyDown={(event) => {
                              if (event.key === 'Enter') {
                                event.preventDefault();
                              }
                            }}
                          />
                        </div>
                        {formik.touched.province && formik.errors.province && (
                          <div className={styles.error}>
                            <span>{formik.errors.province}</span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={styles.nameBox}>
                      <div
                        className={styles.firstName}
                        title='Enter the postal code of your rental property'
                      >
                        <label className={styles.label}>
                          Postal Code<span className={styles.mandatory}>*</span>
                        </label>
                        <input
                          type='text'
                          autoComplete='off'
                          {...formik.getFieldProps('postal')}
                          placeholder='H2Y 3X4'
                          className={clsx(
                            formik.touched.postal && formik.errors.postal ? styles.isInvalid : '',
                            formik.touched.postal && !formik.errors.postal ? styles.valid : '',
                            `${styles.inputBox}  ${theme === 'dark' && styles.phoneInputDark}`
                          )}
                          onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                              event.preventDefault();
                            }
                          }}
                        />
                        {formik.touched.postal && formik.errors.postal && (
                          <div className={styles.error}>
                            <span>{formik.errors.postal}</span>
                          </div>
                        )}
                      </div>
                      <div className={styles.lastName} title='Your country is set to Canada'>
                        <label className={styles.label}>
                          Country<span className={styles.mandatory}>*</span>
                        </label>
                        <div
                          className={clsx(
                            `${styles.countryInputBox} ${theme === 'dark' && styles.whiteCountry}`
                          )}
                        >
                          <img
                            src={countryIcon}
                            alt='country-logo'
                            className={styles.countryLogo}
                          />
                          <div className={styles.countryName}>Canada</div>
                        </div>
                        {formik.touched.country && formik.errors.country && (
                          <div className={styles.error}>
                            <span>{formik.errors.country}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
                <div className={styles.nameBox}>
                  <div className={styles.lastName} title='Enter rent amount'>
                    <label className={styles.label}>
                      Rent Amount($)<span className={styles.mandatory}>*</span>
                    </label>
                    <div
                      className={clsx(
                        formik.touched.rent && formik.errors.rent ? styles.isInvalid : '',
                        formik.touched.rent && !formik.errors.rent ? styles.valid : '',
                        `${styles.phoneInputBox}`
                      )}
                    >
                      {formik.values.rent && (
                        <span
                          className={`${styles.dollarSign} ${
                            theme === 'dark' && styles.dollarWhite
                          }`}
                        >
                          $
                        </span>
                      )}
                      <input
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('rent')}
                        value={
                          formattedRent ||
                          (formik.values.rent !== null ? formik.values.rent.toLocaleString() : '')
                        }
                        placeholder='1,750'
                        className={`${styles.phoneInput} ${
                          theme === 'dark' && styles.phoneInputDark
                        }`}
                        onChange={handleRentChange}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            event.preventDefault();
                          }
                        }}
                      />
                    </div>
                    {formik.touched.rent && formik.errors.rent && (
                      <div className={styles.error}>
                        <span>{formik.errors.rent}</span>
                      </div>
                    )}
                  </div>
                  <div className={clsx(styles.firstName, styles.dueDate)}>
                    <div className={styles.tooltipWrap}>
                      <label className={styles.label}>
                        Monthly Due Date<span className={styles.mandatory}>*</span>
                      </label>
                      <Whisper
                        trigger='hover'
                        placement={'right'}
                        controlId={`control-id-top`}
                        speaker={
                          <Tooltip>
                            <p>
                              Select a day between the 1st and 28th—your landlord will receive the
                              rent payment by this day each month
                            </p>
                          </Tooltip>
                        }
                      >
                        <div className={'signUpBtn'}>
                          <Button appearance='subtle' className={styles.btnImg}>
                            <img src={info} className={styles.infoIcon} alt='info' />
                          </Button>
                        </div>
                      </Whisper>
                    </div>
                    <ClickAwayListener onClickAway={() => setMenu(false)}>
                      <div>
                        <div
                          className={`${styles.dueDateOuter} ${
                            theme === 'dark' && styles.phoneInputDark
                          }`}
                          onClick={() => setMenu(!isMenuOpen)}
                        >
                          <div className={styles.selectedDay}>{dueDay}</div>
                          <img src={arrow} alt='arrow' className={styles.arrowSelected} />
                        </div>

                        {isMenuOpen && (
                          <div
                            className={`${styles.selectMenu} ${
                              theme === 'dark' && styles.selectMenuDark
                            }`}
                          >
                            {Array.from({ length: 28 }, (_, index) => index + 1).map((number) => (
                              <div
                                key={number}
                                className={clsx(
                                  styles.selectMenuOption,
                                  theme === 'dark' && styles.selectMenuOptionDark,
                                  `${dueDay === number && styles.selectedOption}`
                                )}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setDueDay(number);
                                  setMenu(false);
                                  formik.setFieldValue('dueDay', number);
                                }}
                              >
                                {number}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </ClickAwayListener>
                    {formik.touched.dueDay && formik.errors.dueDay && (
                      <div className={styles.error}>
                        <span>{formik.errors.dueDay}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.nameBox}>
                  <div className={styles.firstName} title='Select the start date of your lease'>
                    <label className={styles.label}>
                      Lease Start Date<span className={styles.mandatory}>*</span>
                    </label>
                    <div
                      className={`${styles.dateOuter} ${theme === 'dark' && styles.phoneInputDark}`}
                    >
                      <DateCalendar
                        startDate={startDate}
                        maxDate={addDays(endDate as Date, -1)}
                        onDateChange={setStartDate}
                        formik={formik}
                        type='leaseStart'
                        placement='topEnd'
                      />
                    </div>

                    {formik.touched.leaseStart && formik.errors.leaseStart && (
                      <div className={styles.error}>
                        <span>{formik.errors.leaseStart}</span>
                      </div>
                    )}
                  </div>
                  <div className={styles.firstName} title='Select the end date of your lease'>
                    <label className={styles.label}>
                      Lease End Date<span className={styles.mandatory}>*</span>
                    </label>
                    <div
                      className={`${styles.dateOuter} ${theme === 'dark' && styles.phoneInputDark}`}
                    >
                      <DateCalendar
                        startDate={endDate}
                        minDate={addDays(startDate as Date, 1)}
                        onDateChange={setEndDate}
                        formik={formik}
                        type='leaseEnd'
                        placement='topEnd'
                      />
                    </div>

                    {formik.errors.leaseEnd && formik.touched.leaseEnd && (
                      <div className={styles.error}>
                        <span>{formik.errors.leaseEnd}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  title='Upload the lease document related to your rental property'
                  className={`${styles.uploadBox} ${selectedFile && styles.uploadedFile}`}
                  onClick={() => {
                    if (fileInputRef.current !== undefined) {
                      fileInputRef.current?.click();
                    }
                  }}
                >
                  {!selectedFile && (
                    <img src={uploadIcon} alt='uploadIcon' className={styles.uploadIcon} />
                  )}
                  {!selectedFile && <div className={styles.textUpload}>Upload Lease Document</div>}
                  {selectedFile !== '' && (
                    <div className={styles.uploadOuter}>
                      <img src={attach} alt='attachment' className={styles.attachment} />
                      <button
                        className={styles.textUploaded}
                        onClick={(e) => {
                          e.preventDefault();
                          openDocument();
                        }}
                      >
                        {fileName}
                      </button>
                    </div>
                  )}
                  {selectedFile && (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedFile('');
                        formik.setFieldValue('document', null);
                      }}
                    >
                      <img src={deleteIcon} alt='delete' className={styles.deleteIcon} />
                    </button>
                  )}
                </div>
                {isUploading && (
                  <div
                    className={`${styles.uploadingText} ${
                      theme === 'dark' && styles.phoneInputDark
                    }`}
                  >
                    Uploading...
                  </div>
                )}
                {formik.touched.document && formik.errors.document && (
                  <div className={styles.error}>
                    <span>{formik.errors.document}</span>
                  </div>
                )}
                {isTrigger && !isUploading && !selectedFile && (
                  <div className={styles.error}>
                    <span>{'Lease Document is required'}</span>
                  </div>
                )}

                <input
                  onChange={handleFileChange}
                  multiple={false}
                  ref={fileInputRef}
                  type={selectedFile === '' ? 'file' : ''}
                  hidden
                  accept='image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf'
                />
              </div>
              <div className={styles.buttonWrap}>
                <button
                  type='submit'
                  disabled={!formik.isValid || !isValid}
                  onClick={() => {
                    setIsTrigger(true);
                    setIsManualAddress(
                      !formik.values.street ||
                        !formik.values.city ||
                        !formik.values.province ||
                        !formik.values.postal
                    );
                  }}
                  className={`${styles.submitBtn} ${theme === 'dark' && styles.saveBtnTextDark} ${
                    (formik.isSubmitting || !formik.isValid || !selectedFile || !isValid) &&
                    styles.disableBtn
                  }`}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </main>
    </section>
  );
};
export default AddProperty;
