import axios from 'axios';
import { AUTH_LOCAL_STORAGE_KEY } from '../helpers/AuthHelpers';
import toast from 'react-hot-toast';

export const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

const handleLogout = () => {
  localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
  window.location.href = '/login';
};

// Response interceptor to handle global errors
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const message = error?.response?.data?.message;
    const status = error?.response?.status;
    if (message === 'tenant_is_not_active') {
      handleLogout();
    }
    if (status === 429) {
      toast.error('Too many requests. Try again later after a few seconds.', {
        duration: 4000,
        style: {
          maxWidth: '327px',
        },
      });
    }
    return Promise.reject(error);
  }
);
