import { useState } from 'react';
import styles from './Navbar.module.css';
import { useNavigate } from 'react-router-dom';
import { useLogo } from '../hooks/LogoContext';
import demoImage from '../../assets/helpers/navbar/user.png';
import { useAuth } from '../../auth/Auth';
import ClickAwayListener from 'react-click-away-listener';
import { useProfileImage } from '../hooks/profilePhoto';
import profile from '../../assets/helpers/navbar/profile.png';
import settings from '../../assets/helpers/navbar/settings.png';
import logOut from '../../assets/helpers/navbar/logout.png';
import right from '../../assets/helpers/navbar/rightArrow.png';
import rightWhite from '../../assets/helpers/navbar/rightWhite.png';
import clsx from 'clsx';
import { useTheme } from '../hooks/ThemeContext';
import { useModal } from '../hooks/modalContext';
import light from '../../assets/helpers/navbar/light.png';
import dark from '../../assets/helpers/navbar/dark.png';
import darkLogo from '../../assets/helpers/navbar/tenantpay-white.png';
import dashboardDark from '../../assets/helpers/navbar/dashboardDark.png';
import dashboardWhite from '../../assets/helpers/navbar/dashboard.png';
import profileWhite from '../../assets/helpers/navbar/profileWhite.png';
import settingsWhite from '../../assets/helpers/navbar/settingsWhite.png';
import logOutWhite from '../../assets/helpers/navbar/logOutWhite.png';
import { useDomainStatus } from '../hooks/DomainStatus';

const Navbar = () => {
  const { logout, currentUser } = useAuth();
  const { setModal } = useModal();
  const navigate = useNavigate();
  const { logoUrl, logoLoading } = useLogo();
  const { profileImageUrl } = useProfileImage();
  const [isOpenDropdown, setDropdown] = useState(false);
  const { theme, toggleTheme } = useTheme();
  const { domainStatus } = useDomainStatus();
  const handleBtnClick = (link: string) => {
    navigate(`/${link}`);
  };
  const matchRouteName = (routeName: string) => {
    return routeName === location.pathname;
  };

  const navbarBgGradient = 'background(rgba(0,0,0,0.8))';
  return (
    <section className={clsx(styles.navBar, { [styles.dark]: theme === 'dark' })}>
      <section
        className={clsx(styles.navContainer, { [styles.dark]: theme === 'dark' })}
        style={theme === 'dark' ? {} : { background: navbarBgGradient }}
      >
        <div className={styles.navContent}>
          <button onClick={() => handleBtnClick('dashboard')}>
            {!logoLoading && (
              <img
                src={theme === 'dark' && domainStatus === 'invalid' ? darkLogo : logoUrl}
                className={styles.logo}
                alt='logo'
              />
            )}
          </button>

          <div className={styles.listInner}>
            <button className={styles.menu} onClick={() => handleBtnClick('dashboard')}>
              <div
                className={matchRouteName('/dashboard') ? styles.menuNameActive : styles.menuName}
              >
                Dashboard
                <div className={styles.menuLine}></div>
              </div>
            </button>
            <button className={styles.menu} onClick={() => handleBtnClick('profile')}>
              <div className={matchRouteName('/profile') ? styles.menuNameActive : styles.menuName}>
                Profile
                <div className={styles.menuLine}></div>
              </div>
            </button>
            {/*  <button className={styles.menu} onClick={logout}>
              <div className={styles.menuName}>
                Sign Out <div className={styles.menuLine}></div>
              </div>
            </button> */}
          </div>
          <div className={styles.userToggle}>
            {domainStatus !== 'valid' && (
              <button onClick={toggleTheme}>
                <img
                  src={theme === 'light' ? light : dark}
                  alt='theme'
                  className={styles.lightMode}
                />
              </button>
            )}

            <button onClick={() => setDropdown(!isOpenDropdown)} className={styles.userOuter}>
              {profileImageUrl ? (
                <img src={profileImageUrl} alt='user' className={styles.user} />
              ) : (
                <img src={demoImage} alt='demoUser' className={styles.userDemo} />
              )}
            </button>
            {isOpenDropdown && (
              <ClickAwayListener onClickAway={() => setDropdown(false)}>
                <div className={clsx(styles.dropdownOuter, { [styles.dark]: theme === 'dark' })}>
                  <div className={styles.userOuterMenu}>
                    {profileImageUrl ? (
                      <img src={profileImageUrl} alt='user' className={styles.user} />
                    ) : (
                      <img src={demoImage} alt='demoUser' className={styles.userDemo} />
                    )}
                    <div className={styles.name}>{`${currentUser?.user?.email}`}</div>
                  </div>
                  <div className={styles.dropdownList}>
                    <div
                      className={`${styles.liOuter} ${styles.mobMenu}`}
                      onClick={(e) => {
                        e.preventDefault();
                        setDropdown(false);
                        handleBtnClick('dashboard');
                      }}
                    >
                      <div className={styles.liName}>
                        <img
                          src={theme === 'dark' ? dashboardWhite : dashboardDark}
                          alt='icon'
                          className={styles.liIcon}
                        />
                        <div className={styles.listName}>Dashboard</div>
                      </div>
                      <img
                        src={theme === 'light' ? right : rightWhite}
                        alt='arrow'
                        className={styles.arrow}
                      />
                    </div>
                    <div
                      className={`${styles.liOuter} ${styles.mobMenu}`}
                      onClick={(e) => {
                        e.preventDefault();
                        setDropdown(false);
                        handleBtnClick('profile');
                      }}
                    >
                      <div className={styles.liName}>
                        <img
                          src={theme === 'dark' ? profileWhite : profile}
                          alt='icon'
                          className={styles.liIcon}
                        />
                        <div className={styles.listName}>Profile</div>
                      </div>
                      <img
                        src={theme === 'light' ? right : rightWhite}
                        alt='arrow'
                        className={styles.arrow}
                      />
                    </div>
                    <div
                      className={styles.liOuter}
                      onClick={(e) => {
                        e.preventDefault();
                        setModal('reset-password');
                        setDropdown(false);
                      }}
                    >
                      <div className={styles.liName}>
                        <img
                          src={theme === 'dark' ? settingsWhite : settings}
                          alt='icon'
                          className={styles.liIcon}
                        />
                        <div className={styles.listName}>Reset Password</div>
                      </div>
                      <img
                        src={theme === 'light' ? right : rightWhite}
                        alt='arrow'
                        className={styles.arrow}
                      />
                    </div>
                    <div
                      className={styles.liOuter}
                      onClick={(e) => {
                        e.preventDefault();
                        navigate('/payment/settings');
                        setDropdown(false);
                      }}
                    >
                      <div className={styles.liName}>
                        <img
                          src={theme === 'dark' ? settingsWhite : settings}
                          alt='icon'
                          className={styles.liIcon}
                        />
                        <div className={styles.listName}>Payment Settings</div>
                      </div>
                      <img
                        src={theme === 'light' ? right : rightWhite}
                        alt='arrow'
                        className={styles.arrow}
                      />
                    </div>
                    <div className={clsx(styles.liOuter, styles.lastList)} onClick={() => logout()}>
                      <div className={styles.liName}>
                        <img
                          src={theme === 'dark' ? logOutWhite : logOut}
                          alt='icon'
                          className={styles.liIcon}
                        />
                        <div className={styles.listName}>Sign Out</div>
                      </div>
                      <img
                        src={theme === 'light' ? right : rightWhite}
                        alt='arrow'
                        className={styles.arrow}
                      />
                    </div>
                  </div>
                </div>
              </ClickAwayListener>
            )}
          </div>
        </div>
      </section>
    </section>
  );
};

export default Navbar;
