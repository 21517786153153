import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.css';
import { AuthProvider, AuthInit } from './auth/Auth.tsx';
import { ProfileImageProvider } from './helpers/hooks/profilePhoto.tsx';
import { LogoProvider } from './helpers/hooks/LogoContext.tsx';
import { DomainStatusProvider } from './helpers/hooks/DomainStatus.tsx';
import { ThemeProvider } from './helpers/hooks/ThemeContext.tsx';
import { API_URL, APP_ENV } from '../src/auth/_requests';
import * as Sentry from '@sentry/react';
import { Toaster } from 'react-hot-toast';

if (APP_ENV === 'production') {
  Sentry.init({
    dsn: 'https://7851c2ff2e77e41e4db333a2a094a823@o4506470979993600.ingest.us.sentry.io/4508845976584192',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [new RegExp('^' + API_URL + '/')],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <AuthProvider>
      <AuthInit>
        <ProfileImageProvider>
          <ThemeProvider>
            <DomainStatusProvider>
              <LogoProvider>
                <Toaster position='top-center' reverseOrder={false} />
                <App />
              </LogoProvider>
            </DomainStatusProvider>
          </ThemeProvider>
        </ProfileImageProvider>
      </AuthInit>
    </AuthProvider>
  </React.StrictMode>
);
