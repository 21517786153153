import styles from './SVG.module.css';
interface SVGProps {
  paths: string[];
  strokeColor: string;
  width?: string;
  height?: string;
}
const SVGUtils = ({ paths, strokeColor = '#868c99', width = '24', height = '24' }: SVGProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill='none'
      className={styles.menu}
    >
      {paths.map((pathData, index) => (
        <path
          key={index}
          d={pathData}
          stroke={strokeColor}
          fill='none'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      ))}
    </svg>
  );
};

export { SVGUtils };
