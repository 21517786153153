import { useNavigate } from 'react-router-dom';
import styles from './ReturnToDashboard.module.css';
import { useLogo } from '../../hooks/LogoContext';
import { useModal } from '../../hooks/modalContext';
import { useTheme } from '../../hooks/ThemeContext';
import darkLogo from '../../../assets/helpers/navbar/tenantpay-white.png';
import { useDomainStatus } from '../../hooks/DomainStatus';
const ReturnToDashboard = () => {
  const { logoUrl, logoLoading } = useLogo();
  const { domainStatus } = useDomainStatus();
  const { setModal } = useModal();
  const navigate = useNavigate();
  const { theme } = useTheme();
  const handleYes = () => {
    setModal(null);
    navigate('/dashboard');
  };

  const handleNo = () => {
    setModal(null); // Close the modal
  };

  return (
    <main className={`${styles.main} ${theme === 'dark' && styles.mainDark}`}>
      <section className={`${styles.section} ${theme === 'dark' && styles.sectionDark}`}>
        <div className={styles.message}>
          {!logoLoading && (
            <img
              src={theme === 'dark' && domainStatus === 'invalid' ? darkLogo : logoUrl}
              className={styles.logo}
              alt='logo'
            />
          )}
          <h2 className={`${styles.heading} ${theme === 'dark' && styles.headingDark}`}>
            Are you sure you want to leave this page and return to the dashboard?
          </h2>

          <div className={styles.buttonGroup}>
            <button className={styles.closeBtn} onClick={handleYes}>
              Yes
            </button>
            <button className={styles.closeBtn} onClick={handleNo}>
              No
            </button>
          </div>
        </div>
      </section>
    </main>
  );
};

export default ReturnToDashboard;
