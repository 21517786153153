import { useState, useEffect, useRef } from 'react';
import styles from './AddCard.module.css';
import { useModal } from '../../../../helpers/hooks/modalContext';
import { updateCardDetails } from '../../../../auth/_requests';
import { useAuth } from '../../../../auth/Auth';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { UpdateCardRequestBody } from '../../../../helpers/utils/types';
import clsx from 'clsx';
import { FetchAddress } from '../../../../helpers/utils/utils';

const EditCard = () => {
  const { modalState, setModal } = useModal();
  const { editCard } = modalState || {};
  const { auth } = useAuth();
  const addressInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [, setIsManually] = useState(false);

  useEffect(() => {
    if (editCard && Object.keys(editCard).length > 0) {
      formik.setValues({
        city: editCard.city || '',
        provinceCode: editCard.provinceCode || '',
        countryCode: editCard.countryCode || 'CA',
        postal: editCard.postalCode || '',
        address: editCard.address || '',
      });
    }
  }, [editCard]);

  const formik = useFormik({
    initialValues: {
      city: '',
      provinceCode: '',
      countryCode: 'CA',
      postal: '',
      address: '',
    },
    validationSchema: Yup.object({
      city: Yup.string().required('City is required'),
      provinceCode: Yup.string()
        .matches(/^[A-Za-z]{2}$/, 'Province code must be exactly 2 characters')
        .required('Province code is required'),
      postal: Yup.string().required('Postal code is required'),
      address: Yup.string().required('Address is required'),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const paymentOptionId = editCard?.id;
        const requestBody: UpdateCardRequestBody = {
          billingInfo: {
            city: values.city,
            provinceCode: values.provinceCode,
            postalCode: values.postal,
            fullAddress: values.address,
            countryCode: 'CA',
          },
        };
        await updateCardDetails(auth?.data.token, paymentOptionId, requestBody);
        window.location.reload();
        setModal(null);
      } catch (err) {
        console.error('Error:', err);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <section className={styles.section}>
      <main className={styles.main}>
        <FetchAddress
          addressInputRef={addressInputRef}
          formik={formik}
          isSaveManually={setIsManually}
        />
        <div className={styles.headingBox}>
          <div className={styles.heading}>Edit Card</div>
        </div>
        <button className={styles.cross} onClick={() => setModal(null)}>
          ✕
        </button>

        <form onSubmit={formik.handleSubmit} className={`container ${styles.container}`}>
          <div className={styles.item}>
            <label htmlFor='address' className={styles.label}>
              Full Address<span className={styles.mandatory}>*</span>
            </label>
            <input
              id='address'
              name='address'
              placeholder='Full Address'
              type='text'
              value={formik.values.address}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={clsx(
                styles.inputBox,
                formik.touched.address && formik.errors.address ? styles.isInvalid : '',
                formik.touched.address && !formik.errors.address ? styles.valid : ''
              )}
              ref={addressInputRef}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                }
              }}
            />
            {formik.touched.address && formik.errors.address ? (
              <div className={styles.errorMessage}>{formik.errors.address}</div>
            ) : null}
          </div>
          <div className={styles.item}>
            <label htmlFor='city' className={styles.label}>
              City<span className={styles.mandatory}>*</span>
            </label>
            <input
              id='city'
              name='city'
              placeholder='City'
              type='text'
              value={formik.values.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={clsx(
                styles.inputBox,
                formik.touched.city && formik.errors.city ? styles.isInvalid : '',
                formik.touched.city && !formik.errors.city ? styles.valid : ''
              )}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                }
              }}
            />
            {formik.touched.city && formik.errors.city ? (
              <div className={styles.errorMessage}>{formik.errors.city}</div>
            ) : null}
          </div>

          <div className={styles.item}>
            <label htmlFor='provinceCode' className={styles.label}>
              Province Code<span className={styles.mandatory}>*</span>
            </label>
            <input
              id='provinceCode'
              name='provinceCode'
              placeholder='Province Code'
              type='text'
              value={formik.values.provinceCode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={clsx(
                styles.inputBox,
                formik.touched.provinceCode && formik.errors.provinceCode ? styles.isInvalid : '',
                formik.touched.provinceCode && !formik.errors.provinceCode ? styles.valid : ''
              )}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                }
              }}
            />
            {formik.touched.provinceCode && formik.errors.provinceCode ? (
              <div className={styles.errorMessage}>{formik.errors.provinceCode}</div>
            ) : null}
          </div>
          <div className={styles.item}>
            <label htmlFor='postal' className={styles.label}>
              Postal Code<span className={styles.mandatory}>*</span>
            </label>
            <input
              id='postal'
              name='postal'
              placeholder='Postal Code'
              type='text'
              value={formik.values.postal}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={clsx(
                styles.inputBox,
                formik.touched.postal && formik.errors.postal ? styles.isInvalid : '',
                formik.touched.postal && !formik.errors.postal ? styles.valid : ''
              )}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                }
              }}
            />
            {formik.touched.postal && formik.errors.postal ? (
              <div className={styles.errorMessage}>{formik.errors.postal}</div>
            ) : null}
          </div>
          <div className={styles.item}>
            <label htmlFor='countryCode' className={styles.label}>
              Country Code<span className={styles.mandatory}>*</span>
            </label>
            <input
              id='countryCode'
              name='countryCode'
              placeholder='Country Code (e.g., CA)'
              type='text'
              value={formik.values.countryCode}
              className={clsx(
                styles.inputBox,
                formik.touched.countryCode && formik.errors.countryCode ? styles.isInvalid : '',
                formik.touched.countryCode && !formik.errors.countryCode ? styles.valid : ''
              )}
              readOnly
            />
          </div>

          <div className={styles.item100}>
            <button
              type='submit'
              className={`${styles.submitBtn} ${
                (formik.isSubmitting || !formik.isValid) && styles.disableBtn
              }`}
              disabled={loading || !formik.isValid}
            >
              {loading ? 'Saving...' : 'Save'}
            </button>
          </div>
        </form>
      </main>
    </section>
  );
};

export default EditCard;
