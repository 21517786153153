import React, { ReactElement, Suspense, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/Auth';
import { checkMFAStatus, getTenantDetails } from '../../auth/_requests';
import { Spinner } from '../Spinner/Spinner';
import { useProfileImage } from '../hooks/profilePhoto';
import { buildingDetails, tenantDetails } from '../utils/types';
import { useDomainStatus } from '../hooks/DomainStatus';

interface PrivateRouteProps {
  children:
    | ReactElement<{ tenant: tenantDetails; buildings?: buildingDetails[] }>
    | ReactElement<{ tenant?: tenantDetails; buildings?: buildingDetails[] }>;
  requiredVerifications: string[];
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, requiredVerifications }) => {
  const { auth, logout } = useAuth();
  const [, setIsValid] = useState(false);
  const [loading, setLoading] = useState(true);
  const { setProfileImageUrl, photoUpdated } = useProfileImage();
  const [tenant, setTenant] = useState<tenantDetails>();
  const { domainStatus } = useDomainStatus();
  const navigate = useNavigate();
  useEffect(() => {
    const checkValidity = async () => {
      const token = auth?.data.token;
      if (token) {
        try {
          const response = await getTenantDetails(token);
          const tenant = response.data;
          const profileUrl = tenant.user.profilePictureUrl;
          setProfileImageUrl(profileUrl);
          setTenant(tenant);
          if (
            tenant.tenant.tenantIdv &&
            (tenant.tenant.tenantIdv.status === 'pending' ||
              tenant.tenant.tenantIdv.status === 'declined')
          ) {
            navigate('/identity-verification');
            return;
          }
          if (localStorage.getItem('isSignUp') !== 'true') {
            try {
              const response = await checkMFAStatus(auth.data.token);
              if (
                response?.data.isMfaRequired &&
                tenant.user.isEmailVerified === 1 &&
                tenant.user.isPhoneVerified === 1
              ) {
                navigate('/two-factor-auth');
                return;
              }
            } catch (error) {
              console.error('Error checking MFA status:', error);
            }
          }
          const conditionsMet = requiredVerifications.every((verification: string) => {
            if (verification === 'isTenant') return tenant.user.accessToken !== '';
            if (verification === 'isEmailVerified') return tenant.user.isEmailVerified === 1;
            if (verification === 'isPhoneVerified') return tenant.user.isPhoneVerified === 1;
            if(verification === 'isNonIndividualAccount') return tenant.tenant.isIndividualAccount === 1;
            if (verification === 'isIdentityVerified')
              return tenant.tenant.isIdentityVerified === 1;
            if (verification === 'isDomainValid') return domainStatus === 'valid';
            return true; // Default case
          });
          if (conditionsMet) {
            setIsValid(true);
          } else {
            logout(); // Logout if any condition fails
          }
        } catch (error) {
          console.error('Error fetching tenant details:', error);
          logout();
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    checkValidity();
  }, [auth, logout, requiredVerifications, photoUpdated]);

  useEffect(() => {
    if (!auth) {
      navigate('/', { replace: true });
    }
  }, [auth, navigate]);

  if (loading) {
    return <Spinner />;
  }

  return <Suspense fallback={<Spinner />}>{React.cloneElement(children, { tenant })}</Suspense>;
};

export default PrivateRoute;
