import React, { useEffect, useState } from 'react';
import styles from './PaymentPage.module.css';
import { SafeCharge } from '../../helpers/utils/types';
import CardSection from './CardSection/CardSection';
import OrderSummary from './OrderSummary/OrderSummary';
import { useParams } from 'react-router-dom';
import { useTheme } from '../../helpers/hooks/ThemeContext';
import { PaymentProvider } from '../../helpers/hooks/PaymentContext';
import { useLogo } from '../../helpers/hooks/LogoContext';
import darkLogo from '../../assets/helpers/navbar/tenantpay-white.png';
import security from '../../assets/pages/payment/security.png';
import { ArrowLeft } from 'lucide-react';
import { useModal } from '../../helpers/hooks/modalContext';
import bgImage from '../../assets/pages/dashboard/bg4.jpg';
import darkBg from '../../assets/pages/dashboard/bg3black.jpg';
import { useDomainStatus } from '../../helpers/hooks/DomainStatus';
interface PaymentPageProps {
  safeCharge: SafeCharge;
}

const PaymentPage: React.FC<PaymentPageProps> = ({ safeCharge }) => {
  const { setModal } = useModal();
  const { tenantBuildingId } = useParams();
  const { domainStatus } = useDomainStatus();
  const { paymentType } = useParams();
  const { theme } = useTheme();
  const { logoUrl, logoLoading } = useLogo();
  const [backgroundUrl, setBackgroundUrl] = useState<string>();
  const handleCancel = () => {
    setModal('return-to-dashboard');
  };

  useEffect(() => {
    const selectedBg = theme === 'dark' ? darkBg : bgImage;
    const img = new Image();
    img.src = selectedBg;
    img.onload = () => {
      setBackgroundUrl(selectedBg);
    };
  }, [theme]);
  return (
    <PaymentProvider>
      <div
        style={{
          backgroundImage: `url(${backgroundUrl})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundAttachment: 'fixed',
          minHeight: '100vh',
          color: '#fff',
        }}
      >
        <main className={`${styles.main} ${theme === 'dark' && styles.mainDark}`}>
          <div className={styles.topNav}>
            <div className='flex gap-5'>
              {' '}
              <div
                className={`${styles.backButton} ${theme === 'dark' && styles.backButtonDark}`}
                onClick={handleCancel}
              >
                <ArrowLeft size={24} />
              </div>
              {!logoLoading && (
                <img
                  src={theme === 'dark' && domainStatus === 'invalid' ? darkLogo : logoUrl}
                  className={styles.logo}
                  alt='logo'
                />
              )}
            </div>

            <div className={styles.messageOuter}>
              <img
                src={security}
                alt='security'
                className={`${styles.securityIcon} ${theme === 'dark' && styles.securityIconDark}`}
              />
              <div className={`${styles.message} ${theme === 'dark' && styles.messageDark}`}>
                <p className={`${styles.cardBold} ${theme === 'dark' && styles.cardBoldDark}`}>
                  Secure Checkout
                </p>
              </div>
            </div>
          </div>
          <section className={`${styles.section} ${theme === 'dark' && styles.sectionDark}`}>
            <section className={styles.leftSection}>
              <CardSection
                tenantBuildingId={tenantBuildingId}
                paymentType={paymentType ?? ''}
                safeCharge={safeCharge}
              />
            </section>
            <div className={styles.verticalLine}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='2'
                height='719'
                viewBox='0 0 2 719'
                fill='none'
              >
                <path d='M1 0V719' stroke='#E1E1E1' strokeWidth='1px' />
              </svg>
            </div>
            <div style={{ flex: 2 }}>
              <OrderSummary paymentType={paymentType ?? ''} tenantBuildingId={tenantBuildingId} />
            </div>
          </section>
        </main>
      </div>
    </PaymentProvider>
  );
};

export default PaymentPage;
