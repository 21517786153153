import { useEffect } from 'react';
import { useModal } from '../hooks/modalContext';
import { useTheme } from '../hooks/ThemeContext';
import styles from './Footer.module.css';
import { checkUserConsent } from '../../auth/_requests';
import { useAuth } from '../../auth/Auth';
const Footer = () => {
  const { theme } = useTheme();
  const isDark = theme === 'dark';
  const { setModal } = useModal();
  const { auth } = useAuth();

  useEffect(() => {
    const checkTermsConsent = async () => {
      const consentResponse = await checkUserConsent(auth?.data.token || '');
      if (consentResponse?.data?.isUserConsentRequired) {
        setModal('terms-services');
      }
    };
    if (auth) {
      checkTermsConsent();
    }
  }, []);
  return (
    <main className={`${styles.main} ${isDark && styles.mainDark}`}>
      <section className={styles.section}>
        <button className={styles.btn} onClick={() => setModal('terms-services')}>
          Terms Of Service
        </button>
        <button className={styles.btn} onClick={() => setModal('privacy-policy')}>
          Privacy Policy
        </button>
      </section>
    </main>
  );
};
export default Footer;
