import styles from './SuccessfulProperty.module.css';
import { useModal } from '../../hooks/modalContext';
import { useNavigate } from 'react-router-dom';
import { useLogo } from '../../hooks/LogoContext';
import { useTheme } from '../../hooks/ThemeContext';
import logoWhite from '../../../assets/helpers/navbar/tenantpay-white.png';

const SuccessfulProperty = () => {
  const navigate = useNavigate();
  const { logoUrl, logoLoading } = useLogo();
  const { setModal } = useModal();
  const { theme } = useTheme();
  const isDark = theme === 'dark';
  return (
    <main className={`${styles.main} ${isDark && styles.mainDark}`}>
      <section className={styles.section}>
        <div className={styles.message}>
          {!logoLoading && (
            <img src={isDark ? logoWhite : logoUrl} className={styles.logo} alt='logo' />
          )}
          <h2 className={`${styles.heading} ${theme === 'dark' && styles.headingDark}`}>
            Property added successfully!
          </h2>
          <p className={styles.messageContent}>
            The property has been successfully added! You can now view its details and manage it in
            your dashboard.
          </p>
          <button
            className={styles.closeBtn}
            onClick={() => {
              setModal(null);
              navigate('/');
            }}
          >
            Close
          </button>
        </div>
      </section>
    </main>
  );
};
export default SuccessfulProperty;
