import { useRef, useState, ChangeEvent, useEffect } from 'react';
import styles from './MFAVerify.module.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/Auth';
import { createLoginToken, getTenantDetails, initiateMFA, verifyMFA } from '../../auth/_requests';
import { motion } from 'framer-motion';
import { fadeInUpAnimation } from '../../helpers/utils/types';
import { useLogo } from '../../helpers/hooks/LogoContext';
import BackgroundDarkImage from '../../helpers/BackgroundImage/BackgroundDark';
import { useTheme } from '../../helpers/hooks/ThemeContext';
import darkLogo from '../../assets/helpers/navbar/tenantpay-white.png';
import { useDomainStatus } from '../../helpers/hooks/DomainStatus';
import toast, { Toaster } from 'react-hot-toast';

export function MFAVerify() {
  const { auth, saveAuth, logout } = useAuth();
  const { logoUrl, logoLoading } = useLogo();
  const inputRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];
  const [inputValues, setInputValues] = useState(['', '', '', '']);
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const { theme } = useTheme();
  const isDark = theme === 'dark';
  const { domainStatus } = useDomainStatus();
  const navigate = useNavigate();

  const handleInputChange = (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.slice(0, 1);
    if (!/^\d$/.test(value)) {
      value = '';
    }
    const newValues = [...inputValues];
    newValues[index] = value;
    setInputValues(newValues);
    if (value.length === 1 && index < 3) {
      inputRefs[index + 1].current?.focus();
    }
  };
  const handleNavigation = async () => {
    try {
      const tenantResponse = await getTenantDetails(auth?.data.token);
      const emailVerified = tenantResponse.data.user.isEmailVerified;
      const phoneVerified = tenantResponse.data.user.isPhoneVerified;
      const isIndividualAccount = tenantResponse.data.tenant.isIndividualAccount;
      const isBuilding = tenantResponse.data.tenantBuildings.length;
      const isAddress = tenantResponse.data.address !== null;
      const isIDV = tenantResponse.data.tenant.tenantIdv !== null;
      const idvStatus = isIDV && tenantResponse.data.tenant.tenantIdv.status;
      switch (true) {
        case !emailVerified:
          navigate('/verify-email');
          break;
        case !phoneVerified:
          navigate('/verify-phone');
          break;
        case !isIndividualAccount:
          navigate('/tenant-type');
          break;
        case !isAddress:
          navigate('/personal-info');
          break;
        case !isBuilding:
          navigate('/add-property');
          break;
        case !isIDV:
          navigate('/identity-verification');
          break;
        case idvStatus === 'pending' || idvStatus === 'declined':
          navigate('/identity-verification');
          break;
        case idvStatus === 'approved':
          navigate('/dashboard');
          break;
        case idvStatus === 'changeRequest':
          navigate('/request-changes');
          break;
        default:
          console.warn('Unhandled tenant verification status');
      }
    } catch (error) {
      console.error('Error fetching tenant details:', error);
    }
  };

  const handleVerify = async () => {
    const code = inputValues.join('');
    const isValidCode = code.length === 4 && /^\d{4}$/.test(code);
    if (isValidCode) {
      setErrorMessage('');
      setLoadingBtn(true);
      try {
        const token = auth?.data.token || '';
        const response = await verifyMFA(token, code);
        if (response.message === 'verification_success') {
          toast.remove();
          toast.success('Verification completed successfully!', {
            duration: 4000,
            style: {
              maxWidth: '327px',
              background: `${theme === 'dark' && '#000'}`,
              color: `${theme === 'dark' ? '#f5f5f5' : `#020b28`}`,
              border: `${theme === 'dark' && '1px solid #f5f5f5'}`,
            },
          });
          try {
            const response = await createLoginToken(auth?.data.token || '');
            const loginToken = response.data.loginToken;
            const newAuth = {
              message: 'success',
              data: {
                tenantId: auth?.data.tenantId || '',
                token: loginToken,
              },
            };
            saveAuth(newAuth);
          } catch (error) {
            console.error('Error creating login token:', error);
          }
          setIsEmailVerified(true);
          setTimeout(handleNavigation, 2000);
        }
      } catch (error) {
        setErrorMessage('Error verifying code, Please enter a valid code');
      } finally {
        setLoadingBtn(false);
      }
    } else {
      setErrorMessage('Please enter a valid code');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (['+', '-'].includes(e.key)) {
      e.preventDefault();
    } else if (e.key === 'Enter') {
      handleVerify();
    } else if (e.key === 'Backspace') {
      if (inputValues[index] === '' && index > 0) {
        inputRefs[index - 1].current?.focus();
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('Text');
    const digits = pastedData.replace(/\D/g, '').substring(0, 4).split('');

    const newValues = [...inputValues];
    newValues[0] = digits[0] || '';
    newValues[1] = digits[1] || '';
    newValues[2] = digits[2] || '';
    newValues[3] = digits[3] || '';
    setInputValues(newValues);
    inputRefs[digits.length > 0 ? digits.length - 1 : 0].current?.focus();
  };
  useEffect(() => {
    const initiateMFASession = async () => {
      try {
        await initiateMFA(auth?.data.token || '');
      } catch (error) {
        console.error('Error sending code:', error);
      }
    };
    initiateMFASession();
  }, []);
  return (
    <section className={styles.section}>
      <Toaster position='top-center' reverseOrder={false} />
      <BackgroundDarkImage />
      <motion.main className={`${styles.main} ${isDark && styles.mainDark}`} {...fadeInUpAnimation}>
        {!logoLoading && (
          <img
            src={theme === 'dark' && domainStatus === 'invalid' ? darkLogo : logoUrl}
            className={styles.logo}
            alt='logo'
          />
        )}

        <div className={styles.headingBox}>
          <div className={`${styles.heading} ${isDark && styles.headingDark}`}>
            Two-Factor Authentication
          </div>
          <div className={styles.subHead}>
            To proceed, please enter the 4-digit code sent to your phone for multi-factor
            authentication.
          </div>
        </div>
        <div className={styles.codeBox}>
          <div className={styles.codeBoxInner}>
            <div className={styles.inputBoxes}>
              {inputRefs.map((ref, index) => (
                <input
                  key={index}
                  type='number'
                  className={`${styles.numberBox} ${isDark && styles.numberBoxDark}`}
                  ref={ref}
                  value={inputValues[index]}
                  onChange={handleInputChange(index)}
                  maxLength={1}
                  min={0}
                  max={9}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  onPaste={handlePaste}
                  onWheel={(e) => (e.target as HTMLInputElement).blur()}
                />
              ))}
            </div>
            {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
          </div>
          <button
            className={`${styles.disableBtn} ${
              !isEmailVerified && inputValues.every((val) => val !== '') ? styles.submitBtn : ''
            }  ${isEmailVerified && styles.disableBtn}`}
            onClick={handleVerify}
            disabled={isEmailVerified || inputValues.some((val) => val === '')}
          >
            {!loadingBtn && <span className='indicator-label'>Verify</span>}
            {loadingBtn && (
              <span className={styles.indicatorProgress} style={{ display: 'block' }}>
                Please wait...
                <span className={styles.spinner}></span>
              </span>
            )}
          </button>
          <div className='mt-4 text-center'>
            <a
              href='#'
              className='text-blue-500 text-sm cursor-pointer hover:underline'
              onClick={(e) => {
                e.preventDefault();
                logout();
                navigate('/');
              }}
            >
              Sign in to a different account
            </a>
          </div>
        </div>
      </motion.main>
    </section>
  );
}
