import { useModal } from '../../hooks/modalContext';
import styles from './SubscriptionDetail.module.css';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTheme } from '../../hooks/ThemeContext';
import { getSubscriptionDetails } from '../../../auth/_requests';
import master from '../../../assets/pages/payment/mastercard.png';
import visa from '../../../assets/pages/payment/visa.png';
import amex from '../../../assets/pages/payment/amex.png';
import { useAuth } from '../../../auth/Auth';
import type { SubscriptionDetail } from '../../utils/types';
import { formatDateToDisplayUTCLong } from '../../utils/utils';

const SubscriptionDetail = () => {
  const { auth } = useAuth();
  const { modalState, setModal } = useModal();
  const { theme } = useTheme();
  const { buildingId } = modalState || {};
  const [subscriptionDetails, setSubscriptionDetails] = useState<SubscriptionDetail>();
  const isDark = theme === 'dark';
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getSubscription = async () => {
      if (buildingId) {
        try {
          setLoading(true);
          const response = await getSubscriptionDetails(auth?.data.token, String(buildingId));
          setSubscriptionDetails(response?.data);
        } catch (error) {
          console.error('Failed to fetch Subscription Details:', error);
          setLoading(false);
        } finally {
          setLoading(false);
        }
      }
    };

    getSubscription();
  }, [auth?.data.token, buildingId]);

  return (
    <>
      <main className={styles.main}>
        <div className={`${styles.header} ${theme === 'dark' && styles.headerDark}`}>
          <h1 className={styles.title}>Subscription Details</h1>
          <button className={styles.cross} onClick={() => setModal(null)}>
            ✕
          </button>
        </div>

        <section className={clsx(styles.section, isDark && styles.sectionDark)}>
          {!loading ? (
            <>
              <div className={styles.dateOuter}>
                <div className={styles.label}>Recurring Amount:</div>
                <div
                  className={`${styles.date} ${isDark && styles.dateDark}`}
                >{`$${subscriptionDetails?.recurringAmount.toLocaleString()}`}</div>
              </div>
              <div className={styles.dateOuter}>
                <div className={styles.label}>Billing Cycle:</div>
                <div className={`${styles.date} ${isDark && styles.dateDark}`}>
                  {`${subscriptionDetails?.billingCycle?.charAt(0).toUpperCase()}${
                    subscriptionDetails?.billingCycle?.slice(1) || ''
                  }`}
                </div>
              </div>
              <div className={styles.dateOuter}>
                <div className={styles.label}>Subscription Added on:</div>
                <div className={`${styles.date} ${isDark && styles.dateDark}`}>
                  {formatDateToDisplayUTCLong(subscriptionDetails?.timestampAdded || '')}
                </div>
              </div>
              <div className={styles.dateOuter}>
                <div className={styles.label}>Rent Amount:</div>
                <div
                  className={`${styles.date} ${isDark && styles.dateDark}`}
                >{`$${subscriptionDetails?.rentAmount.toLocaleString()}`}</div>
              </div>
              {subscriptionDetails?.fixedProcessingFee !== '0.00' && (
                <div className={styles.dateOuter}>
                  <div className={styles.label}>Fixed Processing Fee:</div>
                  <div
                    className={`${styles.date} ${isDark && styles.dateDark}`}
                  >{`$${subscriptionDetails?.fixedProcessingFee.toLocaleString()}`}</div>
                </div>
              )}
              {subscriptionDetails?.percentageProcessingFee !== '0.00' && (
                <div className={styles.dateOuter}>
                  <div
                    className={styles.label}
                  >{`Processing Fee (${subscriptionDetails?.percentageRate}%):`}</div>
                  <div
                    className={`${styles.date} ${isDark && styles.dateDark}`}
                  >{`$${subscriptionDetails?.percentageProcessingFee}`}</div>
                </div>
              )}
              <div className={styles.dateOuter}>
                <div className={styles.label}>Total Processing Fee:</div>
                <div
                  className={`${styles.date} ${isDark && styles.dateDark}`}
                >{`$${subscriptionDetails?.processingFee.toLocaleString()}`}</div>
              </div>
              <div className={styles.dateOuter}>
                <div className={styles.label}>Payment Method:</div>
                <div
                  className={`${styles.date} ${isDark && styles.dateDark}`}
                >{`${subscriptionDetails?.paymentMethod}`}</div>
              </div>
              <div className={styles.dateOuter}>
                <div className={styles.label}>Card Type:</div>
                <div className={styles.imageOuter}>
                  <img
                    src={(() => {
                      switch (subscriptionDetails?.cardCompanyType) {
                        case 'Mastercard':
                          return master;
                        case 'Visa':
                          return visa;
                        case 'Amex':
                          return amex;
                        default:
                          return '';
                      }
                    })()}
                    alt='card'
                    className={styles.cardImage}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className={isDark ? styles.loadingDark : styles.loadingText}>Loading...</div>
          )}
        </section>
        <div className={clsx(styles.footer, isDark && styles.footerDark)}>
          <div></div>
          <button
            className={styles.btn}
            onClick={() =>
              setModal('cancel-subscription', {
                buildingId: buildingId,
              })
            }
          >
            Cancel Subscription
          </button>
        </div>
      </main>
    </>
  );
};

export default SubscriptionDetail;
