/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { createContext, useContext, useState, ReactNode } from 'react';

interface PaymentContextProps {
  paymentSummary: any;
  setPaymentSummary: (summary: any) => void;
}

const PaymentContext = createContext<PaymentContextProps | undefined>(undefined);

export const PaymentProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [paymentSummary, setPaymentSummary] = useState<any>(null);

  return (
    <PaymentContext.Provider value={{ paymentSummary, setPaymentSummary }}>
      {children}
    </PaymentContext.Provider>
  );
};

export const usePayment = () => {
  const context = useContext(PaymentContext);
  if (!context) {
    throw new Error('usePayment must be used within a PaymentProvider');
  }
  return context;
};
