interface Props {
  isPending?: boolean;
  className?: string;
}
const SVGAsset = ({ isPending, className }: Props) => {
  return isPending ? (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width='256.000000pt'
      height='256.000000pt'
      viewBox='0 0 256.000000 256.000000'
      preserveAspectRatio='xMidYMid meet'
      className={className}
    >
      <g
        transform='translate(0.000000,256.000000) scale(0.100000,-0.100000)'
        fill='var(--primary-color)'
        stroke='none'
      >
        <path
          d='M430 2544 c-112 -30 -209 -116 -252 -222 l-23 -57 -3 -1095 c-2 -784
    1 -1102 9 -1122 6 -14 23 -32 39 -38 37 -15 76 5 125 65 21 25 42 45 48 45 6
    0 37 -25 69 -57 78 -77 99 -76 179 3 l59 58 59 -58 c81 -80 101 -80 182 0 l59
    58 59 -58 c83 -83 100 -81 196 14 l73 72 43 -29 c279 -191 643 -150 874 99 72
    78 134 192 160 293 8 33 15 103 15 160 0 175 -57 325 -172 450 -97 106 -208
    171 -345 204 l-78 18 0 79 0 79 282 3 282 2 21 27 c21 27 21 33 18 378 -3 318
    -6 354 -23 398 -45 111 -148 203 -260 232 -87 22 -1611 21 -1695 -1z m1267
    -196 l-32 -63 -5 -469 -5 -469 -78 -19 c-192 -45 -354 -174 -441 -348 -59
    -121 -79 -214 -73 -340 5 -114 39 -224 98 -318 21 -35 39 -67 39 -71 0 -4 -15
    -21 -33 -39 l-33 -32 -34 35 c-54 56 -106 88 -132 81 -13 -3 -50 -30 -83 -60
    l-60 -54 -62 59 c-36 35 -70 59 -83 59 -13 0 -48 -25 -86 -62 l-65 -62 -62 62
    c-67 67 -100 77 -145 46 l-22 -15 0 983 c0 932 1 985 19 1023 23 51 65 93 116
    116 37 17 80 19 667 19 l626 0 -31 -62z m421 46 c49 -20 100 -70 123 -119 17
    -36 19 -69 19 -332 l0 -293 -231 0 -230 0 3 298 c3 286 4 299 25 339 55 102
    187 151 291 107z m-254 -1210 c167 -43 309 -175 367 -344 18 -51 23 -87 23
    -165 0 -116 -23 -195 -80 -283 -212 -323 -675 -321 -886 2 -108 166 -108 395
    0 562 123 191 358 284 576 228z'
        />
        <path
          d='M926 2234 c-9 -8 -19 -26 -22 -40 -3 -13 -21 -33 -40 -45 -19 -11
    -46 -41 -60 -65 -22 -38 -26 -54 -22 -103 6 -87 38 -123 154 -177 117 -55 136
    -83 89 -129 -31 -31 -79 -33 -145 -4 -43 18 -52 19 -77 7 -35 -17 -51 -58 -35
    -94 9 -17 32 -35 72 -52 33 -15 60 -32 60 -38 0 -6 9 -23 21 -38 28 -36 80
    -36 108 0 12 15 21 34 22 43 0 9 21 30 47 46 66 43 95 101 90 181 -6 97 -56
    153 -181 204 -72 30 -93 51 -78 79 18 32 55 36 102 12 53 -28 93 -21 114 19
    22 43 4 82 -51 111 -24 13 -44 29 -44 35 0 6 -9 23 -21 38 -24 30 -78 36 -103
    10z'
        />
        <path
          d='M487 1260 c-37 -30 -37 -80 0 -110 26 -20 36 -21 240 -18 238 3 247
    6 247 73 0 67 -9 70 -247 73 -204 3 -214 2 -240 -18z'
        />
        <path
          d='M486 959 c-36 -28 -36 -80 0 -108 25 -20 39 -21 231 -21 125 0 212 4
    224 11 25 13 41 53 33 85 -12 47 -45 54 -262 54 -186 0 -201 -1 -226 -21z'
        />
        <path
          d='M487 660 c-37 -30 -37 -80 0 -110 26 -20 36 -21 240 -18 238 3 247 6
    247 73 0 67 -9 70 -247 73 -204 3 -214 2 -240 -18z'
        />
        <path
          d='M1730 947 c-37 -19 -50 -54 -50 -139 l0 -78 -84 0 c-101 0 -136 -19
    -136 -75 0 -24 7 -39 26 -54 24 -19 40 -21 161 -21 129 0 135 1 158 25 24 23
    25 29 25 163 0 119 -3 143 -17 160 -24 27 -53 34 -83 19z'
        />
      </g>
    </svg>
  ) : (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width='256.000000pt'
      height='256.000000pt'
      viewBox='0 0 256.000000 256.000000'
      preserveAspectRatio='xMidYMid meet'
      className={className}
    >
      <g
        transform='translate(0.000000,256.000000) scale(0.100000,-0.100000)'
        fill='var(--primary-color)'
        stroke='none'
      >
        <path
          d='M230 1813 c-34 -31 -27 -10 -156 -491 -68 -255 -75 -286 -63 -313 19
   -47 48 -60 121 -57 58 3 63 5 66 27 5 31 -12 41 -69 41 -28 0 -49 5 -52 13 -6
   15 181 707 196 725 7 10 24 12 50 9 74 -10 74 -2 -7 -322 -55 -212 -72 -294
   -65 -307 8 -15 23 -18 104 -18 51 0 100 3 109 6 13 5 16 23 16 93 0 97 7 111
   56 111 62 0 64 -4 64 -99 0 -54 5 -92 12 -99 7 -7 31 -12 55 -12 37 0 45 -4
   53 -25 6 -16 6 -34 0 -50 -8 -21 -16 -25 -53 -25 -24 0 -48 -5 -55 -12 -8 -8
   -12 -47 -12 -109 0 -106 -2 -109 -64 -109 -49 0 -56 14 -56 115 0 57 -4 95
   -12 103 -7 7 -45 12 -99 12 -93 0 -116 -11 -104 -50 6 -18 15 -20 76 -20 l69
   0 0 -80 c0 -74 2 -82 29 -112 24 -27 36 -32 88 -36 65 -5 114 13 133 47 5 11
   10 56 10 100 l0 81 34 0 c59 0 107 78 91 150 -11 50 -51 90 -91 90 l-34 0 0
   71 c0 39 -5 79 -10 90 -37 68 -207 63 -239 -7 -6 -14 -11 -54 -11 -89 l0 -65
   -40 0 c-22 0 -40 3 -40 8 -1 4 30 127 67 274 77 305 77 308 3 346 -64 32 -131
   30 -170 -5z'
        />
        <path
          d='M1989 1812 c-29 -26 -38 -52 -120 -357 -117 -432 -114 -416 -95 -454
   9 -17 25 -35 35 -41 11 -6 92 -10 190 -10 l171 0 0 -80 c0 -74 2 -82 29 -112
   24 -27 36 -32 88 -36 65 -5 114 13 133 47 5 11 10 56 10 100 l0 81 34 0 c40 0
   80 40 91 90 16 72 -32 150 -91 150 l-34 0 0 71 c0 39 -5 79 -10 90 -16 29 -64
   49 -119 49 -98 0 -131 -36 -131 -145 l0 -65 -40 0 c-23 0 -40 5 -40 11 0 6 30
   130 67 275 76 301 76 305 3 342 -65 32 -131 30 -171 -6z m147 -64 c11 -11 1
   -60 -61 -302 -54 -214 -71 -294 -64 -308 8 -15 23 -18 104 -18 51 0 100 3 109
   6 13 5 16 23 16 93 0 97 7 111 56 111 62 0 64 -4 64 -99 0 -54 5 -92 12 -99 7
   -7 31 -12 55 -12 37 0 45 -4 53 -25 6 -16 6 -34 0 -50 -8 -21 -16 -25 -53 -25
   -24 0 -48 -5 -55 -12 -8 -8 -12 -47 -12 -109 0 -106 -2 -109 -64 -109 -49 0
   -56 14 -56 115 0 57 -4 95 -12 103 -8 8 -67 12 -199 12 -141 0 -188 3 -192 13
   -6 16 180 707 196 725 14 17 83 10 103 -10z'
        />
        <path
          d='M1161 1695 c-134 -38 -239 -126 -299 -252 -36 -77 -37 -80 -37 -198
   0 -117 1 -122 36 -196 89 -187 293 -295 491 -261 156 27 279 119 346 261 33
   68 37 86 40 174 4 80 1 109 -17 165 -38 117 -95 177 -124 131 -8 -13 -2 -34
   29 -90 38 -73 39 -76 39 -179 0 -95 -3 -111 -29 -168 -38 -82 -131 -171 -213
   -203 -81 -32 -205 -32 -286 0 -82 32 -175 121 -213 203 -26 56 -29 74 -29 163
   0 88 3 107 27 158 52 110 134 182 246 217 110 34 210 21 317 -40 45 -26 59
   -30 70 -20 39 32 -6 79 -116 122 -71 27 -205 33 -278 13z'
        />
        <path
          d='M1082 1448 c-23 -23 -11 -44 68 -123 l80 -80 -80 -80 c-44 -44 -80
   -88 -80 -97 0 -10 9 -22 21 -28 18 -10 30 -2 105 71 l84 83 84 -83 c75 -73 87
   -81 105 -71 35 18 24 43 -59 125 l-80 80 81 81 c82 83 92 105 57 128 -15 9
   -31 -2 -103 -74 l-85 -85 -83 83 c-45 45 -87 82 -93 82 -5 0 -15 -5 -22 -12z'
        />
      </g>
    </svg>
  );
};
export default SVGAsset;
