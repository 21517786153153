import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useModal } from '../../helpers/hooks/modalContext';
import { useTheme } from '../../helpers/hooks/ThemeContext';
import styles from './TermsOfServices.module.css';
import {
  checkUserConsent,
  getTenantDetails,
  getTermsContent,
  setUserConsent,
} from '../../auth/_requests';
import { useAuth } from '../../auth/Auth';
import { Checkbox } from '../../helpers/Checkbox/Checkbox';
import { formatDateToDisplayUTCLong } from '../../helpers/utils/utils';
// import html2pdf from 'html2pdf.js';
import toast, { Toaster } from 'react-hot-toast';
import { jsPDF } from 'jspdf';

const TermsOfServices = () => {
  const { setModal } = useModal();
  const { auth } = useAuth();
  const { theme } = useTheme();
  const [showFields, setShowFields] = useState(false);
  const isDark = theme === 'dark';
  const [termsContent, setTermsContent] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState('');
  const [checked, setChecked] = useState(false);
  const [id, setId] = useState('');
  const [userName, setUserName] = useState('');
  const [address, setAddress] = useState('');
  const [acceptDate, setAcceptDate] = useState('');
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const [hasClickedUnderstood, setHasClickedUnderstood] = useState(false);
  const saveConsent = async () => {
    const requestBody = {
      termsAndConditionId: id,
    };
    try {
      await setUserConsent(requestBody, auth?.data.token || '');
      setModal(null);
    } catch (error) {
      console.error('Error setting user consent');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Fetch terms content
        const termsResponse = await getTermsContent();
        if (termsResponse?.data) {
          setTermsContent(termsResponse.data.value);
          setId(String(termsResponse.data.termsAndConditionId));
        } else {
          setNotFound('Unable to find the terms and conditions');
        }

        // Check user consent status
        if (auth) {
          const consentResponse = await checkUserConsent(auth?.data.token);
          if (consentResponse?.data?.isUserConsentRequired) {
            setShowFields(true);
          } else {
            setChecked(true);
            setAcceptDate(
              formatDateToDisplayUTCLong(consentResponse?.data.timestampAdded as string)
            );
          }
        }
      } catch (error) {
        if (error instanceof Error) {
          if (error.message === 'Terms content not found') {
            setNotFound('Unable to find the terms and conditions');
          }
        }
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [auth]);
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const contentElement = event.target as HTMLDivElement;
    const scrollThreshold = 5;
    const isAtBottom =
      Math.abs(
        contentElement.scrollHeight - contentElement.scrollTop - contentElement.clientHeight
      ) <= scrollThreshold;

    setIsScrolledToBottom(isAtBottom);
  };
  // const downloadStyledPDF = (): void => {
  //   const contentElement = document.getElementById('htmlContent');
  //   const hiddenElement = document.getElementById('contentHidden');

  //   if (contentElement && hiddenElement) {
  //     hiddenElement.style.display = 'block';
  //     const combinedContent = document.createElement('div');
  //     // Append the hidden content first, then the main content
  //     combinedContent.appendChild(hiddenElement.cloneNode(true));
  //     combinedContent.appendChild(contentElement.cloneNode(true));

  //     const options = {
  //       margin: [10, 10, 5, 10],
  //       filename: 'Consent_Agreement.pdf',
  //       html2canvas: {
  //         scale: 1,
  //         useCORS: true,
  //         logging: true,
  //       },
  //       jsPDF: {
  //         unit: 'mm',
  //         format: 'a4',
  //         orientation: 'portrait',
  //         autoPaging: 'yes',
  //         overflow: 'visible',
  //       },
  //     };
  //     html2pdf().from(combinedContent).set(options).save();
  //     hiddenElement.style.display = 'none';
  //   } else {
  //     console.error('Content or hidden element not found!');
  //   }
  // };

  const generatePDF = (): void => {
    const pdf = new jsPDF();
    const hiddenContentElement = document.getElementById('contentHidden');
    const visibleContentElement = document.getElementById('htmlContent');
    if (!hiddenContentElement && !visibleContentElement) return;
    const previousDisplay = hiddenContentElement?.style.display || '';
    if (hiddenContentElement) hiddenContentElement.style.display = 'block';

    const hiddenContent =
      hiddenContentElement?.innerText || hiddenContentElement?.textContent || '';
    const visibleContent =
      visibleContentElement?.innerText || visibleContentElement?.textContent || '';

    if (!hiddenContent && !visibleContent) {
      if (hiddenContentElement) hiddenContentElement.style.display = previousDisplay;
      return;
    }

    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const margin = 10;
    const textWidth = pageWidth - margin * 2;
    const fontSize = 12;
    const lineHeight = 8;

    pdf.setFont('helvetica', 'normal');
    pdf.setFontSize(fontSize);

    let y = margin;
    const addTextToPDF = (text: string, currentY: number): number => {
      const lines = pdf.splitTextToSize(text, textWidth);
      lines.forEach((line: string) => {
        if (currentY + lineHeight > pageHeight - margin) {
          pdf.addPage();
          currentY = margin;
        }
        pdf.text(line, margin, currentY);
        currentY += lineHeight;
      });
      return currentY;
    };
    if (hiddenContent) {
      y = addTextToPDF(hiddenContent, y);
      y += lineHeight; 
    }
    if (visibleContent) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      y = addTextToPDF(visibleContent, y);
    }

    pdf.save('TermsAndConditions.pdf');
    if (hiddenContentElement) hiddenContentElement.style.display = previousDisplay;
  };
  const handleClick = () => {
    const contentElement = document.querySelector(`.${styles.section}`);
    if (!isScrolledToBottom && !hasClickedUnderstood) {
      // Automatically scroll to the bottom
      if (contentElement) {
        contentElement.scrollTo({
          top: contentElement.scrollHeight,
          behavior: 'smooth',
        });
      }

      setHasClickedUnderstood(true);
      return;
    }

    if (!checked && showFields) {
      toast.remove();
      toast.error('Please read and accept the terms and conditions to proceed.', {
        duration: 4000,
        style: {
          maxWidth: '327px',
          background: `${theme === 'dark' && '#000'}`,
          color: `${theme === 'dark' ? '#f5f5f5' : `#020b28`}`,
          border: `${theme === 'dark' && '1px solid #f5f5f5'}`,
        },
      });
      return;
    }

    if (showFields) saveConsent();
    setModal(null);
  };
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        setLoading(true);
        const response = await getTenantDetails(auth?.data.token);
        setUserName(response.data.user.firstName + ' ' + response.data.user.lastName);
        setAddress(response.data.address.fullAddress);
      } catch (error) {
        console.error('Error fetching user details');
      } finally {
        setLoading(false);
      }
    };
    if (auth) {
      fetchUserDetails();
    }
  }, []);

  return (
    <>
      <Toaster position='top-center' reverseOrder={false} />{' '}
      <div className={styles.main}>
        <div className={`${styles.header} ${isDark && styles.headerDark}`}>
          <p className={styles.title}>Terms and Conditions</p>
          <button className={styles.cross} onClick={() => setModal(null)}>
            ✕
          </button>
        </div>
        <div className={clsx(styles.footer, isDark && styles.footerDark)}>
          <div></div>

          <button className={styles.btn} onClick={() => handleClick()}>
            {notFound || loading ? 'Close' : 'Understood'}
          </button>
        </div>
        <div className={clsx(styles.section, isDark && styles.sectionDark)} onScroll={handleScroll}>
          {loading ? (
            <div className={styles.loading}>Loading...</div>
          ) : !notFound ? (
            <>
              <div id='contentHidden' className={styles.none}>
                <span className='text-align: justify'>
                  <a id='_heading=h.gjdgxs'></a>
                  ONLINE CONTRACT BETWEEN:
                </span>
                <br />
                <span className='text-align: justify'>
                  <strong>{userName}</strong>
                  <br />
                  <i> {address} </i>
                </span>
                <br />
                <span className='text-align: justify'>AND</span>
                <br />
                <span className='text-align: justify'>
                  <strong>TENANT PAYMENT SYSTEMS INC.</strong>
                </span>
                <br />
                <i>
                  <span className='text-align: justify'>
                    1000 De La Gauchetière Street West, Suite 900
                  </span>
                  <br />
                  <span className='text-align: justify'>Montréal H3B 5H4</span>
                  <br />
                  <span className='text-align: justify'>Québec, Canada</span>
                </i>
                <br />
                <span className='text-align: justify'>Tel: +1-844-608-1010</span>
                <br />
                <span className='text-align: justify'>
                  For the weekly, monthly, annual access and use of the TenantPay Platform (as
                  defined in these Terms of Use) – description and prices are as set out in the
                  Order Form (as defined in these Terms of Use).
                </span>
                <br />
                <span className='text-align: justify'>
                  Accepted by You on [
                  <strong>
                    {acceptDate ? acceptDate : formatDateToDisplayUTCLong(new Date().toISOString())}
                  </strong>
                  ]
                </span>
                <br />
                <span className='text-align: justify'>
                  <strong>
                    <span className='font-variant: small-caps'>----</span>
                  </strong>
                </span>{' '}
              </div>
              <div className={styles.content} id='htmlContent'>
                <>
                  <div
                    dangerouslySetInnerHTML={{ __html: termsContent }}
                    className='content-class'
                  />
                </>
              </div>
              {showFields && (
                <>
                  <div className={`${styles.checkBoxOuter}`}>
                    <Checkbox
                      checked={checked}
                      onChange={() => {
                        setChecked(!checked);
                      }}
                    />
                    <p className={`${styles.paragraph} ${styles.txt}`}>
                      I acknowledge and accept the Terms of Service
                    </p>
                  </div>
                </>
              )}
              {auth && (
                <button
                  type='submit'
                  className={`${styles.btn} ${styles.btnTop}`}
                  disabled={!checked}
                  onClick={() => {
                    // downloadStyledPDF();
                    generatePDF();
                  }}
                >
                  Download Agreement
                </button>
              )}
            </>
          ) : (
            <div className={clsx(styles.loading, styles.notFound)}>{notFound}</div>
          )}
        </div>
      </div>
    </>
  );
};

export default TermsOfServices;
