import { Toaster } from 'react-hot-toast';
import { useDomainStatus } from '../../hooks/DomainStatus';
import { useLogo } from '../../hooks/LogoContext';
import { useModal } from '../../hooks/modalContext';
import { useTheme } from '../../hooks/ThemeContext';
import darkLogo from '../../../assets/helpers/navbar/tenantpay-white.png';
import styles from './UpdatePhone.module.css';
import clsx from 'clsx';
import { useState } from 'react';
import { isPhoneNumber } from 'class-validator';
import { sendVerificationCodePhoneUpdate } from '../../../auth/_requests';
import { useAuth } from '../../../auth/Auth';
import { CANANA_COUNTRY_CODE } from '../../../constants/canadaCountryCode';

const UpdatePhone = () => {
  const { theme } = useTheme();
  const { auth } = useAuth();
  const { setModal } = useModal();
  const { logoUrl, logoLoading } = useLogo();
  const { domainStatus } = useDomainStatus();
  const [phoneError, setPhoneError] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const formatPhoneNumberInput = (value: string) => {
    if (!value) return value;

    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;

    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };
  const updatePhone = async (phone: string) => {
    try {
      await sendVerificationCodePhoneUpdate(`+${CANANA_COUNTRY_CODE}${phone}`, 4, auth?.data.token);
      setModal('verify-phone-code', { phoneNumber: phone });
    } catch (error) {
      setPhoneError('Please enter a valid number');
    }
  };
  return (
    <section className={styles.section}>
      <Toaster position='top-center' reverseOrder={false} />
      <button
        className={`${styles.cross} ${theme === 'dark' && styles.crossDark} `}
        onClick={() => setModal(null)}
      >
        ✕
      </button>
      <main className={`${styles.main} ${theme === 'dark' && styles.mainDark}`}>
        {!logoLoading && (
          <img
            src={theme === 'dark' && domainStatus === 'invalid' ? darkLogo : logoUrl}
            className={styles.logo}
            alt='logo'
          />
        )}
        <div className={styles.headingBox}>
          <div className={`${styles.heading} ${theme === 'dark' && styles.headingDark}`}>
            Update Phone Number
          </div>
          <div className={styles.subHead}>Set a new phone number for your account</div>
        </div>
        <div className={styles.phoneOuter}>
          <div
            className={clsx(
              phoneError && phone !== '' ? styles.isInvalid : '',
              !phoneError && phone ? styles.valid : '',
              `${styles.phoneInputBox} ${theme === 'dark' && styles.darkInput}`
            )}
          >
            <div className={`${styles.dropdown} ${theme === 'light' && styles.dropdownDark}`}>
              +1
            </div>
            <input
              type='text'
              autoComplete='off'
              className={`${styles.phoneInput}  ${theme === 'dark' && styles.darkInput}`}
              placeholder='(639) 212-4561'
              maxLength={14}
              value={formatPhoneNumberInput(phone)}
              onChange={(e) => {
                const inputValue = e.target.value.replace(/[^\d]/g, '').slice(0, 10);
                setPhone(inputValue);
                if (inputValue.length === 0) {
                  setPhoneError('Phone number is required');
                } else if (inputValue.length < 10) {
                  setPhoneError('Phone number must be 10 digits');
                } else {
                  if (!isPhoneNumber(inputValue, 'CA')) {
                    setPhoneError('Please provide a valid Canadian phone number');
                  } else setPhoneError('');
                }
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                }
              }}
            />
          </div>
          {phoneError && <p className={styles.phoneError}>{phoneError}</p>}
        </div>
        {!phoneError && (
          <button
            className={styles.signUpBox}
            onClick={() => {
              updatePhone(phone);
            }}
          >
            Verify Now
          </button>
        )}
      </main>
    </section>
  );
};
export default UpdatePhone;
