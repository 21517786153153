import React from 'react';
import styles from './PaymentStatusModal.module.css';
import { useModal } from '../../../../helpers/hooks/modalContext';
import { Spinner } from '../../../../helpers/Spinner/Spinner';
import SVGAsset from '../../../../helpers/SVG/SVGAsset';
import SVGCheck from '../../../../helpers/SVG/SVGCheck';
import errorIcon from '../../../../assets/pages/nonIndividualError/error.svg';
import { useTheme } from '../../../../helpers/hooks/ThemeContext';

interface PaymentStatusProps {
  status: 'approved' | 'declined' | 'pending' | 'error';
  message: string;
}

const PaymentStatusModal: React.FC<PaymentStatusProps> = ({ status, message }) => {
  const { modalState, setModal } = useModal();
  const { errorDescription } = modalState || { errorDescription: '' };
  const { theme } = useTheme();
  const isDark = theme === 'dark';

  const getStatusIcon = () => {
    switch (status) {
      case 'approved':
        return <SVGCheck isBigCheck={true} />;
      case 'declined':
      case 'error':
        return <img src={errorIcon} alt='error' className={styles.errorIcon} />;
      case 'pending':
        return <SVGAsset isPending={true} className={styles.pendingIcon} />;
      default:
        return null;
    }
  };

  if (status === 'pending') {
    return <Spinner widthFull='!w-[100vw]' />;
  }

  return (
    <section className={styles.section}>
      <main className={`${styles.main} ${isDark && styles.mainDark}`}>
        <button
          className={`${styles.cross} ${isDark && styles.crossWhite}`}
          onClick={() => setModal(null)}
        >
          ✕
        </button>
        <div className={styles.headingBox}>
          {getStatusIcon()}
          <div className={`${styles.heading} ${isDark && styles.headingDark}`}>{message}</div>
          {status === 'error' && errorDescription && (
            <div className={styles.errorDescription}>{errorDescription}</div>
          )}
        </div>
        <button className={styles.backBtn} onClick={() => setModal(null)}>
          Go back to payment page
        </button>
      </main>
    </section>
  );
};

export default PaymentStatusModal;
