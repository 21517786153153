/* eslint-disable @typescript-eslint/no-explicit-any */
import styles from './CardSection.module.css';
import { PaymentOption, SafeCharge } from '../../../helpers/utils/types';
import { useModal } from '../../../helpers/hooks/modalContext';
import { useAuth } from '../../../auth/Auth';
import { useEffect, useRef, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import {
  createSubscription,
  getPaymentOptions,
  getPaymentSummary,
  openOrderWithoutAuth,
} from '../../../auth/_requests';
import { useNavigate } from 'react-router-dom';
import { usePayment } from '../../../helpers/hooks/PaymentContext';
import CardCarousal from './CardCarousal/CardCarousal';
import PaymentNav from '../PaymentNav/PaymentNav';
import visa from '../../../assets/pages/payment/visa.png';
import mastercard from '../../../assets/pages/payment/mastercard.png';
import amex from '../../../assets/pages/payment/amex.png';
import { useTheme } from '../../../helpers/hooks/ThemeContext';
import CardDesign from '../PaymentSection/component/CardDesign/CardDesign';
import { Spinner } from '../../../helpers/Spinner/Spinner';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Trash } from 'lucide-react';
interface SafeChargeProps {
  safeCharge: SafeCharge;
  tenantBuildingId: string | undefined;
  paymentType: string;
}
const errorMessages: Record<string, string> = {
  incomplete_cvc: 'CVC is incomplete',
  invalid_cvc: 'The CVC is invalid',
};
const CardSection: React.FC<SafeChargeProps> = ({ safeCharge, tenantBuildingId, paymentType }) => {
  const { setModal } = useModal();
  const { setPaymentSummary } = usePayment();
  const { auth, currentUser } = useAuth();
  const [paymentOptions, setPaymentOptions] = useState<PaymentOption[]>([]);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState<PaymentOption | null>(null);
  const [cvv, setCvv] = useState<string>('');
  const [isPaying, setIsPaying] = useState(false);
  const cardCvcRef = useRef<any>(null);
  const [, setSelectedMethod] = useState<string | null>(
    localStorage.getItem('selectedPaymentOption')
  );
  const [isCvvEntered, setIsCvvEntered] = useState<boolean>(false);
  // const [paymentSummary, setPaymentSummary] = useState<any>(null);

  const navigate = useNavigate();
  const { theme } = useTheme();
  const [loading, setLoading] = useState<boolean>(true);
  const [cardErrors, setCardErrors] = useState<any>({});
  useEffect(() => {
    const paymentOptionId = localStorage.getItem('selectedPaymentOption');
    if (paymentOptionId) {
      const foundOption = paymentOptions.find(
        (option) => option.paymentOptionId === paymentOptionId
      );
      if (foundOption) {
        setSelectedPaymentOption(foundOption);
      }
    }
  }, [paymentOptions]);

  useEffect(() => {
    const handleFieldChange = (event: any) => {
      const { field, error } = event;

      if (error) {
        const friendlyMessage = errorMessages[error.id] || 'An error occurred';
        setCardErrors((prevErrors: any) => ({
          ...prevErrors,
          [field]: friendlyMessage,
        }));
      } else {
        setCardErrors((prevErrors: any) => ({
          ...prevErrors,
          [field]: '',
        }));
      }
    };

    if (safeCharge && selectedPaymentOption) {
      const safeChargeFields = safeCharge.fields({
        fonts: [{ cssUrl: '' }],
        locale: 'en',
        fontSize: '16px',
      });

      // Poll for the existence of the card-cvc element
      const pollForCvcField = setInterval(() => {
        const cvcElement = document.getElementById('card-cvc');
        if (cvcElement && !cardCvcRef.current) {
          cardCvcRef.current = safeChargeFields.create('ccCvc', {
            style: {
              base: {
                fontSize: '16px',
              },
            },
          });

          cardCvcRef.current.attach('#card-cvc');
          cardCvcRef.current.on('change', (event: any) => {
            if (event.complete) {
              setIsCvvEntered(true);
            } else {
              setIsCvvEntered(false);
            }
          });
          cardCvcRef.current.on('change', handleFieldChange);

          setCvv(cardCvcRef.current);
          clearInterval(pollForCvcField); // Stop polling once the element is found
        }
      }, 100); // Poll every 100ms

      return () => clearInterval(pollForCvcField); // Cleanup the polling on unmount
    }
  }, [safeCharge, selectedPaymentOption]);

  useEffect(() => {
    const fetchPaymentOptions = async () => {
      if (!auth?.data.token) return;
      try {
        setLoading(true);
        const options: PaymentOption[] = await getPaymentOptions(auth.data.token);
        setPaymentOptions(options);
        if (options.length > 0) {
          if (!selectedPaymentOption) {
            handleSelectMethod(options[0].paymentOptionId);
            setSelectedPaymentOption(options[0]);
          }
        }
        const paymentOptionId = localStorage.getItem('selectedPaymentOption');
        if (paymentOptionId) {
          const foundOption = options.find((option) => option.paymentOptionId === paymentOptionId);
          if (foundOption) {
            setSelectedPaymentOption(foundOption);
            setSelectedMethod(paymentOptionId);

            const summary = await getPaymentSummary(
              auth.data.token,
              tenantBuildingId,
              foundOption.id
            );
            setPaymentSummary(summary);
          }
        }
      } catch (error) {
        setLoading(false);
        toast.error('Error fetching payment options: ' + error, {
          duration: 8000,
          style: {
            maxWidth: '327px',
            background: `${theme === 'dark' && '#000'}`,
            color: `${theme === 'dark' ? '#f5f5f5' : `#020b28`}`,
            border: `${theme === 'dark' && '1px solid #f5f5f5'}`,
          },
        });
      } finally {
        setLoading(false);
      }
    };
    fetchPaymentOptions();
  }, [auth, tenantBuildingId]);
  const handleSelectMethod = async (paymentOptionId: string) => {
    if (cardCvcRef.current) {
      cardCvcRef.current.clear();
    }

    setSelectedMethod(paymentOptionId);
    localStorage.setItem('selectedPaymentOption', paymentOptionId);
    const selectedOption = paymentOptions?.find(
      (option) => option.paymentOptionId === paymentOptionId
    );

    if (selectedOption) {
      setSelectedPaymentOption({ ...selectedOption });

      try {
        const summary = await getPaymentSummary(
          auth?.data.token,
          tenantBuildingId,
          selectedOption.id
        );
        setPaymentSummary(summary);
      } catch (error) {
        toast.remove();
        toast.error('Error fetching payment summary: ' + error, {
          duration: 8000,
          style: {
            maxWidth: '327px',
            background: `${theme === 'dark' && '#000'}`,
            color: `${theme === 'dark' ? '#f5f5f5' : `#020b28`}`,
            border: `${theme === 'dark' && '1px solid #f5f5f5'}`,
          },
        });
      }
    }
  };
  /*  const handleCancel = () => {
    setModal('return-to-dashboard');
  }; */
  const handleActiveIndexChange = (swiper: any) => {
    const activeIndex = swiper.activeIndex;
    const activePaymentOption = paymentOptions?.[activeIndex];

    if (activePaymentOption) {
      handleSelectMethod(activePaymentOption.paymentOptionId);
    }
  };
  const handleRemove = () => {
    setModal('remove-card', { editCard: selectedPaymentOption as PaymentOption });
  };
  const createDirectPayment = async () => {
    let sessionTokenForPaymentFinal = '';
    try {
      setIsPaying(true);
      const response = await openOrderWithoutAuth(
        auth?.data.token,
        tenantBuildingId,
        selectedPaymentOption?.id
        // isRebilling
      );

      sessionTokenForPaymentFinal = response.sessionToken;
    } catch (error) {
      setIsPaying(false);
      toast.remove();
      toast.error('Error fetching session token: ' + error, {
        duration: 8000,
        style: {
          maxWidth: '327px',
          background: `${theme === 'dark' && '#000'}`,
          color: `${theme === 'dark' ? '#f5f5f5' : `#020b28`}`,
          border: `${theme === 'dark' && '1px solid #f5f5f5'}`,
        },
      });
      return;
    }

    setModal('payment-status-pending');

    if (sessionTokenForPaymentFinal) {
      try {
        safeCharge?.createPayment(
          {
            sessionToken: sessionTokenForPaymentFinal,
            userTokenId: currentUser?.user?.userId,
            paymentOption: {
              userPaymentOptionId: selectedPaymentOption?.paymentOptionId,
              card: {
                CVV: cvv,
              },
            },
          },
          (resp) => {
            setIsPaying(false);
            const status = String(resp?.transactionStatus).toLowerCase();
            const errorDescription = resp?.errorDescription || '';
            switch (status) {
              case 'declined':
                setModal(null);
                navigate('/payment-status', {
                  state: { status: 'declined', errorDescription, tenantBuildingId },
                });
                break;
              case 'approved':
                setModal(null);
                navigate('/payment-status', { state: { status: 'success' } });
                break;
              case 'error':
                setModal(null);
                navigate('/payment-status', {
                  state: { status: 'failure', errorDescription, tenantBuildingId },
                });
                break;
              default:
                setModal(null);
                navigate('/payment-status', {
                  state: { status: 'failure', errorDescription, tenantBuildingId },
                });
                break;
            }
          }
        );
      } catch (error) {
        setIsPaying(false);
        console.error(error);
        toast.remove();
        toast.error('Error making Payment: ' + error, {
          duration: 8000,
          style: {
            maxWidth: '327px',
            background: `${theme === 'dark' && '#000'}`,
            color: `${theme === 'dark' ? '#f5f5f5' : `#020b28`}`,
            border: `${theme === 'dark' && '1px solid #f5f5f5'}`,
          },
        });
        return;
      }
    }
  };
  const createSubscriptionRequest = async () => {
    try {
      setIsPaying(true);
      await createSubscription(auth?.data.token, tenantBuildingId, selectedPaymentOption?.id || '');
      setModal('successful-subscription');
    } catch (error) {
      setIsPaying(false);
      console.error(error);
      toast.error('Subscription not created ! ' + error, {
        duration: 8000,
        style: {
          maxWidth: '327px',
          background: `${theme === 'dark' && '#000'}`,
          color: `${theme === 'dark' ? '#f5f5f5' : `#020b28`}`,
          border: `${theme === 'dark' && '1px solid #f5f5f5'}`,
        },
      });
      return;
    }
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (paymentType === 'direct') {
      createDirectPayment();
    } else {
      createSubscriptionRequest();
    }
  };
  if (loading) {
    return <Spinner widthFull='!bg-transparent' />;
  }
  return (
    <>
      <Toaster position='top-center' reverseOrder={false} />
      <PaymentNav />
      <main className={styles.main}>
        {paymentOptions.length === 0 ? ( // Check if there are no payment options
          <div className={styles.noPaymentOptions}>
            <p>No payment options available, please add a card to make payment.</p>
            <button onClick={() => setModal('add-payment-method')}>
              <CardDesign noCard={true} />
            </button>
          </div>
        ) : (
          <section className={styles.section}>
            <div style={{ flex: 2 }}>
              {paymentOptions.length <= 2 && (
                <Swiper
                  style={{
                    marginBottom: '2rem',
                    width: '100%', // Use 100% width of the container
                    maxWidth: '280px', // Limit to a maximum width if needed
                    maxHeight: '30vh',
                    flex: 2,
                  }}
                >
                  <SwiperSlide>
                    {' '}
                    <button onClick={() => setModal('add-payment-method')}>
                      <CardDesign addCard={true} />
                    </button>
                  </SwiperSlide>
                </Swiper>
              )}
              <CardCarousal
                paymentOptions={paymentOptions}
                onActiveIndexChange={handleActiveIndexChange}
              ></CardCarousal>
            </div>

            <div style={{ flex: 3 }}>
              {selectedPaymentOption && (
                <>
                  <div
                    className={`${styles.paymentForm} ${
                      theme === 'dark' && styles.paymentFormDark
                    }`}
                  >
                    <p
                      className={`${styles.cardInformation} ${
                        theme === 'dark' && styles.cardInformationDark
                      }`}
                    >
                      {' '}
                      Card Information
                      <button onClick={handleRemove}>
                        <Trash stroke='#f1416c' />
                      </button>
                    </p>

                    <form onSubmit={handleSubmit}>
                      <div className='form-group'>
                        <label className={styles.label}>Card Number</label>
                        <div className={styles.inputWrapper}>
                          <input
                            type='text'
                            value={`**** **** **** ${selectedPaymentOption.cardEndingDigits}`}
                            disabled
                            className={styles.cardInput}
                          />
                          {selectedPaymentOption.cardBrand === 'Visa' && (
                            <img src={visa} alt='Visa' className={styles.cardLogo} />
                          )}
                          {selectedPaymentOption.cardBrand === 'Mastercard' && (
                            <img src={mastercard} alt='MasterCard' className={styles.cardLogo} />
                          )}
                          {selectedPaymentOption.cardBrand === 'Amex' && (
                            <img src={amex} alt='American Express' className={styles.cardLogo} />
                          )}
                        </div>
                      </div>
                      <div className='form-group'>
                        <label className={styles.label}>Cardholder Name</label>
                        <input
                          type='text'
                          value={selectedPaymentOption.cardHolderName || ''}
                          onChange={(e) =>
                            setSelectedPaymentOption({
                              ...selectedPaymentOption,
                              cardHolderName: e.target.value,
                            })
                          }
                          disabled
                        />
                      </div>
                      <div
                        className={`${styles.paymentFormOuter} ${
                          paymentType === 'subscription' && styles.paymentFormOuterSingle
                        }`}
                      >
                        {' '}
                        <div className='form-group'>
                          <label className={styles.label}>Expiration Date</label>
                          <input
                            type='text'
                            value={`${selectedPaymentOption.expiryMonth || ''}/${
                              selectedPaymentOption.expiryYear || ''
                            }`}
                            onChange={(e) => {
                              const [month, year] = e.target.value.split('/');
                              setSelectedPaymentOption({
                                ...selectedPaymentOption,
                                expiryMonth: parseInt(month, 10) || 0,
                                expiryYear: parseInt(year, 10) || 0,
                              });
                            }}
                            disabled
                          />
                        </div>
                        {paymentType === 'direct' && (
                          <div className='form-group'>
                            <div className='item'>
                              <label className={styles.label} htmlFor='card-cvc'>
                                CVV
                              </label>
                              <div
                                id='card-cvc'
                                className='input'
                                style={{
                                  marginTop: '10px',
                                }}
                              />
                            </div>
                            {cardErrors.ccCvc && (
                              <div className='text-red-500'>{cardErrors.ccCvc}</div>
                            )}
                          </div>
                        )}
                      </div>

                      <button
                        type='submit'
                        className={styles.addBtn}
                        disabled={(paymentType === 'direct' && !isCvvEntered) || isPaying}
                      >
                        {isPaying
                          ? 'Processing...'
                          : paymentType === 'direct'
                          ? 'Pay Now'
                          : 'Subscribe'}
                      </button>
                    </form>
                  </div>
                </>
              )}
            </div>
          </section>
        )}
      </main>
    </>
  );
};

export default CardSection;
