import { useLogo } from '../../hooks/LogoContext';
import { useModal } from '../../hooks/modalContext';
import { useTheme } from '../../hooks/ThemeContext';
import styles from '../../../pages/VerifyPhone/VerifyPhone.module.css';
import { ChangeEvent, useRef, useState } from 'react';
import { updateTenantPhone, verifyVerificationCodePhoneUpdate } from '../../../auth/_requests';
import { useAuth } from '../../../auth/Auth';
import { CANANA_COUNTRY_CODE } from '../../../constants/canadaCountryCode';
import { formatPhoneNumber } from '../../utils/utils';
import { useDomainStatus } from '../../hooks/DomainStatus';
import darkLogo from '../../../assets/helpers/navbar/tenantpay-white.png';

const VerifyPhoneCode = () => {
  const { theme } = useTheme();
  const { domainStatus } = useDomainStatus();
  const { auth } = useAuth();
  const { modalState } = useModal();
  const { logoUrl, logoLoading } = useLogo();
  const [errorMessage, setErrorMessage] = useState('');
  const [validMessage, setValidMessage] = useState('');
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const inputRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];
  const [inputValues, setInputValues] = useState(['', '', '', '']);
  const handleInputChange = (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.slice(0, 1);
    if (!/^\d$/.test(value)) {
      value = '';
    }
    const newValues = [...inputValues];
    newValues[index] = value;
    setInputValues(newValues);
    if (value.length === 1 && index < 3) {
      inputRefs[index + 1].current?.focus();
    }
  };
  const handleVerify = async () => {
    const code = inputValues.join('');
    const isValidCode = code.length === 4 && /^\d{4}$/.test(code);
    if (isValidCode) {
      setErrorMessage('');
      setLoadingBtn(true);
      try {
        const token = auth?.data.token || '';
        setLoading(true);
        const response = await verifyVerificationCodePhoneUpdate(
          `+${CANANA_COUNTRY_CODE}${modalState?.phoneNumber}`,
          token,
          code
        );

        switch (response.message) {
          case 'verification_success': {
            const phoneNumber = modalState?.phoneNumber || '';
            const updateResponse = await updateTenantPhone(auth?.data.token, {
              userDetails: { phoneNumber: phoneNumber },
            });

            if (updateResponse.message === 'updated') {
              setValidMessage('Your phone has been verified and updated');
              setIsEmailVerified(true);
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            }
            break;
          }

          case 'invalid_verification_code':
            setErrorMessage('Invalid verification code');
            break;

          case 'phone_number_does_not_exist':
            setErrorMessage('Phone number does not exist');
            break;

          case 'verification_code_expired':
            setErrorMessage('Verification code has expired');
            break;

          case 'verification_attempts_exceeded':
            setErrorMessage('Verification attempts exceeded');
            break;

          default:
            setErrorMessage('Verification failed');
            break;
        }
      } catch (error) {
        if (error instanceof Error) {
          console.log(error.message);
          setErrorMessage('Error verifying code');
        } else {
          setErrorMessage('An unknown error occurred');
        }
      } finally {
        setLoadingBtn(false);
        setLoading(false);
      }
    } else {
      setErrorMessage('Please enter a valid code');
    }
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (['+', '-'].includes(e.key)) {
      e.preventDefault();
    } else if (e.key === 'Enter') {
      handleVerify();
    } else if (e.key === 'Backspace') {
      if (inputValues[index] === '' && index > 0) {
        inputRefs[index - 1].current?.focus();
      }
    }
  };
  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('Text');
    const digits = pastedData.replace(/\D/g, '').substring(0, 4).split('');

    const newValues = [...inputValues];
    newValues[0] = digits[0] || '';
    newValues[1] = digits[1] || '';
    newValues[2] = digits[2] || '';
    newValues[3] = digits[3] || '';
    setInputValues(newValues);
    inputRefs[digits.length > 0 ? digits.length - 1 : 0].current?.focus();
  };
  return (
    <section className={styles.section}>
      <main className={`${styles.main} ${theme === 'dark' && styles.mainDark}`}>
        {!logoLoading && (
          <img
            src={theme === 'dark' && domainStatus === 'invalid' ? darkLogo : logoUrl}
            className={styles.logo}
            alt='logo'
          />
        )}

        <div className={styles.headingBox}>
          <div className={`${styles.heading} ${theme === 'dark' && styles.headingDark}`}>
            Verify Phone
          </div>
          <div className={styles.subHead}>
            Enter the verification code sent to <br />
            <span className={styles.mail}>
              {formatPhoneNumber(`${modalState?.phoneNumber}`, true)}
            </span>{' '}
            to continue.
          </div>
        </div>
        <div className={styles.codeBox}>
          <div className={styles.codeBoxInner}>
            <div className={styles.inputBoxes}>
              {inputRefs.map((ref, index) => (
                <input
                  key={index}
                  type='number'
                  className={`${styles.numberBox} ${theme === 'dark' && styles.darkInput}`}
                  ref={ref}
                  value={inputValues[index]}
                  onChange={handleInputChange(index)}
                  maxLength={1}
                  min={0}
                  max={9}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  onPaste={handlePaste}
                  onWheel={(e) => (e.target as HTMLInputElement).blur()}
                />
              ))}
            </div>
            {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
            {validMessage && (
              <div className={`${styles.validMessage} ${theme === 'dark' && styles.validDark}`}>
                {validMessage}
              </div>
            )}
          </div>
          <button
            className={`${styles.disableBtn} ${
              !isEmailVerified && inputValues.every((val) => val !== '') ? styles.submitBtn : ''
            } ${loading && styles.disableBtn} ${isEmailVerified && styles.disableBtn}`}
            onClick={handleVerify}
            disabled={isEmailVerified || inputValues.some((val) => val === '') || loading}
          >
            {!loadingBtn && <span className='indicator-label'>Verify</span>}
            {loadingBtn && (
              <span className={styles.indicatorProgress} style={{ display: 'block' }}>
                Please wait...
                <span className={styles.spinner}></span>
              </span>
            )}
          </button>
        </div>
      </main>
    </section>
  );
};
export default VerifyPhoneCode;
