import { useTheme } from '../hooks/ThemeContext';
import styles from './Spinner.module.css';
interface props {
  widthFull?: string;
}

export const Spinner = ({ widthFull }: props) => {
  const { theme } = useTheme();
  return (
    <div
      className={`${styles.spinnerContainer} ${
        theme === 'dark' && styles.spinnerContainerDark
      } ${widthFull}`}
    >
      <div className={styles.loader}></div>
    </div>
  );
};
