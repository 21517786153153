import { DatePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { useEffect, useState } from 'react';
import { FormikProps } from 'formik';
import {
  AddPropertyFormValues,
  AddPropertyManager,
  editPropertyProps,
  formProps,
  PersonalInfoFormValues,
  RentalInfoFormProps,
} from '../DatePicker/DatePicker';
import calendarIcon from '../../assets/pages/addProperty/personal-info/calendar.svg';
import { PlacementTypes } from '../utils/types';

interface DateCalendarProps {
  startDate: Date | null;
  onDateChange: (date: Date | null) => void;
  formik:
    | FormikProps<AddPropertyFormValues>
    | FormikProps<PersonalInfoFormValues>
    | FormikProps<RentalInfoFormProps>
    | FormikProps<formProps>
    | FormikProps<editPropertyProps>
    | FormikProps<AddPropertyManager>;
  type: string;
  maxYear?: number;
  minDate?: Date;
  maxDate?: Date;
  placement?: PlacementTypes;
  disable?: boolean;
}

const DateCalendar = ({
  startDate,
  onDateChange,
  formik,
  type,
  maxYear,
  minDate,
  maxDate,
  placement = 'bottomStart',
  disable = false,
}: DateCalendarProps) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(startDate);
  const CaretIcon = () => <img src={calendarIcon} alt='calendar' className='cursor-pointer' />;
 
  const formatDate = (date: Date | null) => {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    return `${('0' + (date?.getDate() || 0)).slice(-2)} ${
      date ? months[date.getMonth()] : 0
    } ${date?.getFullYear()}`;
  };

  const handleDateChange = (newDate: Date | null) => {
    setSelectedDate(newDate);
    onDateChange(newDate);
  };

  useEffect(() => {
    setSelectedDate(startDate);
  }, [startDate]);

  // Disable dates based on minDate and maxDate
  const shouldDisableDate = (date: Date) => {
    if (minDate && date < minDate) return true;
    if (maxDate && date > maxDate) return true;
    return false;
  };

  return (
    <DatePicker
      format='d MMM yyyy'
      placeholder='Select a date'
      placement={placement}
      value={selectedDate}
      editable={false}
      caretAs={CaretIcon}
      onChange={(date) => {
        handleDateChange(date);
        formik.setFieldValue(`${type}`, formatDate(date));
      }}
      limitEndYear={maxYear}
      disabled={disable}
      shouldDisableDate={shouldDisableDate}
      oneTap
    />
  );
};

export default DateCalendar;
