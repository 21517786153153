import { useEffect, useState } from 'react';
import styles from './PaymentSchedule.module.css';
import { useModal } from '../../hooks/modalContext';
import { formatDateToDisplayUTCLong } from '../../utils/utils';
import upPast from '../../../assets/helpers/modals/paymentSchedule/upPast.svg';
import downPast from '../../../assets/helpers/modals/paymentSchedule/downPast.svg';
import upFuture from '../../../assets/helpers/modals/paymentSchedule/upFuture.svg';
import downFuture from '../../../assets/helpers/modals/paymentSchedule/downFuture.svg';
import { getTransactionsStatus } from '../../../auth/_requests';
import { useAuth } from '../../../auth/Auth';
import { PaymentData } from '../../utils/types';
import { useTheme } from '../../hooks/ThemeContext';
import clsx from 'clsx';
import paid from '../../../assets/pages/login/correct.svg';
import unpaid from '../../../assets/pages/login/invalid.svg';

const PaymentSchedule = () => {
  const { modalState, setModal } = useModal();
  const { theme } = useTheme();
  const { auth } = useAuth();
  const { buildingId } = modalState || {};
  const [isPastOpen, setIsPastOpen] = useState(false);
  const [isUpcomingOpen, setIsUpcomingOpen] = useState(true);
  const [overduePayments, setOverduePayments] = useState<PaymentData[]>([]);
  const [futurePayments, setFuturePayments] = useState<PaymentData[]>([]);
  const [noPayments, setNoPayments] = useState(false);
  const [loading, setLoading] = useState(false);
  const isDark = theme === 'dark';
  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        setLoading(true);
        const response = await getTransactionsStatus(String(buildingId), auth?.data.token);
        const payments = response?.data || [];
        const today = new Date();
        const overdue = payments.filter((payment) => {
          const paymentDate = new Date(payment.paymentDate);
          // Overdue if date is in the past or payment is done
          return paymentDate.getTime() < today.setHours(0, 0, 0, 0) || payment.hasPayment === true;
        });

        const upcoming = payments.filter((payment) => {
          const paymentDate = new Date(payment.paymentDate);
          // Future and unpaid
          return (
            paymentDate.getTime() >= today.setHours(0, 0, 0, 0) && payment.hasPayment === false
          );
        });

        setOverduePayments(overdue);
        setFuturePayments(upcoming);

        // If both overdue and upcoming are empty, set no payments flag
        setNoPayments(overdue.length === 0 && upcoming.length === 0);
      } catch (error) {
        console.error('Failed to fetch transactions:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [auth?.data.token, buildingId]);

  return (
    <main className={styles.main}>
      <div className={`${styles.header} ${theme === 'dark' && styles.headerDark}`}>
        <h1 className={styles.title}>Rent Schedule</h1>
        <button className={styles.cross} onClick={() => setModal(null)}>
          ✕
        </button>
      </div>

      <section className={clsx(styles.section, isDark && styles.sectionDark)}>
        {!loading ? (
          <div className={styles.paymentOuter}>
            {/* Show this message if there are no payments */}
            {noPayments && (
              <p className={`${styles.noPayment} ${isDark && styles.noPaymentDark}`}>
                No payment schedule found!
              </p>
            )}

            {/* Render overdue payments accordion only if there are overdue payments */}
            {!noPayments && overduePayments && overduePayments?.length > 0 && (
              <div className={`${styles.accordion} ${theme === 'dark' && styles.accDark}`}>
                <div
                  className={`${styles.accordionHeader} ${isDark && styles.accordionDark} ${
                    !isPastOpen && styles.accRounded
                  }`}
                  onClick={() => setIsPastOpen(!isPastOpen)}
                >
                  <h3 className={styles.overdueTitle}>Past Payments</h3>
                  <img className={styles.arrow} src={isPastOpen ? upPast : downPast} alt='arrow' />
                </div>
                {isPastOpen && (
                  <ul className={`${styles.paymentList} ${isDark && styles.paymentListDark}`}>
                    {overduePayments?.map((date, index) => (
                      <li
                        key={index}
                        className={`${styles.paymentItem} ${
                          index === overduePayments.length - 1 && styles.lastItem
                        } ${theme === 'dark' && styles.paymentItemDark}`}
                      >
                        <span className={`${styles.date} ${isDark && styles.dateDark}`}>
                          {date.responseTimestamp
                            ? formatDateToDisplayUTCLong(date.responseTimestamp)
                            : formatDateToDisplayUTCLong(date.paymentDate)}
                        </span>
                        <div className={styles.amountWrap}>
                          <span className={`${styles.date} ${isDark && styles.dateDark}`}>{`$${
                            date.amountPaid
                              ? date.amountPaid.toLocaleString()
                              : modalState?.propertyAmount?.toLocaleString()
                          }`}</span>
                          <img
                            src={date.hasPayment ? paid : unpaid}
                            alt={date.hasPayment ? 'Paid' : 'Unpaid'}
                            title={
                              date.hasPayment
                                ? 'Payment successfully completed.'
                                : 'Payment pending.'
                            }
                            className={`${styles.statusIcon}`}
                          />
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
            {/* Render future payments accordion only if there are future payments */}
            {!noPayments && futurePayments && futurePayments?.length > 0 && (
              <div className={`${styles.accordion} ${theme === 'dark' && styles.accDark}`}>
                <div
                  className={`${styles.accordionHeader} ${isDark && styles.accordionDark} ${
                    !isUpcomingOpen && styles.accRounded
                  }`}
                  onClick={() => setIsUpcomingOpen(!isUpcomingOpen)}
                >
                  <h3 className={styles.futureTitle}>Upcoming Payments</h3>
                  <img
                    className={styles.arrow}
                    src={isUpcomingOpen ? upFuture : downFuture}
                    alt='arrow'
                  />
                </div>
                {isUpcomingOpen && (
                  <ul className={`${styles.paymentList} ${isDark && styles.paymentListDark}`}>
                    {futurePayments?.map((date, index) => (
                      <li
                        key={index}
                        className={`${styles.paymentItem} ${
                          index === futurePayments.length - 1 && styles.lastItem
                        } ${theme === 'dark' && styles.paymentItemDark}`}
                      >
                        <span className={`${styles.date} ${isDark && styles.dateDark}`}>
                          {formatDateToDisplayUTCLong(date.paymentDate)}
                        </span>
                        <span
                          className={`${styles.date} ${isDark && styles.dateDark}`}
                        >{`$${modalState?.propertyAmount?.toLocaleString()}`}</span>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className={`${styles.loadingText} ${isDark && styles.loadingWhite}`}>Loading...</div>
        )}
      </section>
      <div className={clsx(styles.footer, isDark && styles.footerDark)}>
        <div></div>
        <button className={styles.btn} onClick={() => setModal(null)}>
          Close
        </button>
      </div>
    </main>
  );
};

export default PaymentSchedule;
