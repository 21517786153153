import styles from './CancelSubscription.module.css';
import { useLogo } from '../../hooks/LogoContext';
import { useModal } from '../../hooks/modalContext';
import { useTheme } from '../../hooks/ThemeContext';
import darkLogo from '../../../assets/helpers/navbar/tenantpay-white.png';
import { useDomainStatus } from '../../hooks/DomainStatus';
import { cancelSubscription } from '../../../auth/_requests';
import { useAuth } from '../../../auth/Auth';
import toast, { Toaster } from 'react-hot-toast';

const CancelSubscription = () => {
  const { auth } = useAuth();
  const { modalState, setModal } = useModal();

  const { buildingId } = modalState || {};
  const { logoUrl, logoLoading } = useLogo();
  const { domainStatus } = useDomainStatus();

  const { theme } = useTheme();

  const handleNo = () => {
    setModal(null); // Close the modal
  };
  const cancelSubscriptionRequest = async () => {
    try {
      const response = await cancelSubscription(auth?.data.token, String(buildingId));
      if (response.message === 'success') {
        setModal(null);
        window.location.reload();
        setModal('success-modal', { message: 'Subscription Cancelled.' });
      }
    } catch (error) {
      console.error('Failed to fetch Subscription Details:', error);
      toast.remove();
      toast.error('Error cancelling Subscription: ' + error, {
        duration: 8000,
        style: {
          maxWidth: '327px',
          background: `${theme === 'dark' && '#000'}`,
          color: `${theme === 'dark' ? '#f5f5f5' : `#020b28`}`,
          border: `${theme === 'dark' && '1px solid #f5f5f5'}`,
        },
      });
      return;
    }
  };

  return (
    <>
      <Toaster position='top-center' reverseOrder={false} />
      <main className={`${styles.main} ${theme === 'dark' && styles.mainDark}`}>
        <section className={`${styles.section} ${theme === 'dark' && styles.sectionDark}`}>
          <div className={styles.message}>
            {!logoLoading && (
              <img
                src={theme === 'dark' && domainStatus === 'invalid' ? darkLogo : logoUrl}
                className={styles.logo}
                alt='logo'
              />
            )}
            <h2 className={`${styles.heading} ${theme === 'dark' && styles.headingDark}`}>
              Are you sure you want to cancel the Subscription?
            </h2>

            <div className={styles.buttonGroup}>
              <button className={styles.confirmButton} onClick={cancelSubscriptionRequest}>
                Yes
              </button>
              <button className={styles.cancelButton} onClick={handleNo}>
                No
              </button>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default CancelSubscription;
