interface Props {
  isBigCheck?: boolean;
  className?: string;
}
const SVGCheck = ({ isBigCheck = false, className }: Props) => {
  return !isBigCheck ? (
    <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.9693 15.308L12.7543 15.689H11.9143L11.6613 15.353C11.6463 15.33 10.1593 13.108 8.2373 11.892L7.6023 11.492L8.4043 10.224L9.0373 10.624C10.4223 11.499 11.5723 12.777 12.2503 13.63C13.3153 12.025 15.8093 8.682 19.6343 5.971C17.8473 3.709 15.0863 2.25 11.9873 2.25C6.6113 2.25 2.2373 6.624 2.2373 12C2.2373 17.376 6.6113 21.75 11.9873 21.75C17.3633 21.75 21.7373 17.376 21.7373 12C21.7373 10.263 21.2753 8.635 20.4763 7.221C15.6433 10.667 12.9983 15.257 12.9693 15.308Z'
        fill='var(--primary-color)'
      />
    </svg>
  ) : (
    <svg
      width='50'
      height='49'
      viewBox='0 0 50 49'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M24.9998 0.375244C11.676 0.375244 0.875 11.1763 0.875 24.5C0.875 37.8237 11.676 48.6248 24.9998 48.6248C38.3235 48.6248 49.1245 37.8237 49.1245 24.5C49.1245 11.1763 38.3235 0.375244 24.9998 0.375244Z'
        fill='var(--secondary-color)'
      />
      <path
        d='M34.7892 12.2885L21.2923 22.2556L15.0458 19.5386C11.0492 17.4949 7.42061 20.8029 9.7489 24.8355L16.8756 35.4921C18.8258 38.2252 23.4941 38.3794 25.7023 35.4921L40.0794 17.5853C42.9292 13.7266 38.4382 9.53021 34.7892 12.2885Z'
        fill='white'
      />
    </svg>
  );
};
export default SVGCheck;
