import axios, { AxiosError } from 'axios';
import { AuthModel, updatedDataModel, UserModel } from './_models';
import {
  AddBuildingRequest,
  ApiResponse,
  buildingDetailsResponse,
  IDVSessionResponse,
  PersonalInfoRequest,
  tenantDetailsResponse,
  IDVresponse,
  rentResponse,
  transactionsResponse,
  kycIndustryResponse,
  ForgotPasswordEmailRequest,
  VerifyPasswordEmailRequest,
  NodeEnvEnum,
  payRentResponse,
  subscribeResponse,
  AddBuildingManager,
  compnayResponse,
  UpdateBuildingRequest,
  buildingsSubdomainResponse,
  unitDetailsResponse,
  UnitRequest,
  payNumberRequest,
  payNumberResponse,
  subdomainResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
  OpenOrderResponse,
  PaymentOptionRequest,
  PaymentOption,
  UpdateCardRequestBody,
  TransactionsStatusResponse,
  PaymentSummaryResponse,
  SubscriptionDetailResponse,
  InitiateMFAResponse,
  mfaResponse,
  mfaCookieResponse,
  termsResponse,
  termsRequest,
  checkConsentResponse,
  processingFeeDetailsResponse,
} from '../helpers/utils/types';
import { axiosInstance } from '../configs/axios';

export let APP_ENV: NodeEnvEnum = import.meta.env.VITE_APP_ENV as NodeEnvEnum;
export let API_URL: string;
export let NUVEI_ENV: string;
export let NUVEI_MERCHANT_ID: string;
export let NUVEI_MERCHANT_SITE_ID: string;

// Set the environment variables based on the hostname
if (import.meta.env.VITE_APP_ENV) {
  APP_ENV = import.meta.env.VITE_APP_ENV as NodeEnvEnum;
} else {
  const hostname = window.location.hostname;
  if (hostname.includes('localhost')) {
    NUVEI_ENV = 'int';
    NUVEI_MERCHANT_ID = '3310682875994519434';
    NUVEI_MERCHANT_SITE_ID = '241708';
    APP_ENV = NodeEnvEnum.Local;
  } else if (hostname.includes('staging-')) {
    NUVEI_ENV = 'int';
    NUVEI_MERCHANT_ID = '3310682875994519434';
    NUVEI_MERCHANT_SITE_ID = '241708';

    APP_ENV = NodeEnvEnum.Staging;
  } else {
    APP_ENV = NodeEnvEnum.Production;
    NUVEI_ENV = 'prod';
    NUVEI_MERCHANT_ID = '285181898739894717';
    NUVEI_MERCHANT_SITE_ID = '317929';
  }
}
switch (APP_ENV) {
  case NodeEnvEnum.Local:
    API_URL = 'http://localhost:3000/api/v2';
    break;
  case NodeEnvEnum.Staging:
    API_URL = 'https://staging-api.tenantpay.com/api/v2';
    break;
  case NodeEnvEnum.Production:
    API_URL = import.meta.env.VITE_APP_API_URL || 'https://api.tenantpay.com/api/v2';
    break;
  default:
    console.warn('Unknown environment, defaulting to production API.');
    API_URL = 'https://api.tenantpay.com/api/v2';
}

// List of API endpoints
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/user/verifyUserToken`;
export const LOGIN_URL = `${API_URL}/login/verifyTenantLoginDetails`;
export const REGISTER_URL = `${API_URL}/user/createTenant`;
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`;
export const VERIFY_SIGNUP_URL = `${API_URL}/user/verifySignUp`;
export const VERIFY_PHONE_URL = `${API_URL}/tenant/verifyCodeOnSms`;
export const SEND_EMAIL_CODE = `${API_URL}/login/sendVerificationCode`;
export const SEND_PHONE_CODE = `${API_URL}/tenant/sendCodeOnSms`;
export const SEND_PHONE_CODE_UPDATE = `${API_URL}/verification-code/send-code/phone-number`;
export const VERIFY_PHONE_CODE_UPDATE = `${API_URL}/verification-code/verify/phone-number`;
export const UPDATE_USER_PROFILE = `${API_URL}/user/updateUserProfile`;
export const UPDATE_TENANT_URL = `${API_URL}/tenant/updateTenant`;
export const ADD_BUILDING_URL = `${API_URL}/tenant-building/addBuilding`;
export const IDV_SESSION_URL = `${API_URL}/tenant/createIdvSession`;
export const GET_TENANT_DETAILS = `${API_URL}/tenant/getTenantByUserId`;
export const GET_BUILDING_DETAILS = `${API_URL}/tenant-building/getBuildingsByUserId`;
export const OPEN_ORDER_API = `${API_URL}/nuvei/openOrder`;
export const GET_IDV_STATUS = `${API_URL}/tenant/idvStatus`;
export const GET_RENT_PAID = `${API_URL}/tenant-building/getTotalRentPaid`;
export const GET_TRANSACTIONS = `${API_URL}/tenant/transactions`;
export const GET_INDUSTRY_MENU = `${API_URL}/kyc/businessTypes`;
export const FORGOT_PASSWORD_EMAIL_URL = `${API_URL}/login/forgotPasswordEmail`;
export const VERIFY_PASSWORD_EMAIL_URL = `${API_URL}/login/forgotPasswordReset`;
export const PAY_RENT = `${API_URL}/payment/tenant/rent`;
export const SUBSCRIBE_PAYMENT = `${API_URL}/payment/tenant/subscribe`;
export const UPDATE_BUILDING = `${API_URL}/tenant-building/updateBuilding`;
export const ADD_BUILDING_MANAGER_URL = `${API_URL}/tenant-building/manager`;
export const GET_COMPANY_SETTING = `${API_URL}/company-setting`;
export const GET_BUILDINGS_SUBDOMAIN = `${API_URL}/select/building/subdomain`;
export const GET_UNITS_SUBDOMAIN = `${API_URL}/select/unit/subdomain`;
export const CREATE_UNIT = `${API_URL}/unit`;
export const GENERATE_PAY_NUMBER = `${API_URL}/resident/pay-number`;
export const GET_SUBDOMAIN_STATUS = `${API_URL}/subdomain/validate`;
export const VALIDATE_PAY_NUMBER = `${API_URL}/resident/validate-pay-number`;
export const LOGIN_RESET_PASSWORD = `${API_URL}/login/resetPassword`;
export const CREATE_PAYMENT_OPTION = `${API_URL}/payment/paymentOption`;
export const GET_PAYMENT_OPTIONS = `${API_URL}/payment/paymentOption`;
export const GET_BUILDING_BY_ID = `${API_URL}/tenant-building/getBuildingsByUserId`;
export const UPDATE_CARD_DETAILS = `${API_URL}/payment/paymentOption`;
export const REMOVE_CARD = `${API_URL}/payment/paymentOption`;
export const getTransactionStatusUrl = (id: string) =>
  `${API_URL}/tenant-transaction/tenant-buildings/${id}/payment-schedule`;
export const SUBSCRIBE = `${API_URL}/payment/tenant/subscribe`;
export const SUBSCRIPTION = `${API_URL}/payment/subscription`;
export const INITIATE_MFA = `${API_URL}/mfa/initiate/phone-number`;
export const VERIFY_MFA = `${API_URL}/mfa/verify/phone-number`;
export const CREATE_JWT = `${API_URL}/login/token`;
export const GET_MFA_STATUS = `${API_URL}/mfa/validate`;
export const GET_TERMS_CONTENT = `${API_URL}/terms-and-conditions?name=tenantTermsAndCondition`;
export const CHECK_CONSENT = `${API_URL}/terms-and-conditions/check-user-consent?name=tenantTermsAndCondition`;
export const SET_USER_CONSENT = `${API_URL}/terms-and-conditions/user-consent`;
export const GET_PROCESSING_FEE_DETAILS = `${API_URL}/payment/tenant/processing-fee`;

// Server should return AuthModel
export function login(email: string, password: string) {
  return axiosInstance.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  });
}

// Server should return AuthModel
export async function register(
  email: string,
  firstName: string,
  lastName: string,
  phoneNumber: string,
  password: string,
  phoneNumberExtension: string
) {
  try {
    const response = await axiosInstance.post<AuthModel>(REGISTER_URL, {
      email,
      firstName,
      lastName,
      phoneNumber,
      password,
      phoneNumberExtension,
    });
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<ApiResponse>;
      if (axiosError.response && axiosError.response.data) {
        const { errorMessage } = axiosError.response.data;
        // Check if the error is about an invalid phone number
        if (
          Array.isArray(errorMessage) &&
          errorMessage.includes('phoneNumber must be a valid phone number')
        ) {
          throw new Error('invalid phoneNumber');
        } else if (errorMessage?.includes('email_already_exist')) {
          throw new Error('email already registered');
        }

        throw new Error(`Registration error: ${axiosError.response.data.message}`);
      } else {
        throw new Error(`Registration error: ${error.message}`);
      }
    } else {
      throw new Error(`Unexpected error: ${error}`);
    }
  }
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axiosInstance.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  });
}

export function getUserByToken(token: string) {
  return axiosInstance.post<UserModel>(
    GET_USER_BY_ACCESSTOKEN_URL,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}
export async function verifyVerificationCodeEmail(email: string, token: string, code: string) {
  try {
    const response = await axiosInstance.post<ApiResponse>(
      VERIFY_SIGNUP_URL,
      {
        request: {
          email: email,
          verificationCode: code,
          digit: 4,
        },
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<ApiResponse>;
      if (axiosError.response && axiosError.response.data) {
        console.error('Axios error response data:', axiosError.response.data);
        throw new Error(`Error verifying code: ${axiosError.response.data.message}`);
      } else {
        console.error('Axios error message:', error.message);
        throw new Error(`Error verifying code: ${error.message}`);
      }
    } else {
      console.error('Unexpected error:', error);
      throw new Error(`Error verifying code: ${error}`);
    }
  }
}
export const sendVerificationCodeEmail = async (
  email: string | undefined,
  digit: 4,
  token: string | undefined
) => {
  try {
    digit = 4;
    const response = await axiosInstance.post<void>(
      SEND_EMAIL_CODE,
      { email, digit },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 201) {
      return;
    } else {
      console.error('Failed to send verification code:', response.data);
    }
  } catch (error) {
    console.error('Error sending verification code:', error);
  }
};
export async function updateUserProfile(token: string | undefined, userData: updatedDataModel) {
  try {
    const response = await axiosInstance.post<ApiResponse>(
      UPDATE_USER_PROFILE,
      { request: userData },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<ApiResponse>;
      if (axiosError.response && axiosError.response.data) {
        throw new Error(`Error updating profile: ${axiosError.response.data.message}`);
      } else {
        throw new Error(`Error updating profile: ${error.message}`);
      }
    } else {
      throw new Error(`Error updating profile: ${error}`);
    }
  }
}
export const sendVerificationCodePhone = async (
  phoneNumber: string | undefined,
  digit: 4,
  token: string | undefined
) => {
  try {
    digit = 4;
    const response = await axiosInstance.post<void>(
      SEND_PHONE_CODE,
      { phoneNumber, digit },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 201) {
      return;
    } else {
      console.error('Failed to send verification code:', response.data);
    }
  } catch (error) {
    console.error('Error sending verification code:', error);
  }
};
export async function verifyVerificationCodePhone(
  phoneNumber: string,
  token: string,
  code: string
) {
  try {
    const response = await axiosInstance.post<ApiResponse>(
      VERIFY_PHONE_URL,
      {
        phoneNumber: phoneNumber,
        verificationCode: code,
        digit: 4,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          Origin: APP_ENV,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<ApiResponse>;
      if (axiosError.response && axiosError.response.data) {
        const { message, errorMessage } = axiosError.response.data;
        if (message === 'phone_number_already_verified') {
          return { message: 'phone_number_already_verified' };
        }
        if (
          Array.isArray(errorMessage) &&
          errorMessage.includes('phoneNumber must be a valid phone number')
        ) {
          throw new Error('invalid phoneNumber');
        }
        console.error('Axios error response data:', axiosError.response.data);
        throw new Error(`Error verifying code: ${message}`);
      } else {
        console.error('Axios error message:', error.message);
        throw new Error(`Error verifying code: ${error.message}`);
      }
    } else {
      console.error('Unexpected error:', error);
      throw new Error(`Error verifying code: ${error}`);
    }
  }
}

export const sendVerificationCodePhoneUpdate = async (
  phoneNumber: string | undefined,
  digit: 4,
  token: string | undefined
) => {
  try {
    digit = 4;
    const response = await axios.post<void>(
      SEND_PHONE_CODE_UPDATE,
      { phoneNumber, digit },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 201) {
      return;
    } else {
      console.error('Failed to send verification code:', response.data);
    }
  } catch (error) {
    console.error('Error sending verification code:', error);
  }
};

export const verifyVerificationCodePhoneUpdate = async (
  phoneNumber: string,
  token: string,
  code: string
) => {
  try {
    const response = await axios.post<ApiResponse>(
      VERIFY_PHONE_CODE_UPDATE,
      {
        phoneNumber: phoneNumber,
        verificationCode: code,
        digit: 4,
      },
      {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<ApiResponse>;
      if (axiosError.response && axiosError.response.data) {
        const { message } = axiosError.response.data;
        switch (message) {
          case 'invalid_verification_code':
            return { message: 'invalid_verification_code' };
          case 'phone_number_does_not_exist':
            return { message: 'phone_number_does_not_exist' };
          case 'verification_code_expired':
            return { message: 'verification_code_expired' };
          case 'verification_attempts_exceeded':
            return { message: 'verification_attempts_exceeded' };
          default:
            console.error('Axios error response data:', axiosError.response.data);
            throw new Error(`Error verifying code: ${message}`);
        }
      } else {
        console.error('Axios error message:', error.message);
        throw new Error(`Error verifying code: ${error.message}`);
      }
    } else {
      console.error('Unexpected error:', error);
      throw new Error(`Error verifying code: ${error}`);
    }
  }
};

export async function updateTenantDetails(
  token: string | undefined,
  request: { tenantDetails: { isIndividualAccount: boolean; isNewProperty: boolean } }
) {
  try {
    const response = await axiosInstance.patch<ApiResponse>(UPDATE_TENANT_URL, request, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<ApiResponse>;
      if (axiosError.response && axiosError.response.data) {
        throw new Error(`Error updating tenant: ${axiosError.response.data.message}`);
      } else {
        throw new Error(`Error updating tenant: ${error.message}`);
      }
    } else {
      throw new Error(`Error updating tenant: ${error}`);
    }
  }
}
export async function updatePersonalInfo(token: string | undefined, request: PersonalInfoRequest) {
  try {
    const response = await axiosInstance.patch<ApiResponse>(UPDATE_TENANT_URL, request, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<ApiResponse>;
      if (axiosError.response && axiosError.response.data) {
        throw new Error(`Error updating tenant Personal Info: ${axiosError.response.data.message}`);
      } else {
        throw new Error(`Error updating tenant Personal Info: ${error.message}`);
      }
    } else {
      throw new Error(`Error updating tenant Personal Info: ${error}`);
    }
  }
}
export async function addBuilding(token: string | undefined, requestBody: AddBuildingRequest) {
  try {
    const response = await axiosInstance.post<ApiResponse>(ADD_BUILDING_URL, requestBody, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<ApiResponse>;
      if (axiosError.response && axiosError.response.data) {
        const errorMessage = axiosError.response.data.message;
        if (errorMessage === 'resident_not_found') {
          throw new Error('payNumber_not_found');
        } else {
          throw new Error(`Error adding building: ${errorMessage}`);
        }
      } else {
        throw new Error(`Error adding building: ${error.message}`);
      }
    } else {
      throw new Error(`Error adding building: ${error}`);
    }
  }
}

export async function createIDVSession(token: string | undefined) {
  try {
    const response = await axiosInstance.post<IDVSessionResponse>(
      IDV_SESSION_URL,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<ApiResponse>;
      if (
        axiosError.response &&
        axiosError.response.data.message === 'tenant_is_already_approved'
      ) {
        throw new Error('Tenant is already approved');
      }
      if (axiosError.response && axiosError.response.data) {
        console.error('Axios error response data:', axiosError.response.data);
        throw new Error(`Error creating IDV session: ${axiosError.response.data.message}`);
      } else {
        console.error('Axios error message:', error.message);
        throw new Error(`Error creating IDV session: ${error.message}`);
      }
    } else {
      console.error('Unexpected error:', error);
      throw new Error(`Error creating IDV session: ${error}`);
    }
  }
}
export async function getTenantDetails(token: string | undefined) {
  try {
    const response = await axiosInstance.get<tenantDetailsResponse>(GET_TENANT_DETAILS, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<tenantDetailsResponse>;
      if (axiosError.response && axiosError.response.data) {
        console.error('Axios error response data:', axiosError.response.data);
        throw new Error(`Error getting tenant details: ${axiosError.response.data.message}`);
      } else {
        console.error('Axios error message:', error.message);
        throw new Error(`Error getting tenant details: ${error.message}`);
      }
    } else {
      console.error('Unexpected error:', error);
      throw new Error(`Error getting tenant details: ${error}`);
    }
  }
}
export async function getBuildingDetails(token: string | undefined) {
  try {
    const response = await axiosInstance.get<buildingDetailsResponse>(GET_BUILDING_DETAILS, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<buildingDetailsResponse>;
      if (axiosError.response && axiosError.response.data) {
        console.error('Axios error response data:', axiosError.response.data);
        throw new Error(`Error getting building details: ${axiosError.response.data.message}`);
      } else {
        console.error('Axios error message:', error.message);
        throw new Error(`Error getting building details: ${error.message}`);
      }
    } else {
      console.error('Unexpected error:', error);
      throw new Error(`Error getting building details: ${error}`);
    }
  }
}
export async function getIDVStatus(token: string | undefined) {
  try {
    const response = await axiosInstance.get<IDVresponse>(GET_IDV_STATUS, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<IDVresponse>;
      if (axiosError.response && axiosError.response.data) {
        if (axiosError.response.data.message === 'session_id_not_found') {
          return { message: 'session_id_not_found' };
        }
        console.error('Axios error response data:', axiosError.response.data);
        throw new Error(`Error getting IDV status: ${axiosError.response.data.message}`);
      } else {
        console.error('Axios error message:', error.message);
        throw new Error(`Error getting IDV status: ${error.message}`);
      }
    } else {
      console.error('Unexpected error:', error);
      throw new Error(`Error getting IDV status: ${error}`);
    }
  }
}

export async function getRentPaid(token: string | undefined) {
  try {
    const response = await axiosInstance.get<rentResponse>(GET_RENT_PAID, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<rentResponse>;
      if (axiosError.response && axiosError.response.data) {
        console.error('Axios error response data:', axiosError.response.data);
        throw new Error(`Error getting Rent Paid: ${axiosError.response.data.message}`);
      } else {
        console.error('Axios error message:', error.message);
        throw new Error(`Error getting Rent Paid: ${error.message}`);
      }
    } else {
      console.error('Unexpected error:', error);
      throw new Error(`Error getting Rent Paid: ${error}`);
    }
  }
}
export async function getTransactions(token: string | undefined, page: number, limit: number) {
  try {
    const response = await axiosInstance.get<transactionsResponse>(
      `${GET_TRANSACTIONS}?orderBy=responseTimestamp_DESC&limit=${limit}&page=${page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<transactionsResponse>;
      if (axiosError.response && axiosError.response.data) {
        console.error('Axios error response data:', axiosError.response.data);
        throw new Error(`Error getting transactions: ${axiosError.response.data.message}`);
      } else {
        console.error('Axios error message:', error.message);
        throw new Error(`Error getting transactions: ${error.message}`);
      }
    } else {
      console.error('Unexpected error:', error);
      throw new Error(`Error getting transactions: ${error}`);
    }
  }
}
export async function getIndustryMenu(token: string | undefined) {
  try {
    const response = await axiosInstance.get<kycIndustryResponse>(GET_INDUSTRY_MENU, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<transactionsResponse>;
      if (axiosError.response && axiosError.response.data) {
        console.error('Axios error response data:', axiosError.response.data);
        throw new Error(`Error getting KYC Industry: ${axiosError.response.data.message}`);
      } else {
        console.error('Axios error message:', error.message);
        throw new Error(`Error getting KYC Industry: ${error.message}`);
      }
    } else {
      console.error('Unexpected error:', error);
      throw new Error(`Error getting KYC Industry: ${error}`);
    }
  }
}
export async function updateTenantPhone(
  token: string | undefined,
  request: { userDetails: { phoneNumber: string } }
) {
  try {
    const response = await axiosInstance.patch<ApiResponse>(UPDATE_TENANT_URL, request, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<ApiResponse>;
      if (axiosError.response && axiosError.response.data) {
        throw new Error(`Error updating tenant: ${axiosError.response.data.message}`);
      } else {
        throw new Error(`Error updating tenant: ${error.message}`);
      }
    } else {
      throw new Error(`Error updating tenant: ${error}`);
    }
  }
}
export async function sendForgotPasswordEmail(baseUrlName: string, userName: string) {
  const requestBody: ForgotPasswordEmailRequest = {
    baseUrlName,
    userName,
  };

  try {
    const response = await axiosInstance.post<ApiResponse>(FORGOT_PASSWORD_EMAIL_URL, requestBody, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<ApiResponse>;
      if (axiosError.response && axiosError.response.data) {
        if (axiosError.response.data.message === 'User does not exist') {
          return { message: 'User does not exist' };
        }
        console.error('Axios error response data:', axiosError.response.data);
        throw new Error(`Error sending reset link: ${axiosError.response.data.message}`);
      } else {
        console.error('Axios error message:', error.message);
        throw new Error(`Error sending reset link: ${error.message}`);
      }
    } else {
      console.error('Unexpected error:', error);
      throw new Error(`Error sending reset link: ${error}`);
    }
  }
}
export async function verifyForgotPasswordEmail(hash: string | undefined, password: string) {
  const requestBody: VerifyPasswordEmailRequest = {
    payload: {
      hash: hash || '',
      password: password,
    },
  };
  try {
    const response = await axiosInstance.post<ApiResponse>(VERIFY_PASSWORD_EMAIL_URL, requestBody, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<ApiResponse>;
      if (axiosError.response && axiosError.response.data) {
        if (axiosError.response.data.message === 'User does not exist') {
          return { message: 'User does not exist' };
        }
        console.error('Axios error response data:', axiosError.response.data);
        throw new Error(`Error verifying password: ${axiosError.response.data.message}`);
      } else {
        console.error('Axios error message:', error.message);
        throw new Error(`Error verifying password: ${error.message}`);
      }
    } else {
      console.error('Unexpected error:', error);
      throw new Error(`Error verifying password: ${error}`);
    }
  }
}
export async function payRent(token: string | undefined, tenantBuildingId: number) {
  try {
    const response = await axiosInstance.post<payRentResponse>(
      `${PAY_RENT}/${tenantBuildingId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<payRentResponse>;
      if (axiosError.response && axiosError.response.data) {
        throw new Error(`Error paying rent: ${axiosError.response.data}`);
      } else {
        throw new Error(`Error paying rent: ${error.message}`);
      }
    } else {
      throw new Error(`Error paying rent: ${error}`);
    }
  }
}

export async function subscribePayment(
  token: string | undefined,
  tenantBuildingId: number,
  rentTenure: number
): Promise<subscribeResponse | ApiResponse> {
  try {
    const response = await axiosInstance.post<subscribeResponse>(
      `${SUBSCRIBE_PAYMENT}/${tenantBuildingId}`,
      {
        includeRentForMonths: rentTenure,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<ApiResponse>;
      if (axiosError.response && axiosError.response.data) {
        const errorMessage = axiosError.response.data.message;
        if (errorMessage === 'error_creating_plan') {
          throw { message: 'error_creating_plan' };
        } else {
          throw new Error(`Error subscribing payment: ${errorMessage}`);
        }
      } else {
        throw new Error(`Error subscribing payment: ${error.message}`);
      }
    } else {
      throw new Error(`Error subscribing payment: ${error}`);
    }
  }
}
export async function updateBuilding(
  token: string | undefined,
  tenantBuildingId: string | undefined,
  requestBody: UpdateBuildingRequest
) {
  try {
    const response = await axiosInstance.patch<ApiResponse>(
      `${UPDATE_BUILDING}/${tenantBuildingId}`,
      requestBody,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<ApiResponse>;
      if (axiosError.response && axiosError.response.data) {
        throw new Error(`Error updating building: ${axiosError.response.data.message}`);
      } else {
        throw new Error(`Error updating building: ${error.message}`);
      }
    } else {
      throw new Error(`Unexpected error: ${error}`);
    }
  }
}
export async function addBuildingManager(
  token: string | undefined,
  requestBody: AddBuildingManager
) {
  try {
    const response = await axiosInstance.post<ApiResponse>(ADD_BUILDING_MANAGER_URL, requestBody, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error adding building using manager details:', error);
  }
}
export async function getCompanySettings() {
  try {
    const response = await axiosInstance.get<compnayResponse>(GET_COMPANY_SETTING);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<ApiResponse>;
      if (axiosError.response && axiosError.response.data) {
        if (axiosError.response.data.message === 'invalid_domain') {
          return { message: 'invalid_domain' };
        }
        if (axiosError.response.data.message === 'subdomain_not_found') {
          return { message: 'subdomain_not_found' };
        }
        throw new Error(`Error fetching company settings: ${axiosError.response.data.message}`);
      }
    } else {
      console.error('Unexpected error:', error);
      throw new Error(`Error verifying code: ${error}`);
    }
  }
}
export const uploadToS3 = async (file: File, uploadUrl: string) => {
  await axiosInstance.put(uploadUrl, file, {
    headers: {
      'Content-Type': file.type,
    },
    withCredentials: false,
  });
};
export const getPresignedUrl = async (
  filename: string,
  fileType: string,
  token: string | undefined,
  destination: string
) => {
  const response = await axiosInstance.post(
    `${API_URL}/upload/preSignedUrl`,
    {
      filename,
      fileType,
      destination,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return {
    url: response.data.url,
    uploadUrl: response.data.uploadUrl,
    downloadUrl: response.data.downloadUrl,
  };
};
export const getBuildingsBySubdomain = async (
  token: string | undefined,
  limit?: number,
  searchQuery?: string
) => {
  try {
    const params: Record<string, number | string | undefined> = {};
    if (limit) params.limit = limit;
    if (searchQuery) params.searchQuery = searchQuery;
    const response = await axiosInstance.get<buildingsSubdomainResponse>(GET_BUILDINGS_SUBDOMAIN, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<buildingsSubdomainResponse>;
      if (axiosError.response && axiosError.response.data) {
        throw new Error(`Error fetching buildings: ${axiosError.response.data.message}`);
      }
    }
    console.error('Unexpected error:', error);
    throw new Error('An unexpected error occurred while fetching buildings');
  }
};
export const getUnitsBySubdomain = async (
  token: string | undefined,
  buildingId: number,
  limit?: number,
  searchQuery?: string
) => {
  try {
    const params: Record<string, number | string | undefined> = {};
    if (limit) params.limit = limit;
    else params.limit = 50;
    if (searchQuery) params.searchQuery = searchQuery;
    params.buildingId = buildingId;
    const response = await axiosInstance.get<unitDetailsResponse>(GET_UNITS_SUBDOMAIN, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<unitDetailsResponse>;
      if (axiosError.response && axiosError.response.data) {
        throw new Error(`Error fetching units of building: ${axiosError.response.data.message}`);
      }
    }
    console.error('Unexpected error:', error);
    throw new Error('An unexpected error occurred while fetching building units.');
  }
};
export async function createUnit(token: string | undefined, unitBody: UnitRequest) {
  try {
    const response = await axiosInstance.post<ApiResponse>(CREATE_UNIT, unitBody, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<ApiResponse>;
      if (axiosError.response?.data.message === 'unit_number_already_exist') {
        throw new Error('unit_exist');
      }
      if (axiosError.response && axiosError.response.data) {
        throw new Error(`Error creating new unit: ${axiosError.response.data.message}`);
      } else {
        throw new Error(`Error creating new unit: ${error.message}`);
      }
    } else {
      throw new Error(`Error creating new unit: ${error}`);
    }
  }
}

export async function createPaymentOption(
  token: string | undefined,
  paymentOptionBody: PaymentOptionRequest
) {
  try {
    const response = await axiosInstance.post<ApiResponse>(
      CREATE_PAYMENT_OPTION,
      paymentOptionBody,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<ApiResponse>;
      if (axiosError.response && axiosError.response.data) {
        throw new Error(`Error creating payment option: ${axiosError.response.data.message}`);
      } else {
        throw new Error(`Error creating payment option: ${error.message}`);
      }
    } else {
      throw new Error(`Error creating payment option: ${error}`);
    }
  }
}

export async function generatePayNumber(token: string | undefined, request: payNumberRequest) {
  try {
    const response = await axiosInstance.post<payNumberResponse>(GENERATE_PAY_NUMBER, request, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<ApiResponse>;
      if (axiosError.response && axiosError.response.data) {
        throw new Error(`Error creating new unit: ${axiosError.response.data.message}`);
      } else {
        throw new Error(`Error creating new unit: ${error.message}`);
      }
    } else {
      throw new Error(`Error creating new unit: ${error}`);
    }
  }
}
export async function checkSubdomainValid(
  setDomainStatus: React.Dispatch<React.SetStateAction<'valid' | 'invalid' | null>>
) {
  try {
    const response = await axiosInstance.get<subdomainResponse>(GET_SUBDOMAIN_STATUS);
    const { isValid, isReserved } = response.data.data;
    // Set domain status invalid if isReserved e.g 'staging-app'
    if (isValid && (isReserved === null || isReserved === false)) {
      setDomainStatus('valid');
    } else {
      setDomainStatus('invalid');
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<ApiResponse>;
      if (axiosError.response && axiosError.response.data) {
        console.error(`Error getting subdomain status: ${axiosError.response.data.message}`);
      } else {
        console.error(`Error getting subdomain status: ${error.message}`);
      }
    } else {
      console.error(`Error getting subdomain status: ${error}`);
    }
    // Set domain status to invalid if an error occurs
    setDomainStatus('invalid');
  }
}
export async function checkPayNumber(token: string | undefined, payNumber: string) {
  try {
    const response = await axiosInstance.get<payNumberResponse>(
      `${VALIDATE_PAY_NUMBER}?payNumber=${payNumber}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<tenantDetailsResponse>;
      if (axiosError.response && axiosError.response.data) {
        const errorMessage = axiosError.response.data.message;

        // Handle "pay_number_not_found" error
        if (errorMessage === 'pay_number_not_found') {
          console.error('Pay number not found:', errorMessage);
          throw new Error('pay_number_not_found');
        }
        if (errorMessage === 'pay_number_does_not_belong_to_the_company') {
          console.error('Pay number does not belong to company:', errorMessage);
          throw new Error('pay_number_does_not_belong_to_the_company');
        }
        // Handle other errors
        console.error('Error validating pay number:', errorMessage);
        throw new Error(`Error validating pay number: ${errorMessage}`);
      } else {
        console.error('Axios error message:', error.message);
        throw new Error(`Error validating pay number: ${error.message}`);
      }
    } else {
      console.error('Unexpected error:', error);
      throw new Error(`Error validating pay number: ${error}`);
    }
  }
}
export async function ResetPassword(token: string | undefined, requestBody: ResetPasswordRequest) {
  try {
    const response = await axiosInstance.post<ResetPasswordResponse>(
      LOGIN_RESET_PASSWORD,
      requestBody,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error resetting password:', error);
  }
}

export async function openOrder(token: string | undefined, tenantBuildingId?: number) {
  try {
    const response = await axiosInstance.get<OpenOrderResponse>(
      `${OPEN_ORDER_API}?transactionType=Auth`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          tenantBuildingId,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<OpenOrderResponse>;
      if (axiosError.response && axiosError.response.data) {
        console.error('Axios error response data:', axiosError.response.data);
        throw new Error(`Error opening order: ${axiosError.response.data.reason}`);
      } else {
        console.error('Axios error message:', error.message);
        throw new Error(`Error opening order: ${error.message}`);
      }
    } else {
      console.error('Unexpected error:', error);
      throw new Error(`Error opening order: ${error}`);
    }
  }
}
export async function openOrderWithoutAuth(
  token: string | undefined,
  tenantBuildingId?: string,
  tenantPaymentOptionId?: string,
  isRebilling?: string
) {
  // Construct query parameters conditionally
  const queryParams = new URLSearchParams();
  if (tenantBuildingId) queryParams.append('tenantBuildingId', tenantBuildingId);
  if (tenantPaymentOptionId) queryParams.append('tenantPaymentOptionId', tenantPaymentOptionId);
  if (isRebilling) queryParams.append('isRebilling', isRebilling);

  const url = `${OPEN_ORDER_API}?${queryParams.toString()}`;

  try {
    const response = await axiosInstance.get<OpenOrderResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<OpenOrderResponse>;
      if (axiosError.response && axiosError.response.data) {
        console.error('Axios error response data:', axiosError.response.data);
        throw new Error(`Error opening order: ${axiosError.response.data.reason}`);
      } else {
        console.error('Axios error message:', error.message);
        throw new Error(`Error opening order: ${error.message}`);
      }
    } else {
      console.error('Unexpected error:', error);
      throw new Error(`Error opening order: ${error}`);
    }
  }
}

export async function getPaymentOptions(token: string | undefined): Promise<PaymentOption[]> {
  try {
    const response = await axiosInstance.get<PaymentOption[]>(`${GET_PAYMENT_OPTIONS}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<PaymentOption[]>;
      if (axiosError.response && axiosError.response.data) {
        console.error('Axios error response data:', axiosError.response.data);
        throw new Error(`Error fetching payment options: ${axiosError.response.data}`);
      } else {
        console.error('Axios error message:', error.message);
        throw new Error(`Error fetching payment options: ${error.message}`);
      }
    } else {
      console.error('Unexpected error:', error);
      throw new Error(`Error fetching payment options: ${error}`);
    }
  }
}

export async function getBuildingDetailsById(
  token: string | undefined,
  tenantBuildingId?: string // Optional as per the API
) {
  try {
    const response = await axiosInstance.get<buildingDetailsResponse>(
      GET_BUILDING_BY_ID, // Your base API endpoint
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          tenantBuildingId, // Send tenantBuildingId as a query parameter
        },
      }
    );

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<buildingDetailsResponse>;
      if (axiosError.response && axiosError.response.data) {
        console.error('Axios error response data:', axiosError.response.data);
        throw new Error(`Error getting building details: ${axiosError.response.data.message}`);
      } else {
        console.error('Axios error message:', error.message);
        throw new Error(`Error getting building details: ${error.message}`);
      }
    } else {
      console.error('Unexpected error:', error);
      throw new Error(`Error getting building details: ${error}`);
    }
  }
}

export async function updateCardDetails(
  token: string | undefined,
  paymentOptionId: string | undefined,
  requestBody: UpdateCardRequestBody
) {
  try {
    const response = await axiosInstance.patch<ApiResponse>(
      `${UPDATE_CARD_DETAILS}/${paymentOptionId}`,
      requestBody,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<ApiResponse>;
      if (axiosError.response && axiosError.response.data) {
        throw new Error(`Error updating payment option: ${axiosError.response.data.message}`);
      } else {
        throw new Error(`Error updating payment option: ${error.message}`);
      }
    } else {
      throw new Error(`Unexpected error: ${error}`);
    }
  }
}

export async function deleteCard(
  token: string | undefined,
  tenantPaymentOptionId: string | undefined
) {
  try {
    const response = await axiosInstance.delete<ApiResponse>(
      `${REMOVE_CARD}/${tenantPaymentOptionId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<ApiResponse>;
      if (axiosError.response && axiosError.response.data) {
        throw new Error(`Error deleting payment option: ${axiosError.response.data.message}`);
      } else {
        throw new Error(`Error deleting payment option: ${error.message}`);
      }
    } else {
      throw new Error(`Unexpected error: ${error}`);
    }
  }
}
export async function getTransactionsStatus(tenantBuildingId: string, token: string | undefined) {
  try {
    const response = await axiosInstance.get<TransactionsStatusResponse>(
      getTransactionStatusUrl(tenantBuildingId),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<TransactionsStatusResponse>;
      if (axiosError.response && axiosError.response.data) {
        console.error('Axios error response data:', axiosError.response.data);
        throw new Error(`Error fetching transaction status: ${axiosError.response.data.message}`);
      } else {
        console.error('Axios error message:', error.message);
        throw new Error(`Error fetching transaction status: ${error.message}`);
      }
    } else {
      console.error('Unexpected error:', error);
      throw new Error(`Error fetching transaction status: ${error}`);
    }
  }
}
export async function getPaymentSummary(
  token: string | undefined,
  tenantBuildingId: string | undefined,
  tenantPaymentOptionId: string | undefined
) {
  try {
    const response = await axiosInstance.get<PaymentSummaryResponse>(
      `${API_URL}/payment/tenant/rent/${tenantBuildingId}/summary`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        params: {
          tenantPaymentOptionId,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<PaymentSummaryResponse>;
      if (axiosError.response && axiosError.response.data) {
        if (axiosError.response.data.message === 'subscription_period_too_short') {
          return;
        }
        throw new Error(
          `Error getting payment option summary : ${axiosError.response.data.message}`
        );
      } else {
        throw new Error(`Error getting payment option Summary: ${error.message}`);
      }
    } else {
      throw new Error(`Unexpected error: ${error}`);
    }
  }
}
export async function createSubscription(
  token: string | undefined,
  tenantBuildingId: string | undefined,
  tenantPaymentOptionId: string
) {
  try {
    const response = await axiosInstance.post<ApiResponse>(
      `${SUBSCRIBE}/${tenantBuildingId}`,
      { tenantPaymentOptionId: tenantPaymentOptionId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<ApiResponse>;
      if (axiosError.response && axiosError.response.data) {
        console.error('Axios error response data:', axiosError.response.data);
        throw new Error(`Error creating subscription: ${axiosError.response.data.message}`);
      } else {
        console.error('Axios error message:', error.message);
        throw new Error(`Error creating subscription: ${error.message}`);
      }
    } else {
      console.error('Unexpected error:', error);
      throw new Error(`Error creating subscription: ${error}`);
    }
  }
}

export async function getSubscriptionDetails(
  token: string | undefined,
  tenantBuildingId: string | undefined
) {
  try {
    const response = await axiosInstance.get<SubscriptionDetailResponse>(
      `${SUBSCRIPTION}/${tenantBuildingId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        params: {
          tenantBuildingId,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<SubscriptionDetailResponse>;
      if (axiosError.response && axiosError.response.data) {
        throw new Error(`Error getting subscription summary : ${axiosError.response.data.message}`);
      } else {
        throw new Error(`Error getting subscription Summary: ${error.message}`);
      }
    } else {
      throw new Error(`Unexpected error: ${error}`);
    }
  }
}

export async function cancelSubscription(
  token: string | undefined,
  tenantBuildingId: string | undefined
) {
  try {
    const response = await axiosInstance.patch<ApiResponse>(
      `${SUBSCRIBE}/${tenantBuildingId}/cancel`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          tenantBuildingId,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<ApiResponse>;
      if (axiosError.response && axiosError.response.data) {
        throw new Error(`Error updating subscription: ${axiosError.response.data.message}`);
      } else {
        throw new Error(`Error updating subscription: ${error.message}`);
      }
    } else {
      throw new Error(`Error updating subscription: ${error}`);
    }
  }
}

export async function getSubscriptionSummaryForBuilding(
  token: string | undefined,
  tenantBuildingId: string | undefined,
  rentAmount: number | null,
  dueDate: number | undefined,
  leaseStartDate: string | undefined,
  leaseEndDate: string | undefined
) {
  try {
    const response = await axiosInstance.get<SubscriptionDetailResponse>(
      `${PAY_RENT}/${tenantBuildingId}/summary/subscription/calculate`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        params: {
          tenantBuildingId,
          rentAmount,
          dueDate,
          leaseStartDate,
          leaseEndDate,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<SubscriptionDetailResponse>;
      throw new Error(`${axiosError?.response?.data.message}`);
    } else {
      throw new Error(`Unexpected error: ${error}`);
    }
  }
}
export async function initiateMFA(token: string | undefined) {
  try {
    const response = await axiosInstance.post<InitiateMFAResponse>(
      INITIATE_MFA,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<InitiateMFAResponse>;
      if (axiosError.response && axiosError.response.data) {
        const errorMessage = axiosError.response.data.message;
        switch (errorMessage) {
          case 'mfa_not_required':
            throw new Error('MFA is not required');
          case 'mfa_not_enabled_from_backend':
            throw new Error('MFA is not enabled globally');
          default:
            throw new Error(`Error initiating MFA: ${errorMessage}`);
        }
      } else {
        throw new Error('Server error initiating MFA. Try again later.');
      }
    } else {
      throw new Error('Network error initiating MFA. Check your connection.');
    }
  }
}
export async function verifyMFA(token: string, code: string) {
  try {
    const response = await axiosInstance.post<mfaResponse>(
      VERIFY_MFA,
      {
        verificationCode: code,
        digit: 4,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          Origin: APP_ENV,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<ApiResponse>;
      if (axiosError.response && axiosError.response.data) {
        const { message, errorMessage } = axiosError.response.data;
        console.error('Axios error response data:', axiosError.response.data);
        throw new Error(`Error verifying code: ${message} ${errorMessage}`);
      } else {
        console.error('Axios error message:', error.message);
        throw new Error(`Error verifying code: ${error.message}`);
      }
    } else {
      console.error('Unexpected error:', error);
      throw new Error(`Error verifying code: ${error}`);
    }
  }
}
export async function createLoginToken(token: string) {
  try {
    const response = await axiosInstance.post<mfaResponse>(
      CREATE_JWT,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<ApiResponse>;
      if (axiosError.response && axiosError.response.data) {
        const { message, errorMessage } = axiosError.response.data;
        console.error('Axios error response data:', axiosError.response.data);
        throw new Error(`Error creating JWT token: ${message} ${errorMessage}`);
      } else {
        console.error('Axios error message:', error.message);
        throw new Error(`Error creating JWT token: ${error.message}`);
      }
    } else {
      console.error('Unexpected error:', error);
      throw new Error(`Error creating JWT token: ${error}`);
    }
  }
}
export async function checkMFAStatus(token: string) {
  try {
    const response = await axiosInstance.get<mfaCookieResponse>(GET_MFA_STATUS, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching MFA status');
  }
}
export async function getTermsContent() {
  try {
    const response = await axiosInstance.get<termsResponse>(GET_TERMS_CONTENT);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response && error.response.status === 404) {
        throw new Error('Terms content not found');
      } else {
        console.error('Error fetching terms content:', error.message);
      }
    } else {
      console.error('Unexpected error:', error);
    }
  }
}

export async function setUserConsent(requestBody: termsRequest, token: string) {
  try {
    const response = await axiosInstance.post<ApiResponse>(SET_USER_CONSENT, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error setting user consent:', error);
  }
}
export async function checkUserConsent(token: string) {
  try {
    const response = await axiosInstance.get<checkConsentResponse>(CHECK_CONSENT, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching user consent status');
  }
}

export async function getProcessingFeeDetails(token: string | undefined) {
  try {
    const response = await axiosInstance.get<processingFeeDetailsResponse>(
      GET_PROCESSING_FEE_DETAILS,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<processingFeeDetailsResponse>;
      if (axiosError.response && axiosError.response.data) {
        console.error('Axios error response data:', axiosError.response.data);
        throw new Error(
          `Error getting processing fee details: ${axiosError.response.data.message}`
        );
      } else {
        console.error('Axios error message:', error.message);
        throw new Error(`Error getting processing fee details: ${error.message}`);
      }
    } else {
      console.error('Unexpected error:', error);
      throw new Error(`Error getting processing fee details: ${error}`);
    }
  }
}
