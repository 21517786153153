import styles from './Checkbox.module.css';
import { SVGUtils } from '../SVG/SVG';
interface props {
  checked: boolean;
  onChange: (checked: boolean) => void;
}
export function Checkbox({ checked, onChange }: props) {
  const paths = ['M1 4.54852L3.5 7.04852L9 1.04852'];
  return (
    <div
      className={`${styles.customCheckbox} ${!checked && styles.unchecked}`}
      onClick={() => onChange(!checked)}
    >
      {checked == true && (
        <SVGUtils paths={paths} strokeColor='var(--primary-color)' width='10' height='8' />
      )}
    </div>
  );
}
