import styles from './SuccessMessage.module.css';
import { useModal } from '../../hooks/modalContext';
import { useLogo } from '../../hooks/LogoContext';
import clsx from 'clsx';
import { useTheme } from '../../hooks/ThemeContext';
import darkLogo from '../../../assets/helpers/navbar/tenantpay-white.png';
import { useDomainStatus } from '../../hooks/DomainStatus';

const SuccessMessage = () => {
  const { setModal, modalState } = useModal();
  const { theme } = useTheme();
  const { logoUrl, logoLoading } = useLogo();
  const { domainStatus } = useDomainStatus();
  const isDark = theme === 'dark';
  const { message } = modalState || { message: '' };
  return (
    <main className={clsx(styles.main, isDark && styles.mainDark)}>
      <section className={styles.section}>
        <div className={styles.message}>
          {!logoLoading && (
            <img
              src={theme === 'dark' && domainStatus === 'invalid' ? darkLogo : logoUrl}
              className={styles.logo}
              alt='logo'
            />
          )}
          <h2 className={`${styles.heading} ${isDark && styles.headingDark}`}>{message}</h2>
          <div className={styles.messageContent}>Your subscription is cancelled.</div>
          <button
            className={styles.closeBtn}
            onClick={() => {
              setModal(null);
            }}
          >
            Close
          </button>
        </div>
      </section>
    </main>
  );
};
export default SuccessMessage;
