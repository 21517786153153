import { motion } from 'framer-motion';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './PaymentStatus.module.css';
import errorIcon from '../../assets/pages/nonIndividualError/error.svg';
import errorDark from '../../assets/pages/nonIndividualError/errorDark.svg';
import { fadeInUpAnimation } from '../../helpers/utils/types';
import SVGAsset from '../../helpers/SVG/SVGAsset';
import SVGCheck from '../../helpers/SVG/SVGCheck';
import { useEffect, useState } from 'react';
import { useTheme } from '../../helpers/hooks/ThemeContext';
import bgImage from '../../assets/pages/dashboard/bg4.jpg';
import darkBg from '../../assets/pages/dashboard/bg3black.jpg';

const PaymentStatus = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { theme } = useTheme();
  const { status, errorDescription, tenantBuildingId } = location.state || {};
  const [backgroundUrl, setBackgroundUrl] = useState<string>();
  useEffect(() => {
    const selectedBg = theme === 'dark' ? darkBg : bgImage;
    const img = new Image();
    img.src = selectedBg;
    img.onload = () => {
      setBackgroundUrl(selectedBg);
    };
  }, [theme]);
  let message, title;

  switch (status) {
    case 'pending':
      title = 'Payment Pending!';
      message = 'Your transaction is in progress. We’ll update you once it’s confirmed.';
      break;
    case 'success':
      title = 'Success!';
      message = 'Your payment has been successfully processed! Thank you for your transaction.';
      break;
    case 'failure':
      title = 'Payment Failed!';
      message = errorDescription
        ? `Error: ${errorDescription}`
        : 'Your payment could not be processed at this time. Please ensure all details are correct and try again later.';
      break;
    case 'declined':
      title = 'Payment Declined!';
      message = errorDescription
        ? `Error: ${errorDescription}`
        : 'Your payment could not be processed at this time. Please ensure all details are correct and try again later.';
      break;
    default:
      title = 'Invalid payment status';
      message = 'Invalid payment status.';
  }

  const handleButtonClick = () => {
    if (status === 'success' || status === 'pending') {
      navigate('/dashboard');
    } else {
      navigate(`/payment/direct/${tenantBuildingId}`);
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url(${backgroundUrl})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed',
        minHeight: '100vh',
        color: '#fff',
      }}
    >
      {' '}
      <section className={styles.section}>
        <motion.main
          className={`${styles.main} ${theme === 'dark' && styles.mainDark}`}
          {...fadeInUpAnimation}
        >
          {status === 'pending' ? (
            <SVGAsset isPending={true} className={styles.pendingIcon} />
          ) : status === 'success' ? (
            <SVGCheck isBigCheck={true} />
          ) : (
            <img
              src={theme === 'dark' ? errorDark : errorIcon}
              alt='error'
              className={styles.errorIcon}
            />
          )}
          <div className={styles.headingBox}>
            <div className={`${styles.heading} ${theme === 'dark' && styles.headingDark}`}>
              {title}
            </div>
            <div className={`${styles.subHead} ${theme === 'dark' && styles.subHeadDark}`}>
              {message}
            </div>
          </div>
          <button className={styles.backBtn} onClick={handleButtonClick}>
            {status === 'success' || status === 'pending' ? 'Return to Home' : 'Retry Payment'}
          </button>
        </motion.main>
      </section>
    </div>
  );
};

export { PaymentStatus };
