import React from 'react';
import styles from './CardDesign.module.css';
import visa from './../../../../../assets/pages/payment/visa.png';
import mastercard from './../../../../../assets/pages/payment/mastercard.png';
import amex from './../../../../../assets/pages/payment/amex.png';
import design from './../../../../../assets/helpers/Vectors/carddesign.svg';
import design1 from './../../../../../assets/helpers/Vectors/cardDesign2.svg';
import design2 from './../../../../../assets/helpers/Vectors/cardDesign3.svg';
import { useTheme } from '../../../../../helpers/hooks/ThemeContext';

interface CardDesignProps {
  cardBrand?: string;
  cardEndingDigits?: string;
  cardholderName?: string;
  addCard?: boolean; // Existing prop for adding a new card
  isActive?: boolean; // Ensure isActive is included in the props
  index?: number;
  noCard?: boolean; // New prop to indicate no card
}

const CardDesign: React.FC<CardDesignProps> = ({
  cardBrand,
  cardEndingDigits,
  cardholderName,
  addCard,
  isActive,
  index,
  noCard, // Include noCard in destructuring
}) => {
  const { theme } = useTheme();

  return (
    <div className={styles.cardContainer}>
      {noCard ? ( // Check if noCard is true
        <div
          className={`${styles.creditCard} ${styles.noCardContainer} ${
            theme === 'dark' && styles.creditCardDark && styles.noCardContainerDark
          }`}
        >
          {' '}
          {/* Container for the no card state */}
          <div className={`${styles.addCardText} ${theme === 'dark' && styles.addCardTextDark}`}>
            + Add Payment Method
          </div>
        </div>
      ) : addCard ? ( // Check if addCard is true
        <div>
          <div className={`${styles.addCardText} ${theme === 'dark' && styles.addCardTextDark}`}>
            + Add New Payment Method
          </div>
        </div>
      ) : (
        <div
          className={`${styles.creditCard} 
          ${isActive ? styles.activeCard : ''} 
          ${theme === 'dark' ? styles.creditCardDark : ''} 
          ${isActive && theme === 'dark' ? styles.creditCardActiveDark : ''}`}
        >
          <div>
            {index !== undefined && (
              <img
                src={index % 3 === 0 ? design : index % 3 === 1 ? design1 : design2}
                alt='Card Design'
                className={styles.cardDesignImage}
              />
            )}
          </div>
          {isActive && (
            <div className={styles.iconContainer}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
              >
                <ellipse cx='13' cy='12' rx='6' ry='5' fill='white' />
                <path
                  d='M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z'
                  fill='var(--primary-color)'
                />
              </svg>
            </div>
          )}
          <div className={styles.cardBrand}>
            {cardBrand === 'Visa' && <img src={visa} alt='Visa' className='h-4 ' />}
            {cardBrand === 'Mastercard' && (
              <img src={mastercard} alt='MasterCard' className='h-4 ' />
            )}
            {cardBrand === 'Amex' && <img src={amex} alt='American Express' className='h-7 w-8' />}
          </div>

          <div className={styles.cardFooter}>
            <div className={`${styles.cardNumber} ${theme === 'dark' && styles.cardNumberDark}`}>
              **** **** **** {cardEndingDigits}
            </div>
            <div className={styles.card_holder}>{cardholderName}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CardDesign;
