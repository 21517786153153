import { useState } from 'react';
import styles from './ResetPassword.module.css';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Link, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { verifyForgotPasswordEmail } from '../../auth/_requests';
import passwordOff from '../../assets/pages/login/password-off.svg';
import passwordOn from '../../assets/pages/login/password-on.svg';
import { PasswordInput } from '../../helpers/PasswordInput/PasswordInput';
import { motion } from 'framer-motion';
import { fadeInUpAnimation } from '../../helpers/utils/types';
import { useLogo } from '../../helpers/hooks/LogoContext';
import { useAuth } from '../../auth/Auth';
import toast, { Toaster } from 'react-hot-toast';

const loginSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Minimum 8 characters')
    .max(15, 'Maximum 15 characters')
    .matches(/[a-z]/, 'Must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Must contain at least one uppercase letter')
    .matches(/[0-9]/, 'Must contain at least one number')
    .matches(/[\W_]/, 'Must contain at least one special character')
    .matches(/^\S*$/, 'Password cannot contain spaces')
    .required('Password is required'),

  changePassword: Yup.string()
    .min(3, 'Minimum 8 characters')
    .max(50, 'Maximum 15 characters')
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
});

const initialValues = {
  password: '',
  changePassword: '',
};

export function ResetPassword() {
  const { logout } = useAuth();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { logoUrl, logoLoading } = useLogo();
  const { hash } = useParams();
  const [message, setMessage] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const response = await verifyForgotPasswordEmail(hash, values.password);
        await new Promise((resolve) => setTimeout(resolve, 1000));
        if (response.message === 'successful') {
          toast.remove();
          toast.success('Password changed successfully, you can login with new password now', {
            duration: 8000,
            style: {
              maxWidth: '327px',
            },
          });
        } else {
          setMessage('');
          toast.remove();
          toast.error('An error occurred. Please try again.', {
            duration: 5000,
            style: {
              maxWidth: '327px',
            },
          });
        }
      } catch (error) {
        console.error(error);
        setStatus('The reset link is not correct, please check again');
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  return (
    <section className={styles.section}>
      <Toaster position='top-center' reverseOrder={false} />
      <motion.main className={`${styles.main} `} {...fadeInUpAnimation}>
        {!logoLoading && (
          <img
            src={logoUrl}
            className={styles.logo}
            alt='logo'
          />
        )}
        <div className={styles.headingBox}>
          <div className={`${styles.heading}`}>
            Reset Your Password
          </div>
          <div className={styles.subHead}>Set a new password for your account</div>
        </div>
        <form
          className={styles.form}
          onSubmit={formik.handleSubmit}
          noValidate
          id='kt_login_signin_form'
        >
          <div className={styles.formUpper}>
            <div className={styles.formBoxes}>
              <div className={styles.formBoxesInner}>
                {formik.status && (
                  <div className={styles.statusBox}>
                    <div className={styles.status}>{formik.status}</div>
                  </div>
                )}
                {message && (
                  <div className={styles.messageBox}>
                    <div className={styles.status}>{message}</div>
                  </div>
                )}
                <div
                  className={styles.inputOuter}
                  title='Password must be 8-15 characters long, including at least one uppercase letter, one lowercase letter, one number, one special character, and no spaces.'
                >
                  <div className={styles.label}>
                    New Password<span className={styles.mandatory}>*</span>
                  </div>
                  <div
                    className={clsx(
                      formik.touched.password && formik.errors.password ? styles.isInvalid : '',
                      formik.touched.password && !formik.errors.password ? styles.valid : '',
                      styles.emailInput
                    )}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                      }
                    }}
                  >
                    <PasswordInput
                      value={formik.values.password}
                      onChange={(newPassword: string) => {
                        formik.setFieldValue('password', newPassword);
                      }}
                      onBlur={formik.handleBlur('password')}
                      showPassword={showPassword}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                          formik.handleSubmit();
                        }
                      }}
                      isLogin={true}
                      placeholder='Enter new password'
                    />
                    <button
                      onClick={(event) => {
                        event.preventDefault();
                        setShowPassword(!showPassword);
                      }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                        }
                      }}
                    >
                      {!showPassword && <img src={passwordOff} alt='passwordOff' />}
                      {showPassword && <img src={passwordOn} alt='passwordOn' />}
                    </button>
                  </div>
                  {formik.touched.password && formik.errors.password && (
                    <div className={styles.emailError}>
                      <span>{formik.errors.password}</span>
                    </div>
                  )}
                </div>
                <div className={styles.inputOuter} title='Must match the password exactly'>
                  <div className={styles.label}>
                    Confirm New Password<span className={styles.mandatory}>*</span>
                  </div>
                  <div
                    className={clsx(
                      formik.touched.changePassword && formik.errors.changePassword
                        ? styles.isInvalid
                        : '',
                      formik.touched.changePassword && !formik.errors.changePassword
                        ? styles.valid
                        : '',
                      styles.passwordInput
                    )}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                      }
                    }}
                  >
                    <PasswordInput
                      value={formik.values.changePassword}
                      onChange={(newPassword: string) => {
                        formik.setFieldValue('changePassword', newPassword);
                      }}
                      onBlur={formik.handleBlur('changePassword')}
                      showPassword={showConfirmPassword}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                          formik.handleSubmit();
                        }
                      }}
                      isLogin={true}
                      placeholder='Confirm new password'
                    />
                    <button
                      onClick={(event) => {
                        event.preventDefault();
                        setShowConfirmPassword(!showConfirmPassword);
                      }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                        }
                      }}
                    >
                      {!showConfirmPassword && <img src={passwordOff} alt='passwordOff' />}
                      {showConfirmPassword && <img src={passwordOn} alt='passwordOn' />}
                    </button>
                  </div>
                  {formik.touched.changePassword && formik.errors.changePassword && (
                    <div className={styles.emailError}>
                      <div className='fv-help-block'>
                        <span>{formik.errors.changePassword}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='d-grid'>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className={`${styles.submitBtn} ${
                (formik.isSubmitting || !formik.isValid) && styles.disableBtn
              }`}
            >
              {!loading && <span className='indicator-label'>Change Password</span>}
              {loading && (
                <span className={styles.indicatorProgress} style={{ display: 'block' }}>
                  Please wait...
                  <span className={styles.spinner}></span>
                </span>
              )}
            </button>
          </div>
        </form>
        <div className={styles.signInBox}>
          <Link to={'/login'} className={styles.forgotPassword} onClick={() => logout()}>
            Login
          </Link>
        </div>
      </motion.main>
    </section>
  );
}
