import styles from './PayNumber.module.css';
import { useLogo } from '../../hooks/LogoContext';
import { useModal } from '../../hooks/modalContext';
import clsx from 'clsx';
import { useTheme } from '../../hooks/ThemeContext';
import darkLogo from '../../../assets/helpers/navbar/tenantpay-white.png';
import { useDomainStatus } from '../../hooks/DomainStatus';

interface props {
  isRNTChange?: boolean;
}
const PayNumber = ({ isRNTChange = false }: props) => {
  const { setModal, modalState } = useModal();
  const { domainStatus } = useDomainStatus();
  const { theme } = useTheme();
  const { logoUrl } = useLogo();
  const { isChangeRequest } = modalState || {};
  const isDark = theme === 'dark' && !isChangeRequest;
  return (
    <main className={clsx(styles.main, isDark && styles.mainDark)}>
      <section className={styles.section}>
        <div className={styles.message}>
          <img
            src={
              theme === 'dark' && domainStatus === 'invalid' && !isChangeRequest
                ? darkLogo
                : logoUrl
            }
            alt='logo'
            className={styles.logo}
          />
          <h2 className={`${styles.heading} ${isDark && styles.headingDark}`}>
            {!isRNTChange ? 'TenantPay account number required!' : 'Add new property!'}
          </h2>
          <p className={styles.messageContent}>
            {!isRNTChange
              ? 'To proceed, enter the TenantPay account number provided by your landlord. If you need assistance, let us know!'
              : 'TenantPay account number cannot be changed. To use a different TenantPay account number, add a new property.'}
          </p>
          <button className={styles.closeBtn} onClick={() => setModal(null)}>
            Close
          </button>
        </div>
      </section>
    </main>
  );
};
export default PayNumber;
