import React, { useEffect, useState } from 'react';
import styles from './PaymentSettings.module.css';
import { useTheme } from '../../helpers/hooks/ThemeContext';

import bgImage from '../../assets/pages/dashboard/bg4.jpg';
import darkBg from '../../assets/pages/dashboard/bg3black.jpg';

import CardSettings from './CardSettings/CardSettings';
import Navbar from '../../helpers/Navbar/Navbar';

const PaymentSettings: React.FC = () => {
  const { theme } = useTheme();

  const [backgroundUrl, setBackgroundUrl] = useState<string>();

  useEffect(() => {
    const selectedBg = theme === 'dark' ? darkBg : bgImage;
    const img = new Image();
    img.src = selectedBg;
    img.onload = () => {
      setBackgroundUrl(selectedBg);
    };
  }, [theme]);
  return (
    <>
      <Navbar />
      <div
        style={{
          backgroundImage: `url(${backgroundUrl})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundAttachment: 'fixed',
          minHeight: '100vh',
          color: '#fff',
        }}
      >
        <main className={`${styles.main} ${theme === 'dark' && styles.mainDark}`}>
          <section className={`${styles.section} ${theme === 'dark' && styles.sectionDark}`}>
            <section className={styles.leftSection}>
              <CardSettings />
            </section>
          </section>
        </main>
      </div>
    </>
  );
};

export default PaymentSettings;
