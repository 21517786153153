import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import './styles.css';
import './App.css';
import { Login } from '../src/pages/Login/Login';
import { SignUp } from '../src/pages/SignUp/SignUp';
import { VerifyEmail } from './pages/VerifyEmail/VerifyEmail';
import { VerifyPhone } from './pages/VerifyPhone/VerifyPhone';
import { VerificationSuccess } from './pages/addProperty/VerificationSuccess/VerificationSuccess';
import { NonIndividualError } from './pages/NonIndividualError/NonIndividualError';
import { Profile } from './pages/Profile/Profile';
import { ModalProvider } from './helpers/hooks/modalContext';
import { lazy, Suspense, useEffect, useState } from 'react';
import { Spinner } from './helpers/Spinner/Spinner';
import useVersion from './helpers/hooks/useVersion';
import ModalContainer from './helpers/ModalContainer';
import { ResetPassword } from './pages/ResetPassword/ResetPassword';
import PrivateRoute from './helpers/PrivateRoute/PrivateRoute';
import { DashboardProvider } from './helpers/hooks/DashboardContext';
import { NotFound } from './pages/NotFound/NotFound';
import FetchCompanySettings from './helpers/FetchCompanySettings';
import SelectBuilding from './pages/generateRNT/SelectBuilding/SelectBuilding';
import AddUnitNumber from './pages/generateRNT/AddUnitNumber/AddUnitNumber';
import RNTAdded from './pages/generateRNT/RNTAdded/RNTAdded';
import { PaymentStatus } from './pages/PaymentStatus/PaymentStatus';
import PaymentPage from './pages/paymentPage/PaymentPage';
import { SafeCharge } from './helpers/utils/types';
import RequestChanges from './pages/RequestChanges/RequestChanges';
import { MFAVerify } from './pages/MFA/MFAVerify';
import PaymentSettings from './pages/PaymentSettings/PaymentSettings';
import {
  createLoginToken,
  NUVEI_ENV,
  NUVEI_MERCHANT_ID,
  NUVEI_MERCHANT_SITE_ID,
} from './auth/_requests';
import { useAuth } from './auth/Auth';

declare global {
  interface Window {
    SafeCharge: unknown;
  }
}

const loadScript = (src: string): Promise<void> =>
  new Promise((resolve, reject) => {
    const scriptElem = Object.assign(document.createElement('script'), {
      type: 'text/javascript',
      defer: true,
      src,
      onerror: (e: Event) => {
        reject(e);
      },
    });
    scriptElem.onload = () => {
      resolve();
    };
    document.body.appendChild(scriptElem);
  });

function App() {
  const [safeCharge, setSafeCharge] = useState<unknown>(null);
  const { auth, saveAuth } = useAuth();

  useEffect(() => {
    loadScript('https://cdn.safecharge.com/safecharge_resources/v1/websdk/safecharge.js').then(
      () => {
        const allBrands = ['amex', 'diners', 'discover', 'jcb', 'dankort', 'unionpay'];
        const cardProducts = ['credit', 'debit'];
        const cardTypes = ['consumer', 'corporate'];

        // Create combinations of brands, card products, and card types to block
        const blockCards = allBrands.flatMap((brand) =>
          cardProducts.flatMap((product) => cardTypes.map((type) => [brand, product, type]))
        );
        setSafeCharge(
          (
            window.SafeCharge as {
              (config: {
                env: string;
                merchantId: string;
                merchantSiteId: string;
                blockCards?: string[][];
              }): unknown;
            }
          )({
            env: NUVEI_ENV,
            merchantId: NUVEI_MERCHANT_ID,
            merchantSiteId: NUVEI_MERCHANT_SITE_ID,
            blockCards,
          })
        );
      }
    );
  }, []);
  useVersion('1');
  const TenantType = lazy(() => import('./pages/TenantType/TenantType'));
  const PersonalInfo = lazy(() => import('./pages/addProperty/PersonalInfo/PersonalInfo'));
  const AddProperty = lazy(() => import('./pages/addProperty/AddProperty/AddProperty'));
  // const AddPropertyManager = lazy(
  //   () => import('./pages/addProperty/AddProperty/AddPropertyManager')
  // );
  const Dashboard = lazy(() => import('./pages/dashboard/Dashboard'));
  const IdentityVerification = lazy(
    () => import('./pages/addProperty/IdentityVerification/IdentityVerification')
  );
  const EditProperty = lazy(() => import('./pages/EditProperty/EditProperty'));
  const EditPropertyManager = lazy(() => import('./pages/EditProperty/EditPropertyManager'));
  useEffect(() => {
    const fetchToken = async () => {
      try {
        if (!auth?.data.token) return;
        const response = await createLoginToken(auth?.data.token || '');
        const loginToken = response.data.loginToken;
        const newAuth = {
          message: 'success',
          data: {
            tenantId: auth?.data.tenantId || '',
            token: loginToken,
          },
        };
        saveAuth(newAuth);
      } catch (error) {
        console.error(error);
      }
    };

    // JWT token expiry time is 1hr
    const expiryTime = 3600000;
    const refreshInterval = expiryTime - 120000; // Refresh 2 minutes before expiry
    const intervalId = setInterval(fetchToken, refreshInterval);
    fetchToken();

    return () => clearInterval(intervalId);
  }, [auth?.data.token]);

  return (
    <BrowserRouter>
      <ModalProvider>
        <ModalContainer safeCharge={safeCharge} />
        <FetchCompanySettings />
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/login' element={<Login />} />
          <Route path='/two-factor-auth' element={<MFAVerify />} />
          <Route path='/reset-password/:hash' element={<ResetPassword />} />
          <Route path='/sign-up' element={<SignUp />} />
          <Route
            path='/verify-email'
            element={
              <PrivateRoute requiredVerifications={['isTenant', 'mfa']}>
                <VerifyEmail />
              </PrivateRoute>
            }
          />
          <Route
            path='/verify-phone'
            element={
              <PrivateRoute requiredVerifications={['isTenant', 'isEmailVerified', 'mfa']}>
                <VerifyPhone />
              </PrivateRoute>
            }
          />
          <Route
            path='/tenant-type'
            element={
              <PrivateRoute
                requiredVerifications={['isTenant', 'isEmailVerified', 'isPhoneVerified', 'mfa']}
              >
                <TenantType />
              </PrivateRoute>
            }
          />
          <Route
            path='/personal-info'
            element={
              <PrivateRoute
                requiredVerifications={['isTenant', 'isEmailVerified', 'isPhoneVerified', 'mfa']}
              >
                <PersonalInfo />
              </PrivateRoute>
            }
          />
          <Route
            path='/add-property'
            element={
              <PrivateRoute
                requiredVerifications={['isTenant', 'isEmailVerified', 'isPhoneVerified', 'mfa']}
              >
                <AddProperty />
              </PrivateRoute>
            }
          />
          {/* <Route
            path='/add-property-manager'
            element={
              <PrivateRoute
                requiredVerifications={['isTenant', 'isEmailVerified', 'isPhoneVerified', 'mfa']}
              >
                <AddPropertyManager />
              </PrivateRoute>
            }
          /> */}
          <Route
            path='/generate-rnt/select-building'
            element={
              <PrivateRoute
                requiredVerifications={[
                  'isTenant',
                  'isEmailVerified',
                  'isPhoneVerified',
                  'isDomainValid',

                  'mfa',
                ]}
              >
                <SelectBuilding />
              </PrivateRoute>
            }
          />
          <Route
            path='/generate-rnt/create-unit'
            element={
              <PrivateRoute
                requiredVerifications={[
                  'isTenant',
                  'isEmailVerified',
                  'isPhoneVerified',
                  'isDomainValid',

                  'mfa',
                ]}
              >
                <AddUnitNumber />
              </PrivateRoute>
            }
          />
          <Route
            path='/rnt-added'
            element={
              <PrivateRoute
                requiredVerifications={[
                  'isTenant',
                  'isEmailVerified',
                  'isPhoneVerified',
                  'isDomainValid',

                  'mfa',
                ]}
              >
                <RNTAdded />
              </PrivateRoute>
            }
          />
          <Route
            path='/identity-verification'
            element={
              <PrivateRoute
                requiredVerifications={['isTenant', 'isEmailVerified', 'isPhoneVerified', 'mfa']}
              >
                <IdentityVerification />
              </PrivateRoute>
            }
          />
          <Route
            path='/verification-success'
            element={
              <PrivateRoute
                requiredVerifications={[
                  'isTenant',
                  'isEmailVerified',
                  'isPhoneVerified',
                  'isIdentityVerified',
                  'mfa',
                ]}
              >
                <VerificationSuccess />
              </PrivateRoute>
            }
          />
          <Route
            path='/non-individual-error'
            element={
              <PrivateRoute
                requiredVerifications={['isTenant', 'isEmailVerified', 'isPhoneVerified', 'mfa']}
              >
                <NonIndividualError />
              </PrivateRoute>
            }
          />
          <Route
            path='/dashboard'
            element={
              <PrivateRoute
                requiredVerifications={[
                  'isTenant',
                  'isEmailVerified',
                  'isPhoneVerified',
                  'isIdentityVerified',

                  'mfa',
                ]}
              >
                <DashboardProvider>
                  <Suspense fallback={<Spinner />}>
                    <Dashboard />
                  </Suspense>
                </DashboardProvider>
              </PrivateRoute>
            }
          />
          <Route
            path='/profile'
            element={
              <PrivateRoute
                requiredVerifications={[
                  'isTenant',
                  'isEmailVerified',
                  'isPhoneVerified',
                  'isIdentityVerified',

                  'mfa',
                ]}
              >
                <Profile />
              </PrivateRoute>
            }
          />
          <Route
            path='/payment-success'
            element={<Navigate to={'/payment-status?status=success'} replace />}
          />
          <Route
            path='/payment-failure'
            element={<Navigate to={'/payment-status?status=failure'} replace />}
          />
          <Route
            path='/payment-pending'
            element={<Navigate to={'/payment-status?status=pending'} replace />}
          />
          <Route
            path='/payment-status'
            element={
              <PrivateRoute
                requiredVerifications={[
                  'isTenant',
                  'isEmailVerified',
                  'isPhoneVerified',
                  'isIdentityVerified',

                  'mfa',
                ]}
              >
                <PaymentStatus />
              </PrivateRoute>
            }
          />

          <Route
            path='/payment/:paymentType/:tenantBuildingId'
            element={
              <PrivateRoute
                requiredVerifications={[
                  'isTenant',
                  'isEmailVerified',
                  'isPhoneVerified',
                  'isIdentityVerified',

                  'mfa',
                ]}
              >
                <PaymentPage safeCharge={safeCharge as SafeCharge} />
              </PrivateRoute>
            }
          />

          <Route
            path='/edit-property/:tenantBuildingId'
            element={
              <PrivateRoute
                requiredVerifications={['isTenant', 'isEmailVerified', 'isPhoneVerified', 'mfa']}
              >
                <EditProperty />
              </PrivateRoute>
            }
          />
          <Route
            path='/edit-property-manager/:tenantBuildingId'
            element={
              <PrivateRoute
                requiredVerifications={['isTenant', 'isEmailVerified', 'isPhoneVerified', 'mfa']}
              >
                <EditPropertyManager />
              </PrivateRoute>
            }
          />
          <Route
            path='/request-changes'
            element={
              <PrivateRoute
                requiredVerifications={['isTenant', 'isEmailVerified', 'isPhoneVerified', 'mfa']}
              >
                <RequestChanges />
              </PrivateRoute>
            }
          />
          <Route
            path='/payment/settings'
            element={
              <PrivateRoute
                requiredVerifications={['isTenant', 'isEmailVerified', 'isPhoneVerified', 'mfa']}
              >
                <PaymentSettings />
              </PrivateRoute>
            }
          />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </ModalProvider>
    </BrowserRouter>
  );
}

export default App;
