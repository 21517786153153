import { useNavigate } from 'react-router-dom';
import BackgroundImage from '../../helpers/BackgroundImage/BackgroundImage';
import { fadeInUpAnimation } from '../../helpers/utils/types';
import styles from '../NonIndividualError/NonIndividualError.module.css';
import pending from '../../assets/pages/nonIndividualError/change.png';
import { motion } from 'framer-motion';

const RequestChanges = () => {
  const navigate = useNavigate();
  const handleBackBtn = () => {
    navigate('/personal-info');
  };

  return (
    <section className={styles.section}>
      <BackgroundImage />
      <motion.main className={styles.main} {...fadeInUpAnimation}>
        <img src={pending} alt='Change request icon' className={styles.errorIcon} />
        <div className={styles.headingBox}>
          <h1 className={styles.heading}>Changes Requested!</h1>
          <p className={styles.subHead}>
            Changes have been requested by the admin. Please review and update the necessary
            details.
          </p>
        </div>
        <button className={styles.backBtn} onClick={handleBackBtn}>
          Proceed to Update
        </button>
      </motion.main>
    </section>
  );
};

export default RequestChanges;
