export type ModalItem =
  | 'setup-payment'
  | 'add-property-manager'
  | 'property-successful'
  | 'property-updated'
  | 'add-property'
  | 'rnt-not-found'
  | 'select-building'
  | 'rnt-added'
  | 'create-unit'
  | 'change-rnt-number'
  | 'terms-services'
  | 'privacy-policy'
  | 'payment-schedule'
  | 'reset-password'
  | 'add-card'
  | 'payment-status-pending'
  | 'payment-status-approved'
  | 'payment-status-declined'
  | 'payment-status-error'
  | 'edit-card'
  | 'remove-card'
  | 'card-successful'
  | 'transaction-detail'
  | 'return-to-dashboard'
  | 'successful-subscription'
  | 'subscription-detail'
  | 'success-modal'
  | 'cancel-subscription'
  | 'update-phone'
  | 'verify-phone-code'
  | 'add-payment-method'
  | null;

export type ModalState = {
  payNumber?: string;
  buildingId?: number | null;
  paymentDates?: Record<number, { nextPaymentDate: string; isPaymentDone: boolean }>;
  propertyAmount?: number;
  errorDescription?: string;
  editCard?: PaymentOption;
  transactionDetails?: {
    cardType: string;
    cardCompany: string;
    transactionDate: string;
    amountPaid: string | number;
    rentAmount: string | number;
    processingFee: string | number;
    fixedFee: string | number;
    status: string;
    percentageRate: number | string;
    percentageProcessingFee: string | number;
  };
  isChangeRequest?: boolean;
  message?: string;
  disableRNT?: boolean;
  phoneNumber?: string;
};
export enum NodeEnvEnum {
  Local = 'local',
  Staging = 'staging',
  Production = 'production',
  Test = 'test',
}
export const fadeInUpAnimation = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 1, ease: 'easeInOut' },
};
export const slideInAnimation = (margin = -100) => {
  return {
    initial: { opacity: 0, y: 150 },
    whileInView: { opacity: 1, y: 0 },
    transition: { duration: 0.5 },
    viewport: { margin: `${margin}px`, once: true },
  };
};
export interface AddBuildingRequest {
  buildingDetails: {
    payNumber: string;
    unitNumber: string;
    buildingName: string;
    rentAmount: number;
    dueDate: number;
    leaseStartDate: string;
    leaseEndDate: string;
    leaseDocumentUrl: string;
  };
  addressDetails: {
    streetName: string;
    city: string;
    province: string;
    country: string;
    countryCode: string;
    postalCode: string;
    fullAddress: string;
  };
}
export interface UpdateBuildingRequest {
  buildingDetails: {
    payNumber: string;
    unitNumber: string;
    buildingName: string;
    rentAmount: number;
    dueDate: number;
    leaseStartDate: string;
    leaseEndDate: string;
    leaseDocumentUrl: string;
  };
  addressDetails: {
    streetName: string;
    city: string;
    province: string;
    country: string;
    countryCode: string;
    postalCode: string;
    fullAddress: string;
  };
  managerDetails?: {
    email: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
    isIndividual: boolean;
    securityQuestion: string;
    securityAnswer: string;
  };
}
export interface AddBuildingManager {
  buildingDetails: {
    unitNumber: string;
    buildingName: string;
    rentAmount: number;
    dueDate: number;
    leaseStartDate: string;
    leaseEndDate: string;
    leaseDocumentUrl: string;
  };
  managerDetails: {
    email: string;
    phoneNumber: string;
    phoneNumberExtension: string;
    firstName: string;
    lastName: string;
    isIndividual: boolean;
    securityQuestion: string;
    securityAnswer: string;
  };
  addressDetails: {
    streetName: string;
    city: string;
    province: string;
    country: string;
    countryCode: string;
    postalCode: string;
    fullAddress: string;
  };
}
export interface PersonalInfoRequest {
  userDetails: {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
  };
  tenantDetails: {
    occupation: string;
    industry: string;
  };
  addressDetails: {
    streetName: string;
    city: string;
    unitNumber: string;
    province: string;
    country: string;
    countryCode: string;
    postalCode: string;
    fullAddress: string;
  };
}
export type ApiResponse = {
  message: string;
  errorMessage?: string;
  timestamp: string;
};
export type IDVSessionResponse = {
  message: string;
  data: {
    accessToken: string;
    sessionId: string;
    webEnrolmentUrl: string;
    hasError: boolean;
    externalId: string;
  };
};
export type tenantDetailsResponse = {
  message: string;
  errorMessage?: string;
  timestamp: string;
  data: tenantDetails;
};
export type tenantDetails = {
  user: {
    userId: string;
    firstName: string;
    lastName: string;
    phoneNumberExtension: string;
    phoneNumber: string;
    profilePicturePath: string;
    email: string;
    accessToken: string | undefined;
    isEmailVerified: number;
    isPhoneVerified: number;
    dateOfBirth: string;
    profilePictureUrl: string;
  };
  tenant: {
    tenantId: string;
    isIdentityVerified: number;
    isNewProperty: number;
    isIndividualAccount: number;
    occupation: string;
    industry: string;
    tenantIdv: {
      tenantIdvId: number;
      idvId: string;
      messageFromAdmin: string;
      status: string;
    };
  };
  address: {
    addressId: string;
    unitNumber: string;
    buildingNumber: string;
    streetName: string;
    city: string;
    province: string;
    country: string;
    countryCode: string;
    postalCode: string;
    fullAddress: string;
    provinceCode: string | null;
  };
  tenantBuildings: {
    tenantBuildingId: string;
  }[];
};
export type Country = {
  icon: string;
  name: string;
  countryCode: string;
};
export type buildingDetailsResponse = {
  message: string;
  data: buildingDetails[];
};
export type buildingDetails = {
  building: {
    tenantBuildingId: string;
    userId: string;
    payNumber: string;
    unitNumber: string;
    buildingName: string;
    addressId: string;
    rentAmount: number;
    dueDate: number;
    leaseStartDate: string;
    leaseEndDate: string;
    leaseDocumentUrl: string;
    leaseDocumentDownloadUrl: string;
    paymentDates: string[];
    addedByUserId: string;
    updatedByUserId: string;
    removedByUserId: string | null;
    removed: number;
    timestampAdded: string;
    timestampUpdated: string;
    timestampRemoved: string | null;
    nextBillingDate: string | null;
  };
  address: {
    addressId: string;
    hashId: string | null;
    unitNumber: string | null;
    buildingNumber: string | null;
    streetName: string;
    city: string;
    province: string;
    country: string;
    countryCode: string;
    postalCode: string;
    fullAddress: string;
    updatedByUserId: string;
    addedByUserId: string;
    timestampAdded: string;
    timestampUpdated: string;
  };
  tenantManager?: {
    tenantManagerId: number;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    isIndividual: boolean;
    securityQuestion: string;
    securityAnswer: string;
  };
  tenantSubscriptions?: [];
};
export type IDVresponse = {
  message: string;
  data: {
    sessionId: string;
    status: string | null;
    tenantIdvStatus: string;
  };
};
export type rentResponse = {
  message: string;
  data: {
    totalRentPaid: number | null;
  };
};
export type transactionsResponse = {
  message: string;
  data: [
    {
      tenantTransactionId: string;
      tenantBuildingId: string;
      payNumber: string;
      email: string;
      paymentMethod: string | null;
      paymentType: string;
      amountPaid: number;
      currency: string;
      status: string;
      cardCompany: string | null;
      cardType: string | null;
      responseTimestamp: string;
    }
  ];
  meta: {
    page: number;
    limit: number;
    totalCount: number;
    lastPage: number;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
  };
};
export type particularTransaction = {
  tenantTransactionId: string;
  tenantBuildingId: string;
  payNumber: string;
  email: string;
  paymentMethod: string | null;
  paymentType: string;
  amountPaid: number;
  currency: string;
  status: string;
  cardCompany: string | null;
  cardType: string | null;
  responseTimestamp: string;
  rentAmount?: number;
  processingFee?: number;
  fixedProcessingFee?: number;
  percentageRate?: number;
  percentageProcessingFee?: number;
};
export type singleIndustry = {
  kycBusinessTypeId: string;
  value: string;
};
export type kycIndustryResponse = singleIndustry[];
declare global {
  interface AddressComponent {
    long_name: string;
    short_name: string;
    types: string[];
  }

  interface Place {
    address_components?: AddressComponent[];
    formatted_address: string;
  }

  interface ComponentRestrictions {
    country?: string | string[];
  }

  interface Autocomplete {
    addListener: (event: string, callback: () => void) => void;
    getPlace: () => Place;
  }

  interface GoogleMaps {
    places: {
      Autocomplete: new (
        input: HTMLInputElement,
        options: { types: string[]; componentRestrictions?: ComponentRestrictions }
      ) => Autocomplete;
    };
  }

  interface Window {
    google: {
      maps: GoogleMaps;
    };
  }
}

export interface AddressComponent {
  long_name: string;
  types: string[];
}
export interface ForgotPasswordEmailRequest {
  baseUrlName: string;
  userName: string;
}
export interface VerifyPasswordEmailRequest {
  payload: {
    hash: string;
    password: string;
  };
}
export interface payRentResponse {
  paymentPageUrl: string;
}
export interface subscribeResponse {
  subscriptionPageUrl: string;
}
export interface ErrorType {
  message: string;
}
export interface compnayResponse {
  message: string;
  data: {
    subdomain: {
      subdomainId: number;
    };
    companySetting: {
      companySettingId: number;
      logo: string;
      primaryColor: string;
      secondaryColor: string;
      backgroundImage: string;
      backgroundColor: string;
      logoUrl: string;
      backgroundImageUrl: string;
      theme: 'light' | 'dark';
    };
  };
}
export interface buildingsSubdomainResponse {
  message: string;
  data: [
    {
      buildingId: number;
      name: string;
      buildingNumber: string;
      fullAddress: string;
    }
  ];
}
export interface unitDetailsResponse {
  message: string;
  data: [
    {
      unitId: string;
      unitNumber: string;
      unitName: string | null;
    }
  ];
}
export interface UnitRequest {
  buildingId: string;
  unitNumber: string;
  unitName: string;
}
export interface payNumberRequest {
  buildingId: string;
  unitId: string;
}
export interface payNumberResponse {
  message: string;
  data: { payNumber: string };
}
export interface subdomainResponse {
  message: string;
  data: {
    subdomainId: number;
    isValid: boolean;
    isReserved: boolean;
  };
}
export type PlacementTypes =
  | 'bottomStart'
  | 'bottomEnd'
  | 'topStart'
  | 'topEnd'
  | 'leftStart'
  | 'leftEnd'
  | 'rightStart'
  | 'rightEnd'
  | 'auto'
  | 'autoVerticalStart'
  | 'autoVerticalEnd'
  | 'autoHorizontalStart'
  | 'autoHorizontalEnd';
export interface ResetPasswordRequest {
  passwordData: {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
  };
}
export interface ResetPasswordResponse {
  message: string;
}

export interface OpenOrderResponse {
  internalRequestId: number;
  status: string;
  errCode: number;
  reason: string;
  merchantId: string;
  merchantSiteId: string;
  version: string;
  clientRequestId: string;
  sessionToken: string;
  clientUniqueId: string;
  orderId: number;
  userTokenId: string;
}

export interface PaymentOptionRequest {
  userPaymentOptionId: string;
}

export interface PaymentOption {
  id: string;
  paymentOptionId: string;
  cardEndingDigits: string;
  expiryMonth: number;
  expiryYear: number;
  cardHolderName: string;
  paymentMethod: string;
  cardBrand: string;
  paymentProcessor: string;
  address: string;
  city: string;
  provinceCode: string | null;
  postalCode: string;
  country: string;
  countryCode: string;
  unitNumber: string | null;
  buildingNumber: string | null;
  streetName: string;
  fixedProcessingFee: string;
  percentageRate: string;
}
export interface SafeCharge {
  fields: (options: FieldsOptions) => FieldsInstance;
  createPayment: (
    options: Record<string, unknown>,
    callback: (result: Record<string, unknown>) => void
  ) => void;
  addCardUpo: (
    options: Record<string, unknown>,
    callback: (result: Record<string, unknown>) => void
  ) => void;
}

interface FieldsOptions {
  fonts: { cssUrl: string }[];
  locale: string;
  fontSize: string;
}

interface FieldsInstance {
  create: (field: string, options?: CreateOptions) => Field;
}

interface CreateOptions {
  style?: {
    base?: {
      fontSize?: string;
    };
  };
}

interface Field {
  attach: (selector: string) => void;
}

export interface billingInfo {
  countryCode: string;
  fullAddress: string;
  postalCode: string;
  provinceCode: string;
  city: string;
}

export interface UpdateCardRequestBody {
  billingInfo: billingInfo;
}

export interface PaymentData {
  amountPaid: number;
  responseTimestamp: string;
  rentAmount: string;
  processingFee: string;
  fixedProcessingFee: string;
  paymentDate: string;
  hasPayment: boolean;
}
export interface TransactionsStatusResponse {
  message: string;
  data: PaymentData[];
}

export interface ProcessingFee {
  fixed: string;
  percentage: string;
  total: string;
  percentageRate: string;
}

export interface PaymentSummary {
  amount: string;
  processingFee: ProcessingFee;
  totalAmount: string;
}

export interface Period {
  day: string;
  month: string;
  year: string;
}

export interface SubscriptionPeriod {
  startAfter: Period;
  endAfter: Period;
  recurringPeriod: Period;
  firstPaymentDate: string;
  endDate: string;
}

export interface PaymentSummaryResponse {
  message: string;
  data: PaymentSummary;
  subscriptionPeriod: SubscriptionPeriod;
}

export interface SubscriptionDetail {
  tenantSubscriptionPlanId: string;
  paymentProcessorPlanId: string;
  billingCycle: string;
  recurringPeriod: string;
  recurringAmount: number;
  initialAmount: number;
  firstPaymentDate: string;
  endDate: string;
  rentAmount: string;
  processingFee: string;
  fixedProcessingFee: string;
  percentageRate: string;
  percentageProcessingFee: string;
  status: string;
  paymentProcessorSubscriptionId: string;
  tenantSubscriptionId: string;
  tenantPaymentOptionId: string | null;
  canceledOn: string | null;
  errorMsg: string | null;
  timestampAdded: string;
  paymentMethod: string | null;
  cardCompanyType: string | null;
}

export interface SubscriptionDetailResponse {
  message: string;
  data: SubscriptionDetail;
}
export interface InitiateMFAResponse {
  message: string;
}
export interface mfaResponse {
  message: string;
  data: {
    loginToken: string;
  };
}
export interface mfaCookieResponse {
  message: string;
  data: {
    isMfaValid: true;
    isMfaEnabledForUser: true;
    isMfaEnabledGlobally: true;
    isMfaRequired: false;
  };
}
export interface termsResponse {
  message: string;
  data: {
    termsAndConditionId: number;
    value: string;
  };
}
export interface termsRequest {
  termsAndConditionId: string;
}
export interface checkConsentResponse {
  message: string;
  data: {
    isUserConsentRequired: boolean;
    timestampAdded: string;
  };
}

export interface TenantProcessingFee {
  tenantProcessingFeeId: string;
  paymentMethodId: string;
  cardCompanyTypeId: string;
  fixedProcessingFee: string;
  percentageRate: number;
  paymentMethodName: string;
  cardCompanyTypeValue: string;
}

export interface processingFeeDetailsResponse {
  message: string;
  data: TenantProcessingFee[];
}
