import { useEffect, useState } from 'react';
import bg from '../../assets/pages/dashboard/bg4.jpg';
import darkBg from '../.././assets/pages/dashboard/bg3black.jpg';
import { useTheme } from '../hooks/ThemeContext';
import { useAuth } from '../../auth/Auth';
interface props {
  isLogOut?: boolean;
}
const BackgroundDarkImage = ({ isLogOut = false }: props) => {
  const { theme } = useTheme();
  const { logout } = useAuth();
  const [backgroundUrl, setBackgroundUrl] = useState<string>();
  useEffect(() => {
    const selectedBg = theme === 'dark' ? darkBg : bg;
    const img = new Image();
    img.src = selectedBg;
    img.onload = () => {
      setBackgroundUrl(selectedBg);
    };
  }, [theme]);
  return (
    <>
      {isLogOut && (
        <button
          className={'absolute top-[20px] right-[20px] text-[var(--primary-color)] font-semibold'}
          onClick={() => {
            logout();
          }}
        >
          SignOut?
        </button>
      )}
      <div
        style={{
          backgroundImage: `url(${backgroundUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'top',
          height: '100vh',
          width: '100vw',
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: -1,
        }}
      ></div>
    </>
  );
};

export default BackgroundDarkImage;
