import styles from './PropertyUpdated.module.css';
import { useModal } from '../../hooks/modalContext';
import { useNavigate } from 'react-router-dom';
import { useLogo } from '../../hooks/LogoContext';
import { useAuth } from '../../../auth/Auth';
import clsx from 'clsx';
import { useTheme } from '../../hooks/ThemeContext';
import darkLogo from '../../../assets/helpers/navbar/tenantpay-white.png';
import { useDomainStatus } from '../../hooks/DomainStatus';

const PropertyUpdated = () => {
  const { setModal, modalState } = useModal();
  const { logout } = useAuth();
  const { theme } = useTheme();
  const { isChangeRequest } = modalState || {};
  const { logoUrl, logoLoading } = useLogo();
  const navigate = useNavigate();
  const { domainStatus } = useDomainStatus();
  const isDark = theme === 'dark' && !isChangeRequest;
  return (
    <main className={clsx(styles.main, isDark && styles.mainDark)}>
      <section className={styles.section}>
        <div className={styles.message}>
          {!logoLoading && (
            <img
              src={
                theme === 'dark' && domainStatus === 'invalid' && !isChangeRequest
                  ? darkLogo
                  : logoUrl
              }
              className={styles.logo}
              alt='logo'
            />
          )}
          <h2 className={`${styles.heading} ${isDark && styles.headingDark}`}>
            {isChangeRequest ? 'Application details updated!' : 'Property updated successfully!'}
          </h2>
          <p className={styles.messageContent}>
            {isChangeRequest
              ? 'Your application details have been successfully updated. Click below to log out, and we will notify you once your account has been approved!'
              : 'Your property details have been updated successfully! You can review the changes in your dashboard.'}
          </p>
          <button
            className={styles.closeBtn}
            onClick={() => {
              setModal(null);
              {
                isChangeRequest ? logout() : navigate('/dashboard');
              }
            }}
          >
            {isChangeRequest ? 'LogOut' : 'Close'}
          </button>
        </div>
      </section>
    </main>
  );
};
export default PropertyUpdated;
