import styles from './RNTAdded.module.css';
import { motion } from 'framer-motion';
import { fadeInUpAnimation } from '../../../helpers/utils/types';
import SVGCheck from '../../../helpers/SVG/SVGCheck';
import { useLocation, useNavigate } from 'react-router-dom';
import SVGAsset from '../../../helpers/SVG/SVGAsset';
import { useModal } from '../../../helpers/hooks/modalContext';
import { useTheme } from '../../../helpers/hooks/ThemeContext';
import BackgroundDarkImage from '../../../helpers/BackgroundImage/BackgroundDark';
interface props {
  isModal?: boolean;
}
const RNTAdded = ({ isModal = false }: props) => {
  const navigate = useNavigate();
  const { theme } = useTheme();
  const location = useLocation();
  const { setModal, modalState } = useModal();
  const { payNumber } = location.state || {};
  const isDark = theme === 'dark';
  return (
    <section className={styles.section}>
      {!isModal && <BackgroundDarkImage />}
      <motion.main
        className={`${styles.main} ${isDark && styles.mainDark}`}
        {...(!isModal ? fadeInUpAnimation : {})}
      >
        {isModal && (
          <button
            className={`${styles.cross} ${isDark && styles.crossDark}`}
            onClick={() => setModal(null)}
          >
            ✕
          </button>
        )}
        {payNumber || modalState ? (
          <SVGCheck isBigCheck={true} />
        ) : (
          <SVGAsset isPending={true} className={styles.pendingIcon} />
        )}
        {payNumber || modalState ? (
          <div className={styles.headingBox}>
            <div className={`${styles.heading} ${isDark && styles.headingDark}`}>
              Your TenantPay number has been generated
            </div>
            {!isModal ? (
              <div className={styles.subHead}>{`Your TenantPay number is: ${payNumber}`}</div>
            ) : (
              <div className={styles.subHead}>
                {`Your TenantPay number is: `}{' '}
                <span className={styles.boldText}>{modalState?.payNumber}</span>
              </div>
            )}
          </div>
        ) : (
          <div className={styles.subHead}>{`Please go back and generate payNumber first.`}</div>
        )}
        <div className={styles.buttonWrap}>
          <button
            className={`${styles.backBtn}`}
            onClick={() => {
              if (isModal) {
                setModal('select-building');
              } else navigate('/generate-rnt/select-building');
            }}
          >
            Back
          </button>
          <button
            type='submit'
            onClick={() => {
              if (isModal) {
                setModal('add-property', {
                  payNumber: modalState?.payNumber || '',
                  disableRNT: true,
                });
              } else
                navigate('/add-property', {
                  state: { payNumber: payNumber || '', disableRNT: true },
                });
            }}
            className={`${styles.submitBtn}`}
          >
            Next
          </button>
        </div>
      </motion.main>
    </section>
  );
};
export default RNTAdded;
