import { useEffect } from 'react';
import { getCompanySettings } from '../auth/_requests';
import { convertToRgb } from './utils/utils';
import { useLogo } from './hooks/LogoContext';
import { useDomainStatus } from './hooks/DomainStatus';

const FetchCompanySettings = () => {
  const { setLogoUrl, setLogoLoading } = useLogo();
  const { setDomainStatus } = useDomainStatus();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getCompanySettings();
        if (response && 'data' in response) {
          setDomainStatus('valid');
          const primaryColor = response.data.companySetting.primaryColor;
          const secondaryColor = response.data.companySetting.secondaryColor;
          const rgb = convertToRgb(primaryColor);
          // Set CSS variables
          document.documentElement.style.setProperty('--primary-color', primaryColor);
          document.documentElement.style.setProperty('--secondary-color', secondaryColor);
          document.documentElement.style.setProperty(
            '--primary-color-rgb',
            `${rgb.r}, ${rgb.g}, ${rgb.b}`
          );
          if (response.data.companySetting.logoUrl) {
            setLogoUrl(response.data.companySetting.logoUrl);
          }
          localStorage.setItem('theme', response.data.companySetting.theme);
        } else {
          if (response?.message === 'invalid_domain') {
            setDomainStatus('invalid');
          }
          if (response?.message === 'subdomain_not_found') {
            setDomainStatus('invalid');
          }
          if (response?.message === 'companySetting_not_found') {
            setDomainStatus('valid');
          }
        }
      } catch (error) {
        console.error('Error fetching company settings:', error);
      } finally {
        setLogoLoading(false);
      }
    };

    fetchData();
  }, [setLogoUrl, setLogoLoading]);

  return null;
};

export default FetchCompanySettings;
