import styles from './AddPaymentMethodCard.module.css';
import mastercard from './../../../../../assets/pages/payment/mastercard.png';
import visa from './../../../../../assets/pages/payment/visa.png';
import amex from './../../../../../assets/pages/payment/amex.png';
import { useTheme } from '../../../../../helpers/hooks/ThemeContext';
import { useModal } from '../../../../../helpers/hooks/modalContext';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../../../auth/Auth';
import { getProcessingFeeDetails } from '../../../../../auth/_requests';
import { TenantProcessingFee } from '../../../../../helpers/utils/types';

const cardImages: Record<string, string> = {
  Visa: visa,
  Mastercard: mastercard,
  Amex: amex,
};

const AddPaymentMethodCard = () => {
  const { auth } = useAuth();
  const [processingData, setProcessingData] = useState<TenantProcessingFee[]>([]);
  const [selectedCard, setSelectedCard] = useState<TenantProcessingFee | null>(null);
  const [loading, setLoading] = useState(false);

  const { setModal } = useModal();
  const { theme } = useTheme();

  const handleCardSelection = (cardInfo: TenantProcessingFee | null) => {
    setSelectedCard(cardInfo);
  };

  useEffect(() => {
    const getFeeDetails = async () => {
      try {
        setLoading(true);
        const token = auth?.data.token || '';
        const res = await getProcessingFeeDetails(token);

        const filteredData = res.data.filter(
          (item: TenantProcessingFee) =>
            item.cardCompanyTypeValue !== 'Amex' && item.paymentMethodName !== 'Interac Instant'
        );

        setProcessingData(filteredData);
      } catch {
        console.error('Error getting payment processing fee details');
      } finally {
        setLoading(false);
      }
    };
    getFeeDetails();
  }, []);

  return (
    <section className={styles.section}>
      <main className={`${styles.main} ${theme === 'dark' && styles.mainDark} `}>
        {loading ? (
          <p className={`${styles.loadingText} ${theme === 'dark' && styles.loadingDark}`}>
            Loading...
          </p>
        ) : (
          <>
            <div className={styles.headingBox}>
              <div className={`${styles.heading} ${theme === 'dark' && styles.headingDark}`}>
                Choose a Payment Method
              </div>
              <p className={styles.subHead}>
                Select a payment option to continue with your rent payment
              </p>
            </div>
            <button
              className={`${styles.cross} ${theme === 'dark' && styles.crossDark} `}
              onClick={() => setModal(null)}
            >
              ✕
            </button>

            <div className={styles.detailCards}>
              {processingData.map((item) => (
                <div
                  key={item.tenantProcessingFeeId}
                  className={`${styles.card} ${theme === 'dark' && styles.cardDark} `}
                  onClick={() => handleCardSelection(item)}
                >
                  <div className={styles.radioOuter}>
                    <input
                      type='radio'
                      id='visa'
                      onChange={() => handleCardSelection(item)}
                      checked={
                        selectedCard?.tenantProcessingFeeId === `${item.tenantProcessingFeeId}`
                      }
                    />
                    <img
                      src={cardImages[item.cardCompanyTypeValue]}
                      alt='card-img'
                      className='h-4 '
                    />
                  </div>
                  <div
                    className={`${styles.processingDetail} ${
                      theme === 'dark' && styles.processingDetailDark
                    } `}
                  >
                    <h2
                      className={`${styles.cardHeading} ${
                        theme === 'dark' && styles.cardHeadingDark
                      } `}
                    >
                      {item.cardCompanyTypeValue}
                    </h2>
                    <p className={styles.cardType}>{item.paymentMethodName}</p>
                  </div>
                  <div
                    className={`${styles.processingDetail} ${
                      theme === 'dark' && styles.processingDetailDark
                    }`}
                  >
                    Processing Fee:
                    <p
                      className={`${styles.processingDetailValues} ${
                        theme === 'dark' && styles.processingDetailValuesDark
                      }`}
                    >
                      {Number(item.percentageRate) > 0 && Number(item.fixedProcessingFee) > 0
                        ? `${item.percentageRate}% + $${item.fixedProcessingFee}`
                        : Number(item.percentageRate) > 0
                        ? `${item.percentageRate}%`
                        : Number(item.fixedProcessingFee) > 0
                        ? `$${item.fixedProcessingFee}`
                        : 'N/A'}
                    </p>
                  </div>
                </div>
              ))}

              <div className={styles.item100}>
                <button
                  type='button'
                  onClick={() => setModal('add-card')}
                  className={styles.payButton}
                  disabled={!selectedCard}
                >
                  {selectedCard ? `Continue` : `Select Payment Option`}
                </button>
              </div>
            </div>
          </>
        )}
      </main>
    </section>
  );
};

export default AddPaymentMethodCard;
