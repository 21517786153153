/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import styles from './CardCarousal.module.css';
import { Swiper, SwiperSlide, useSwiperSlide } from 'swiper/react';
import 'swiper/css/pagination';
import { Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { PaymentOption } from '../../../../helpers/utils/types';
import CardDesign from '../../PaymentSection/component/CardDesign/CardDesign';
import { useModal } from '../../../../helpers/hooks/modalContext';

interface CardCarousalProps {
  paymentOptions: PaymentOption[];
  onActiveIndexChange: (swiper: any) => void;
}

const CardCarousal: React.FC<CardCarousalProps> = ({ paymentOptions, onActiveIndexChange }) => {
  const { setModal } = useModal();
  const [numberOfCardsToShow, setNumberOfCardsToShow] = useState<3 | 1 | 2>(3);
  const [direction, setDirection] = useState<'vertical' | 'horizontal'>('vertical'); // Default direction

  const handleResize = () => {
    if (window.innerHeight < 900) {
      // Height is less than 900
      if (window.innerWidth < 700) {
        setNumberOfCardsToShow(1);
        setDirection('horizontal');
      } else if (window.innerWidth < 800) {
        setNumberOfCardsToShow(2);
        setDirection('horizontal');
      } else if (window.innerWidth < 1000) {
        setNumberOfCardsToShow(2);
        setDirection('horizontal');
      } else {
        // For width >= 1000
        setNumberOfCardsToShow(2);
        setDirection('vertical');
      }
    } else {
      // Height is greater than or equal to 900
      if (window.innerWidth < 700) {
        setNumberOfCardsToShow(1);
        setDirection('horizontal');
      } else if (window.innerWidth < 800) {
        setNumberOfCardsToShow(2);
        setDirection('horizontal');
      } else if (window.innerWidth < 1000) {
        setNumberOfCardsToShow(2);
        setDirection('horizontal');
      } else {
        // For width >= 1000
        setNumberOfCardsToShow(3);
        setDirection('vertical');
      }
    }
  };

  useEffect(() => {}, [paymentOptions, setModal]);

  useEffect(() => {
    handleResize(); // Set initial direction
    window.addEventListener('resize', handleResize); // Add resize listener

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      <div className={styles.cardSection}>
        <Swiper
          direction={direction}
          grabCursor={true}
          mousewheel={true}
          keyboard={true}
          slidesPerView={numberOfCardsToShow}
          centerInsufficientSlides={true}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          slideToClickedSlide={true}
          modules={[Pagination, Mousewheel, Keyboard]}
          onActiveIndexChange={onActiveIndexChange}
          style={{
            width: '100%', // Use 100% width of the container
            maxWidth: '280px', // Limit to a maximum width if needed
            maxHeight: '30vh',
            marginRight: 'auto',
            flex: 2,
          }}
        >
          {/* Conditionally render payment buttons inside SwiperSlide */}
          {paymentOptions ? (
            paymentOptions.map((option, index) => (
              <SwiperSlide key={option.paymentOptionId}>
                <SlideWithActiveState>
                  {({ isActive }) => (
                    <CardDesign
                      cardBrand={option.cardBrand}
                      cardEndingDigits={option.cardEndingDigits}
                      cardholderName={option.cardHolderName}
                      addCard={false}
                      isActive={isActive} // Pass the isActive state to CardDesign
                      index={index}
                    />
                  )}
                </SlideWithActiveState>
              </SwiperSlide>
            ))
          ) : (
            <SwiperSlide>
              <div className='payment-methods'>
                <p>Loading...</p>
              </div>
            </SwiperSlide>
          )}
          {(numberOfCardsToShow > 1 || paymentOptions.length > 2) && (
            <div>
              <SwiperSlide>
                <div className='payment-methods'></div>
              </SwiperSlide>
              {numberOfCardsToShow > 2 && (
                <SwiperSlide>
                  <div className='payment-methods'></div>
                </SwiperSlide>
              )}
            </div>
          )}
        </Swiper>
        {paymentOptions.length > 2 && (
          <Swiper
            style={{
              marginTop: '2rem',
              width: '100%', // Use 100% width of the container
              maxWidth: '280px', // Limit to a maximum width if needed
              maxHeight: '30vh',
              flex: 2,
            }}
          >
            <SwiperSlide>
              <button onClick={() => setModal('add-payment-method')}>
                <CardDesign addCard={true} />
              </button>
            </SwiperSlide>
          </Swiper>
        )}
      </div>
    </>
  );
};

interface SlideData {
  isActive: boolean;
  isDuplicate: boolean;
  isPrev: boolean;
  isNext: boolean;
  isVisible: boolean;
  isBlank: boolean;
  index: number;
  isBeginning: boolean;
  isEnd: boolean;
  isFirst: boolean;
  isLast: boolean;
}

const SlideWithActiveState: React.FC<{ children: (data: SlideData) => React.ReactNode }> = ({
  children,
}) => {
  const slideData = useSwiperSlide() as SlideData; // Get slide data and cast to SlideData
  return <>{children(slideData)}</>; // Render children with slide data
};

export default CardCarousal;
