import { useRef, useState, ChangeEvent, useEffect } from 'react';
import styles from './VerifyPhone.module.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/Auth';
import {
  createLoginToken,
  sendVerificationCodePhone,
  updateTenantPhone,
  verifyVerificationCodePhone,
} from '../../auth/_requests';
import { motion } from 'framer-motion';
import { fadeInUpAnimation } from '../../helpers/utils/types';
import clsx from 'clsx';
import { Spinner } from '../../helpers/Spinner/Spinner';
import BackgroundImage from '../../helpers/BackgroundImage/BackgroundImage';
import { useLogo } from '../../helpers/hooks/LogoContext';
import { formatPhoneNumber } from '../../helpers/utils/utils';
import { isPhoneNumber } from 'class-validator';
import { useDomainStatus } from '../../helpers/hooks/DomainStatus';
import { useTheme } from '../../helpers/hooks/ThemeContext';
import BackgroundDarkImage from '../../helpers/BackgroundImage/BackgroundDark';
import { CANANA_COUNTRY_CODE } from '../../constants/canadaCountryCode';

export function VerifyPhone() {
  const { auth, currentUser, saveAuth } = useAuth();
  const { logoUrl, logoLoading } = useLogo();
  const { domainStatus } = useDomainStatus();
  const { theme } = useTheme();
  const inputRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];
  const [inputValues, setInputValues] = useState(['', '', '', '']);
  const [errorMessage, setErrorMessage] = useState('');
  const [validMessage, setValidMessage] = useState('');
  const codeSentRef = useRef(false);
  const [showVerification, setVerification] = useState<boolean>(true);
  const [phone, setPhone] = useState<string>('');
  const [phoneError, setPhoneError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const navigate = useNavigate();
  const isDark = theme === 'dark' && domainStatus === 'valid';

  const handleInputChange = (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.slice(0, 1);
    if (!/^\d$/.test(value)) {
      value = '';
    }
    const newValues = [...inputValues];
    newValues[index] = value;
    setInputValues(newValues);
    if (value.length === 1 && index < 3) {
      inputRefs[index + 1].current?.focus();
    }
  };

  const handleVerify = async () => {
    const code = inputValues.join('');
    const isValidCode = code.length === 4 && /^\d{4}$/.test(code);
    if (isValidCode) {
      setErrorMessage('');
      setLoadingBtn(true);
      try {
        const phoneNumber = currentUser?.user?.phoneNumber || '';
        const token = auth?.data.token || '';
        const response = await verifyVerificationCodePhone(
          `+${CANANA_COUNTRY_CODE}${phoneNumber}`,
          token,
          code
        );
        if (response.message === 'verification_successful') {
          setValidMessage('Your phone has been verified');
          try {
            const response = await createLoginToken(auth?.data.token || '');
            const loginToken = response.data.loginToken;
            const newAuth = {
              message: 'success',
              data: {
                tenantId: auth?.data.tenantId || '',
                token: loginToken,
              },
            };
            saveAuth(newAuth);
          } catch (error) {
            console.error('Error creating login token:', error);
          }
          setIsEmailVerified(true);
          setTimeout(() => {
            navigate('/tenant-type');
          }, 2000);
        } else if (response.message === 'phone_number_already_verified') {
          setValidMessage('Phone number has already been verified');
          setIsEmailVerified(true);
          setTimeout(() => {
            navigate('/tenant-type');
          }, 2000);
        } else {
          setErrorMessage('Verification failed');
        }
      } catch (error) {
        if (error instanceof Error) {
          if (error.message === 'invalid phoneNumber') {
            setErrorMessage('Invalid phone number');
            setIsEmailVerified(true);
          } else {
            setErrorMessage('Error verifying code, Please enter a valid code');
          }
        } else {
          setErrorMessage('An unknown error occurred');
        }
      } finally {
        setLoadingBtn(false);
      }
    } else {
      setErrorMessage('Please enter a valid code');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (['+', '-'].includes(e.key)) {
      e.preventDefault();
    } else if (e.key === 'Enter') {
      handleVerify();
    } else if (e.key === 'Backspace') {
      if (inputValues[index] === '' && index > 0) {
        inputRefs[index - 1].current?.focus();
      }
    }
  };
  const updatePhone = async (phone: string) => {
    try {
      const response = await updateTenantPhone(auth?.data.token, {
        userDetails: { phoneNumber: phone },
      });
      setLoading(true);
      if (response.message === 'updated') {
        await sendVerificationCodePhone(
          `+${CANANA_COUNTRY_CODE}${currentUser?.user?.phoneNumber}`,
          4,
          auth?.data.token
        );
        window.location.reload();
      }
    } catch (error) {
      setPhoneError('Please enter a valid number');
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('Text');
    const digits = pastedData.replace(/\D/g, '').substring(0, 4).split('');

    const newValues = [...inputValues];
    newValues[0] = digits[0] || '';
    newValues[1] = digits[1] || '';
    newValues[2] = digits[2] || '';
    newValues[3] = digits[3] || '';
    setInputValues(newValues);
    inputRefs[digits.length > 0 ? digits.length - 1 : 0].current?.focus();
  };
  useEffect(() => {
    if (currentUser?.user?.phoneNumber && auth?.data.token && !codeSentRef.current) {
      sendVerificationCodePhone(
        `+${CANANA_COUNTRY_CODE}${currentUser.user?.phoneNumber}`,
        4,
        auth.data.token
      );
      codeSentRef.current = true;
    }
  }, [currentUser, auth]);

  const formatPhoneNumberInput = (value: string) => {
    if (!value) return value;

    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;

    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };
  if (loading) {
    return <Spinner />;
  }
  return (
    <section className={styles.section}>
      {isDark ? <BackgroundDarkImage /> : <BackgroundImage />}
      <motion.main className={`${styles.main} ${isDark && styles.mainDark}`} {...fadeInUpAnimation}>
        {!logoLoading && <img src={logoUrl} className={styles.logo} alt='logo' />}
        {showVerification ? (
          <>
            <div className={styles.headingBox}>
              <div className={`${styles.heading} ${isDark && styles.headingDark}`}>
                Verify Phone Number
              </div>
              <div className={styles.subHead}>
                Enter the verification code sent to <br />
                <span className={styles.mail}>
                  {formatPhoneNumber(`${currentUser?.user?.phoneNumber}`, true)}
                </span>{' '}
                to continue.
              </div>
            </div>
            <div className={styles.codeBox}>
              <div className={styles.codeBoxInner}>
                <div className={styles.inputBoxes}>
                  {inputRefs.map((ref, index) => (
                    <input
                      key={index}
                      type='number'
                      className={`${styles.numberBox} ${isDark && styles.darkInput}`}
                      ref={ref}
                      value={inputValues[index]}
                      onChange={handleInputChange(index)}
                      maxLength={1}
                      min={0}
                      max={9}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      onPaste={handlePaste}
                      onWheel={(e) => (e.target as HTMLInputElement).blur()}
                    />
                  ))}
                </div>
                {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
                {validMessage && (
                  <div className={`${styles.validMessage} ${isDark && styles.validDark}`}>
                    {validMessage}
                  </div>
                )}
              </div>
              <button
                className={`${styles.disableBtn} ${
                  !isEmailVerified && inputValues.every((val) => val !== '') ? styles.submitBtn : ''
                } ${loading && styles.disableBtn} ${isEmailVerified && styles.disableBtn}`}
                onClick={handleVerify}
                disabled={isEmailVerified || inputValues.some((val) => val === '') || loading}
              >
                {!loadingBtn && <span className='indicator-label'>Verify</span>}
                {loadingBtn && (
                  <span className={styles.indicatorProgress} style={{ display: 'block' }}>
                    Please wait...
                    <span className={styles.spinner}></span>
                  </span>
                )}
              </button>
            </div>
            <button className={styles.signUpBox} onClick={() => setVerification(false)}>
              Sign up with different phone number
            </button>
          </>
        ) : (
          <>
            <div className={styles.headingBox}>
              <div className={`${styles.heading2} ${isDark && styles.headingDark}`}>
                Update your number
              </div>
              <div className={styles.subHead}>Enter a new number </div>
            </div>
            <div className={styles.phoneOuter}>
              <div
                className={clsx(
                  phoneError && phone !== '' ? styles.isInvalid : '',
                  !phoneError && phone ? styles.valid : '',
                  `${styles.phoneInputBox} ${isDark && styles.darkInput}`
                )}
              >
                <div className={styles.dropdown}>+1</div>
                <input
                  type='text'
                  autoComplete='off'
                  className={`${styles.phoneInput}  ${isDark && styles.darkInput}`}
                  placeholder='(639) 212-4561'
                  maxLength={14}
                  value={formatPhoneNumberInput(phone)}
                  onChange={(e) => {
                    const inputValue = e.target.value.replace(/[^\d]/g, '').slice(0, 10);
                    setPhone(inputValue);
                    if (inputValue.length === 0) {
                      setPhoneError('Phone number is required');
                    } else if (inputValue.length < 10) {
                      setPhoneError('Phone number must be 10 digits');
                    } else {
                      if (!isPhoneNumber(inputValue, 'CA')) {
                        setPhoneError('Please provide a valid Canadian phone number');
                      } else setPhoneError('');
                    }
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                    }
                  }}
                />
              </div>
              {phoneError && <p className={styles.phoneError}>{phoneError}</p>}
            </div>
            {!phoneError ? (
              <button
                className={styles.signUpBox}
                onClick={() => {
                  updatePhone(phone);
                }}
              >
                Verify Now
              </button>
            ) : (
              <button className={styles.signUpBox} onClick={() => setVerification(true)}>
                Back to phone verification
              </button>
            )}
          </>
        )}
      </motion.main>
    </section>
  );
}
