import AddUnitNumber from '../pages/generateRNT/AddUnitNumber/AddUnitNumber';
import RNTAdded from '../pages/generateRNT/RNTAdded/RNTAdded';
import SelectBuilding from '../pages/generateRNT/SelectBuilding/SelectBuilding';
import AddCard from '../pages/paymentPage/PaymentSection/component/AddCard';
import AddPaymentMethodCard from '../pages/paymentPage/PaymentSection/component/AddPaymentMethodCard/AddPaymentMethodCard';
import EditCard from '../pages/paymentPage/PaymentSection/component/EditCard';
import RemoveCard from '../pages/paymentPage/PaymentSection/component/RemoveCard';
import SuccessfulCard from '../pages/paymentPage/PaymentSection/component/SuccessfulCard';
import SuccessfulSubscription from '../pages/paymentPage/PaymentSection/component/SuccessfulSubscription';
import PaymentStatusModal from '../pages/paymentPage/PaymentSection/PaymentStatus/PaymentStatusModal';
import PrivacyPolicy from '../pages/PrivacyPolicy/PrivacyPolicy';
import TermsOfServices from '../pages/TermsOfServices/TermsOfServices';
import Modal from './Modal/Modal';
import AddManagerModal from './Modals/AddProperty/AddManagerModal';
import AddProperty from './Modals/AddProperty/AddPropertyModal';
import CancelSubscription from './Modals/CancelSubscription/CancelSubscription';
import PaymentSchedule from './Modals/PaymentSchedule/PaymentSchedule';
import PayNumber from './Modals/PayNumberModal/PayNumber';
import PropertyUpdated from './Modals/PropertyUpdated/PropertyUpdated';
import { ResetPasswordModal } from './Modals/ResetPasswordModal/ResetPasswordModal';
import ReturnToDashboard from './Modals/ReturnToDashboard/ReturnToDashboard';
import SubscriptionDetail from './Modals/SubscriptionDetail/SubscriptionDetail';
import SuccessfulProperty from './Modals/SuccessfulProperty/SuccessfulProperty';
import SuccessMessage from './Modals/SuccessMessage/SuccessMessage';
import TransactionDetail from './Modals/TransactionDetail/TransactionDetail';
import UpdatePhone from './Modals/UpdatePhone/UpdatePhone';
import VerifyPhoneCode from './Modals/UpdatePhone/VerifyPhoneCode';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ModalContainer = ({ safeCharge }: { safeCharge: any }) => {
  return (
    <>
      <Modal id={'rnt-not-found'}>
        <PayNumber />
      </Modal>
      <Modal id={'property-successful'}>
        <SuccessfulProperty />
      </Modal>
      <Modal id={'property-updated'}>
        <PropertyUpdated />
      </Modal>
      <Modal id={'add-property'} closeOnClickAway={false}>
        <AddProperty />
      </Modal>
      <Modal id={'select-building'}>
        <SelectBuilding isModal={true} />
      </Modal>
      <Modal id={'rnt-added'}>
        <RNTAdded isModal={true} />
      </Modal>
      <Modal id={'create-unit'}>
        <AddUnitNumber isModal={true} />
      </Modal>
      <Modal id={'add-property-manager'} closeOnClickAway={false}>
        <AddManagerModal />
      </Modal>
      <Modal id={'change-rnt-number'}>
        <PayNumber isRNTChange={true} />
      </Modal>
      <Modal id={'terms-services'}>
        <TermsOfServices />
      </Modal>
      <Modal id={'privacy-policy'}>
        <PrivacyPolicy />
      </Modal>
      <Modal id={'payment-schedule'}>
        <PaymentSchedule />
      </Modal>
      <Modal id={'reset-password'}>
        <ResetPasswordModal />
      </Modal>
      <Modal id={'add-card'} closeOnClickAway={false}>
        <AddCard safeCharge={safeCharge} />
      </Modal>
      <Modal id={'add-payment-method'}>
        <AddPaymentMethodCard/>
      </Modal>
      <Modal id={'payment-status-pending'} closeOnClickAway={false}>
        <PaymentStatusModal status={'pending'} message={''} />
      </Modal>
      <Modal id={'payment-status-declined'}>
        <PaymentStatusModal status={'declined'} message={'Payment is Declined'} />
      </Modal>
      <Modal id={'payment-status-approved'}>
        <PaymentStatusModal status={'approved'} message={'Payment is Approved'} />
      </Modal>
      <Modal id={'payment-status-error'}>
        <PaymentStatusModal status={'error'} message={'Error in Payment'} />
      </Modal>
      <Modal id={'edit-card'} closeOnClickAway={false}>
        <EditCard />
      </Modal>
      <Modal id={'remove-card'}>
        <RemoveCard />
      </Modal>
      <Modal id={'card-successful'} closeOnClickAway={false}>
        <SuccessfulCard />
      </Modal>
      <Modal id={'transaction-detail'}>
        <TransactionDetail />
      </Modal>
      <Modal id={'return-to-dashboard'}>
        <ReturnToDashboard />
      </Modal>
      <Modal id={'successful-subscription'}>
        <SuccessfulSubscription />
      </Modal>
      <Modal id={'subscription-detail'}>
        <SubscriptionDetail />
      </Modal>
      <Modal id={'success-modal'}>
        <SuccessMessage />
      </Modal>
      <Modal id={'cancel-subscription'}>
        <CancelSubscription />
      </Modal>
      <Modal id={'update-phone'}>
        <UpdatePhone />
      </Modal>
      <Modal id={'verify-phone-code'} closeOnClickAway={false}>
        <VerifyPhoneCode />
      </Modal>
    </>
  );
};
export default ModalContainer;
