import { useEffect, useState } from 'react';
import styles from './Login.module.css';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import {
  checkMFAStatus,
  createLoginToken,
  getTenantDetails,
  getUserByToken,
  login,
  sendForgotPasswordEmail,
} from '../../auth/_requests';
import { useAuth } from '../../auth/Auth';
import passwordOff from '../../assets/pages/login/password-off.svg';
import passwordOn from '../../assets/pages/login/password-on.svg';
import { Checkbox } from '../../helpers/Checkbox/Checkbox';
import { PasswordInput } from '../../helpers/PasswordInput/PasswordInput';
import { motion } from 'framer-motion';
import { ErrorType, fadeInUpAnimation } from '../../helpers/utils/types';
import axios, { AxiosError } from 'axios';
import { useLogo } from '../../helpers/hooks/LogoContext';
import toast, { Toaster } from 'react-hot-toast';

// Login page
const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Password is required'),
});

const initialValues = {
  email: '',
  password: '',
};

export function Login() {
  const [loading, setLoading] = useState(false);
  const { logoUrl, logoLoading } = useLogo();
  const [showPassword, setShowPassword] = useState(false);
  const [isChecked, setIsChecked] = useState(true);
  const [message, setMessage] = useState('');
  const { saveAuth, setCurrentUser, logout } = useAuth();
  const navigate = useNavigate();
  const handleCheckBtn = () => {
    setIsChecked(!isChecked);
  };
  useEffect(() => {
    const rememberMe = document.cookie.split('; ').find((row) => row.startsWith('rememberMe='));
    if (rememberMe && rememberMe.split('=')[1] === 'true') {
      navigate('/dashboard');
    }
  }, [navigate]);
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setMessage('');
      try {
        const { data: auth } = await login(values.email, values.password);
        saveAuth(auth);
        const { data: user } = await getUserByToken(auth.data.token);
        setCurrentUser(user);
        if (isChecked) {
          const expirationDate = new Date();
          expirationDate.setDate(expirationDate.getDate() + 30);
          document.cookie = `rememberMe=true; expires=${expirationDate.toUTCString()}; path=/`;
        }
        try {
          const tenantResponse = await getTenantDetails(auth?.data.token);
          const response = await checkMFAStatus(auth.data.token);
          if (
            response?.data.isMfaRequired &&
            tenantResponse.data.user.isEmailVerified &&
            tenantResponse.data.user.isPhoneVerified
          ) {
            navigate('/two-factor-auth');
            return;
          } else {
            try {
              const emailVerified = tenantResponse.data.user.isEmailVerified;
              const phoneVerified = tenantResponse.data.user.isPhoneVerified;
              const isIndividualAccount = tenantResponse.data.tenant.isIndividualAccount; 
              const isBuilding = tenantResponse.data.tenantBuildings.length;
              const isAddress = tenantResponse.data.address !== null;
              const isIDV = tenantResponse.data.tenant.tenantIdv !== null;
              const idvStatus = isIDV && tenantResponse.data.tenant.tenantIdv.status;
              switch (true) {
                case !emailVerified:
                  navigate('/verify-email');
                  break;
                case !phoneVerified:
                  navigate('/verify-phone');
                  break;
                case !isIndividualAccount:
                  navigate('/tenant-type');
                  break;
                case !isAddress:
                  navigate('/personal-info');
                  break;
                case !isBuilding:
                  navigate('/add-property');
                  break;
                case !isIDV:
                  navigate('/identity-verification');
                  break;
                case idvStatus === 'pending' || idvStatus === 'declined':
                  navigate('/identity-verification');
                  break;
                case idvStatus === 'approved':
                  navigate('/dashboard');
                  break;
                case idvStatus === 'changeRequest':
                  navigate('/request-changes');
                  break;
                default:
                  console.warn('Unhandled tenant verification status');
              }
            } catch (error) {
              console.error('Error fetching tenant details:', error);
            }
            try {
              const response = await createLoginToken(auth?.data.token || '');
              const loginToken = response.data.loginToken;
              const newAuth = {
                message: 'success',
                data: {
                  tenantId: auth?.data.tenantId || '',
                  token: loginToken,
                },
              };
              saveAuth(newAuth);
            } catch (error) {
              console.error('Error creating login token:', error);
            }
          }
        } catch (error) {
          console.error('Error checking MFA status:', error);
        }
      } catch (error) {
        console.error(error);
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError;
          const errorMessage = (axiosError.response?.data as ErrorType).message;
          if (errorMessage === 'User does not exist') {
            setStatus('No account found. Sign up or verify your details.');
          } else if (errorMessage === 'account_locked') {
            setStatus(
              'You have reached the maximum number of login attempts. Your account is temporarily locked. Please try again in 5 minutes.'
            );
          } else if (errorMessage === 'tenant_not_found') {
            setStatus(
              'This email is already registered with another account. Please use a different email.'
            );
          } else if (errorMessage === 'user_is_inactive') {
            setStatus(
              'Your account has been locked. For assistance, please reach out to TenantPay support at support@tenantpay.com.'
            );
          } else {
            setStatus('The login details are incorrect');
          }
        } else {
          setStatus('The login details are incorrect');
        }

        saveAuth(undefined);
        setSubmitting(false);
        setLoading(false);
      }
    },
  });
  const sendResetLink = async () => {
    if (formik.values.email === '') {
      formik.setErrors({ email: 'Please fill email to send reset link' });
      toast.remove();
      toast.error('Enter your email to reset your password.', {
        duration: 4000,
        style: {
          maxWidth: '327px',
          color: `#020b28`,
        },
      });
      formik.setFieldTouched('email', true);
      return;
    }

    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const response = await sendForgotPasswordEmail('TENANT_URL', formik.values.email);
      if (response.message === 'User does not exist') {
        toast.remove();
        toast.error('Email is not registered with us, please check again.', {
          duration: 10000,
          style: {
            maxWidth: '327px',
            color: `#020b28`,
          },
        });
        setMessage('');
      } else {
        toast.remove();
        toast.success('A reset link has been sent to your email. Please check your inbox.', {
          duration: 8000,
          style: {
            maxWidth: '327px',
            color: `#020b28`,
          },
        });
        formik.setStatus('');
      }
    } catch (error) {
      console.error('Error sending reset link:', error);
      toast.remove();
      toast.error('An error occurred while sending the reset link. Please try again later.', {
        duration: 5000,
        style: {
          maxWidth: '327px',
          color: `#020b28`,
        },
      });
    }
  };

  return (
    <section className={styles.section}>
      <Toaster position='top-center' reverseOrder={false} />
      <motion.main className={`${styles.main} `} {...fadeInUpAnimation}>
        {!logoLoading && <img src={logoUrl} className={styles.logo} alt='logo' />}
        <div className={styles.headingBox}>
          <div className={`${styles.heading} `}>Welcome Back!</div>
          <div className={styles.subHead}>Login to your Account</div>
        </div>
        <form
          className={styles.form}
          onSubmit={formik.handleSubmit}
          noValidate
          id='kt_login_signin_form'
        >
          <div className={styles.formUpper}>
            <div className={styles.formBoxes}>
              <div className={styles.formBoxesInner}>
                {formik.status && (
                  <div className={styles.statusBox}>
                    <div className={styles.status}>{formik.status}</div>
                  </div>
                )}
                {message && (
                  <div className={styles.messageBox}>
                    <div className={styles.message}>{message}</div>
                  </div>
                )}
                <div className={styles.inputOuter} title='Enter your registered email to login'>
                  <div className={styles.label}>
                    Email<span className={styles.mandatory}>*</span>
                  </div>

                  <div
                    className={clsx(
                      formik.touched.email && formik.errors.email ? styles.isInvalid : '',
                      formik.touched.email && !formik.errors.email ? styles.valid : '',
                      styles.emailInput
                    )}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                        formik.handleSubmit();
                      }
                    }}
                  >
                    <input
                      {...formik.getFieldProps('email')}
                      className={`${styles.emailInputBox}`}
                      placeholder='Enter your email'
                      type='email'
                      name='email'
                      autoComplete='off'
                    />
                  </div>
                  {formik.touched.email && formik.errors.email && (
                    <div className={styles.emailError}>
                      <span>{formik.errors.email}</span>
                    </div>
                  )}
                </div>
                <div
                  className={styles.inputOuter}
                  title='Enter your password to access your account'
                >
                  <div className={styles.label}>
                    Password<span className={styles.mandatory}>*</span>
                  </div>
                  <div
                    className={clsx(
                      formik.touched.password && formik.errors.password ? styles.isInvalid : '',
                      formik.touched.password && !formik.errors.password ? styles.valid : '',
                      styles.passwordInput
                    )}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                      }
                    }}
                  >
                    <PasswordInput
                      value={formik.values.password}
                      onChange={(newPassword: string) => {
                        formik.setFieldValue('password', newPassword);
                      }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                          formik.handleSubmit();
                        }
                      }}
                      onBlur={formik.handleBlur('password')}
                      showPassword={showPassword}
                      placeholder='Enter your password'
                    />

                    <button
                      onClick={(event) => {
                        event.preventDefault();
                        setShowPassword(!showPassword);
                      }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                        }
                      }}
                    >
                      {!showPassword && <img src={passwordOff} alt='passwordOff' />}
                      {showPassword && <img src={passwordOn} alt='passwordOn' />}
                    </button>
                  </div>
                  {formik.touched.password && formik.errors.password && (
                    <div className={styles.emailError}>
                      <div className='fv-help-block'>
                        <span>{formik.errors.password}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.forgotBox}>
                <div className={styles.rememberOuter}>
                  <Checkbox
                    checked={isChecked}
                    onChange={() => {
                      handleCheckBtn();
                    }}
                  />
                  <div className={styles.remember}>Remember me</div>
                </div>
                <button
                  onClick={(e) => {
                    sendResetLink();
                    e.preventDefault();
                  }}
                  className={styles.forgotPassword}
                >
                  Forgot password?
                </button>
              </div>
            </div>
          </div>

          <div className='d-grid'>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className={`${styles.submitBtn} ${
                (formik.isSubmitting || !formik.isValid) && styles.disableBtn
              }`}
            >
              {!loading && <span className='indicator-label'>Login</span>}
              {loading && (
                <span className={styles.indicatorProgress} style={{ display: 'block' }}>
                  Please wait...
                  <span className={styles.spinner}></span>
                </span>
              )}
            </button>
          </div>
        </form>
        <div className={styles.signInBox}>
          <div className={styles.remember}>Are you new?</div>
          <button
            onClick={() => {
              logout();
              navigate('/sign-up');
            }}
            className={styles.forgotPassword}
          >
            Sign up
          </button>
        </div>
      </motion.main>
    </section>
  );
}
