import { createContext, useState, useContext, ReactNode, Dispatch, SetStateAction } from 'react';

interface ProfileImageContextType {
  profileImageUrl: string;
  setProfileImageUrl: Dispatch<SetStateAction<string>>;
  photoUpdated: boolean;
  setPhotoUpdated: Dispatch<SetStateAction<boolean>>;
}

const ProfileImageContext = createContext<ProfileImageContextType>({
  profileImageUrl: '',
  setProfileImageUrl: () => {},
  photoUpdated: false,
  setPhotoUpdated: () => {},
});

interface ProfileImageProviderProps {
  children: ReactNode;
}

export const ProfileImageProvider = ({ children }: ProfileImageProviderProps) => {
  const [profileImageUrl, setProfileImageUrl] = useState<string>('');
  const [photoUpdated, setPhotoUpdated] = useState<boolean>(false);

  return (
    <ProfileImageContext.Provider
      value={{ profileImageUrl, setProfileImageUrl, photoUpdated, setPhotoUpdated }}
    >
      {children}
    </ProfileImageContext.Provider>
  );
};

export const useProfileImage = () => useContext(ProfileImageContext);
