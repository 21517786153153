import styles from './NonIndividualError.module.css';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { fadeInUpAnimation } from '../../helpers/utils/types';
import BackgroundImage from '../../helpers/BackgroundImage/BackgroundImage';
import { useTheme } from '../../helpers/hooks/ThemeContext';
import { useDomainStatus } from '../../helpers/hooks/DomainStatus';
import BackgroundDarkImage from '../../helpers/BackgroundImage/BackgroundDark';
import { useLogo } from '../../helpers/hooks/LogoContext';

const NonIndividualError = () => {
  const navigate = useNavigate();
  const { theme } = useTheme();
  const { logoUrl, logoLoading } = useLogo();
  const { domainStatus } = useDomainStatus();
  const isDark = theme === 'dark' && domainStatus === 'valid';
  const handleBackBtn = () => {
    navigate('/tenant-type');
  };
  return (
    <section className={styles.section}>
      {isDark ? <BackgroundDarkImage isLogOut /> : <BackgroundImage isLogOut />}
      <motion.main className={`${styles.main} ${isDark && styles.mainDark}`} {...fadeInUpAnimation}>
        {!logoLoading && <img src={logoUrl} className={styles.logo} alt='logo' />}
        <div className={styles.headingBox}>
          <div className={`${styles.heading} ${isDark && styles.headingDark}`}>
            We're expanding soon!
          </div>
          <div className={styles.subHead}>
            TenantPay currently supports individual tenants. We appreciate your interest and will
            keep you updated as we grow. Stay tuned!
          </div>
        </div>
        <button className={styles.backBtn} onClick={handleBackBtn}>
          Back
        </button>
      </motion.main>
    </section>
  );
};
export { NonIndividualError };
