import { FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import {
  AddPropertyFormValues,
  AddPropertyManager,
  EditCardProps,
  editPropertyProps,
  formProps,
  PersonalInfoFormValues,
  RentalInfoFormProps,
  AddCardFormValues,
} from '../DatePicker/DatePicker';

export const standardizeDate = (date: string) => {
  const dateParts = date.split(' ');
  if (dateParts.length !== 3) {
    throw new Error('Invalid date format. Expected format: DD MMM YYYY');
  }
  const monthMap = {
    jan: 1,
    feb: 2,
    mar: 3,
    apr: 4,
    may: 5,
    jun: 6,
    jul: 7,
    aug: 8,
    sep: 9,
    oct: 10,
    nov: 11,
    dec: 12,
  };

  const monthLower = dateParts[1].toLowerCase();
  if (!Object.prototype.hasOwnProperty.call(monthMap, monthLower)) {
    throw new Error('Invalid month name');
  }
  const month = monthMap[monthLower as keyof typeof monthMap];
  const standardizedDate = new Date(Number(dateParts[2]), month - 1, Number(dateParts[0]));
  if (isNaN(standardizedDate.getTime())) {
    throw new Error('Invalid date');
  }
  const year = standardizedDate.getFullYear();
  const formattedMonth = String(standardizedDate.getMonth() + 1).padStart(2, '0');
  const formattedDay = String(standardizedDate.getDate()).padStart(2, '0');
  return `${year}-${formattedMonth}-${formattedDay}`;
};
// d MM YYYY
export const formatDate = (date: Date | null) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  return `${('0' + date?.getDate()).slice(-2)} ${
    date ? months[date.getMonth()] : 0
  } ${date?.getFullYear()}`;
};
export const formatDateToDisplay = (date: Date): string => {
  const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'short', year: 'numeric' };
  let formattedDate = date.toLocaleDateString('en-GB', options);
  formattedDate = formattedDate.replace('Sept', 'Sep');
  return formattedDate;
};
export const formatDateToDisplayUTC = (dateString: string): string => {
  const date = new Date(dateString);
  const day = String(date.getUTCDate()).padStart(2, '0');
  const monthIndex = date.getUTCMonth();
  const year = date.getUTCFullYear();

  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const month = monthNames[monthIndex];
  return `${day} ${month} ${year}`;
};
export const formatDateToDisplayUTCLong = (dateString: string): string => {
  const date = new Date(dateString);
  const day = String(date.getUTCDate()).padStart(2, '0');
  const monthIndex = date.getUTCMonth();
  const year = date.getUTCFullYear();

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const month = monthNames[monthIndex];
  return `${day} ${month} ${year}`;
};
export const formatDateToDisplayUTCShort = (dateString: string): string => {
  const date = new Date(dateString); // Parse date string as UTC
  const day = String(date.getUTCDate()).padStart(2, '0');
  const monthIndex = date.getUTCMonth();

  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const month = monthNames[monthIndex];

  return `${day} ${month}`; // Day and short month only
};

export const parseDateStringToDate = (dateString: string): Date | null => {
  const [day, month, year] = dateString.split(' ');
  const date = new Date(`${day} ${month} ${year}`);
  return isNaN(date.getTime()) ? null : date;
};
export const formatDateToDisplayLong = (date: Date): string => {
  const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'long', year: 'numeric' };
  return date.toLocaleDateString('en-GB', options);
};
export const formatDateToYYYYMMDD = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};
export const formatDueDate = (day: number, isPaid?: boolean) => {
  const date = new Date();
  if (isPaid) {
    // If the payment is made, set the date to the next month
    date.setMonth(date.getMonth() + 1);
  }
  date.setDate(day);
  const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'long', year: 'numeric' };
  return date.toLocaleDateString('en-GB', options);
};
export const convertToRgb = (color: string) => {
  const tempElement = document.createElement('div');
  tempElement.style.color = color;
  document.body.appendChild(tempElement);
  // Get the computed color in RGB format
  const computedColor = getComputedStyle(tempElement).color;
  document.body.removeChild(tempElement);

  // Extract the RGB values from the computed color
  const rgbValues = computedColor.match(/\d+/g)?.map(Number);

  if (rgbValues && rgbValues.length >= 3) {
    return { r: rgbValues[0], g: rgbValues[1], b: rgbValues[2] };
  }

  // Default to black if the color conversion fails
  return { r: 0, g: 0, b: 0 };
};
interface AddressFetchProps {
  addressInputRef: React.MutableRefObject<HTMLInputElement | null>;
  isSaveManually: (value: boolean) => void;
  setIsAutocompleteOpen?: (value: boolean) => void;
  formik:
    | FormikProps<AddPropertyFormValues>
    | FormikProps<PersonalInfoFormValues>
    | FormikProps<RentalInfoFormProps>
    | FormikProps<formProps>
    | FormikProps<editPropertyProps>
    | FormikProps<AddPropertyManager>
    | FormikProps<EditCardProps>
    | FormikProps<AddCardFormValues>;
}
export const FetchAddress: React.FC<AddressFetchProps> = ({
  addressInputRef,
  formik,
  isSaveManually,
  setIsAutocompleteOpen,
}) => {
  const [changed, setChanged] = useState(false);
  useEffect(() => {
    if (window.google && addressInputRef.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(addressInputRef.current, {
        types: ['address'],
        componentRestrictions: { country: ['CA'] },
      });
      const handlePlaceChanged = () => {
        formik.setFieldTouched('address', false);
        const place = autocomplete.getPlace();
        if (setIsAutocompleteOpen) setIsAutocompleteOpen(false);
        if (place && place.address_components) {
          formik.setFieldValue('address', place.formatted_address);
          let house = '';
          let street = '';
          let city = '';
          let province = '';
          let postal = '';
          place.address_components.forEach((component: AddressComponent) => {
            const types = component.types;
            if (types.includes('street_number')) {
              house = component.long_name;
              formik.setFieldValue('house', house);
              formik.setFieldValue('streetNumber', house);
            }
            if (types.includes('route')) {
              street = component.long_name;
              formik.setFieldValue('street', street);
            }
            if (types.includes('locality')) {
              city = component.long_name;
              formik.setFieldValue('city', city);
            }
            if (types.includes('administrative_area_level_1')) {
              province = component.long_name;
              formik.setFieldValue('province', province);
            }
            if (types.includes('postal_code')) {
              postal = component.long_name;
              formik.setFieldValue('postal', postal);
              formik.setFieldValue('zip', postal);
            }

            if (types.includes('administrative_area_level_1')) {
              postal = component.short_name;
              formik.setFieldValue('provinceCode', postal);
            }
          });
          if (!house || !street || !city || !province || !postal) {
            isSaveManually(true);
          } else {
            isSaveManually(false);
          }
        }
        formik.setFieldTouched('address', true);
        formik.setFieldTouched('house', true);
        formik.setFieldTouched('street', true);
        formik.setFieldTouched('postal', true);
        formik.setFieldTouched('city', true);
        formik.setFieldTouched('province', true);
        formik.setFieldTouched('provinceCode', true);
        setChanged(!changed);
      };
      const handleFocus = () => {
        if (setIsAutocompleteOpen) setIsAutocompleteOpen(true);
      };

      const handleBlur = () => {
        setTimeout(() => {
          if (setIsAutocompleteOpen) setIsAutocompleteOpen(false);
        }, 200);
      };
      autocomplete.addListener('place_changed', handlePlaceChanged);
      addressInputRef.current.addEventListener('focus', handleFocus);
      addressInputRef.current.addEventListener('blur', handleBlur);
      formik.validateForm();
      return () => {
        addressInputRef.current?.removeEventListener('focus', handleFocus);
        addressInputRef.current?.removeEventListener('blur', handleBlur);
      };
    }
  }, [changed]);
  return null;
};
export const formatPhoneNumber = (value: string, isExtension?: boolean) => {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return isExtension
    ? `+1- (${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
    : `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};
export const lightenColor = (hex: string, percent: number) => {
  let hexCode = hex.replace('#', '');
  if (hexCode.length === 3) {
    // Convert shorthand (e.g., #abc) to full form (e.g., #aabbcc)
    hexCode = hexCode
      .split('')
      .map((c) => c + c)
      .join('');
  }
  // Parse the hex code into its RGB components
  let r = parseInt(hexCode.substring(0, 2), 16);
  let g = parseInt(hexCode.substring(2, 4), 16);
  let b = parseInt(hexCode.substring(4, 6), 16);

  // Calculate the new RGB values after lightening
  r = Math.min(255, Math.floor(r + (255 - r) * (percent / 100)));
  g = Math.min(255, Math.floor(g + (255 - g) * (percent / 100)));
  b = Math.min(255, Math.floor(b + (255 - b) * (percent / 100)));

  // Convert the RGB values back to hex format
  const toHex = (value: number) => {
    const hex = value.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};
export const getCssVariable = (variableName: string) => {
  return getComputedStyle(document.documentElement).getPropertyValue(variableName).trim();
};
export const questions = [
  'What is the name of your first pet?',
  'What was the make and model of your first car?',
  'In what city were you born?',
  "What is your mother's maiden name?",
  'What was the name of your first school?',
  'What is the name of the street you grew up on?',
  'What is your favorite childhood memory?',
  "What is your favorite teacher's name?",
  'What was the name of your first employer?',
  'What was the name of your best friend in high school?',
  'What was the first concert you attended?',
  'What is the name of your favorite book?',
  'What is your favorite sports team?',
  'What is the middle name of your oldest sibling?',
  'What was the name of your childhood best friend?',
];

// Set a cookie with an expiry in 1 day
export function setCookie(name: string, value: string, days = 1) {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000); // 1 day
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
}
// Get a cookie value by name
export function getCookie(name: string) {
  const nameEQ = `${name}=`;
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    while (cookie.charAt(0) === ' ') cookie = cookie.substring(1);
    if (cookie.indexOf(nameEQ) === 0) return cookie.substring(nameEQ.length);
  }
  return null;
}
