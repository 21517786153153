/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useRef, memo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styles from './AddCard.module.css';
import { useModal } from '../../../../helpers/hooks/modalContext';
import { createPaymentOption, openOrder } from '../../../../auth/_requests';
import { useAuth } from '../../../../auth/Auth';
import { SafeCharge } from '../../../../helpers/utils/types';
import toast, { Toaster } from 'react-hot-toast';
import { FetchAddress } from '../../../../helpers/utils/utils';
import { useTheme } from '../../../../helpers/hooks/ThemeContext';
import { EditIcon } from 'lucide-react';
interface SafeChargeProps {
  safeCharge: SafeCharge;
}


const errorMessages: Record<string, string> = {
  incomplete_card_number: 'Card number is incomplete',
  incorrect_card_number: 'The card number is incorrect',
  incomplete_cvc: 'CVC is incomplete',
  invalid_cvc: 'The CVC is invalid',
  incomplete_date: 'Expiration date is incomplete',
  date_in_past: 'Expiration date is in the past',
  invalid_expiration_year: 'Expiration year is invalid',
};

const AddCard: React.FC<SafeChargeProps> = memo(({ safeCharge }) => {
  const { setModal } = useModal();
  const { auth } = useAuth();
  const { theme } = useTheme();
  const [loading, setLoading] = useState(false);
  const [sessionToken, setSessionToken] = useState<string>('');
  const [cardErrors, setCardErrors] = useState<any>({});
  const [isSaveManually, setIsManually] = useState(false);
  const cardNumberRef = useRef<any>(null);
  const cardExpiryRef = useRef<any>(null);
  const cardCvcRef = useRef<any>(null);
  const addressInputRef = useRef(null);
  const [isAutoCompleteOpen, setIsAutocompleteOpen] = useState(false);

  useEffect(() => {
    const fetchSessionToken = async () => {
      try {
        const response = await openOrder(auth?.data.token);
        setSessionToken(response.sessionToken);
      } catch (error) {
        console.error('Error fetching session token:', error);
      }
    };
    fetchSessionToken();
  }, []);

  useEffect(() => {
    const handleFieldChange = (event: any) => {
      const { field, error } = event;

      if (error) {
        const friendlyMessage = errorMessages[error.id] || 'An error occurred';
        setCardErrors((prevErrors: any) => ({
          ...prevErrors,
          [field]: friendlyMessage,
        }));
      } else {
        setCardErrors((prevErrors: any) => ({
          ...prevErrors,
          [field]: '',
        }));
      }
    };

    if (safeCharge) {
      const safeChargeFields = safeCharge.fields({
        fonts: [{ cssUrl: '' }],
        locale: 'en',
        fontSize: '16px',
      });

      if (!cardNumberRef.current) {
        cardNumberRef.current = safeChargeFields.create('ccNumber', {});
        cardNumberRef.current.attach('#card-number');
        cardNumberRef.current.on('change', handleFieldChange);
      }
      if (!cardExpiryRef.current) {
        cardExpiryRef.current = safeChargeFields.create('ccExpiration', {
          style: { base: { fontSize: '16px' } },
        });
        cardExpiryRef.current.attach('#card-expiry');
        cardExpiryRef.current.on('change', handleFieldChange);
      }
      if (!cardCvcRef.current) {
        cardCvcRef.current = safeChargeFields.create('ccCvc', {
          style: { base: { fontSize: '16px' } },
        });
        cardCvcRef.current.attach('#card-cvc');
        cardCvcRef.current.on('change', handleFieldChange);
      }
    }
  }, [safeCharge]);

  const formik = useFormik({
    initialValues: {
      cardHolderName: '',
      email: '',
      address: '',
      unitNumber: '',
      street: '',
      house: '',
      postal: '',
      city: '',
      province: '',
      provinceCode: '',
      country: 'CA',
    },
    validationSchema: Yup.object({
      cardHolderName: Yup.string().required('Cardholder name is required'),
      email: Yup.string()
        .email('Invalid email format')
        .min(3, 'Minimum 3 characters')
        .max(50, 'Maximum 50 characters')
        .required('Email is required'),
      address: Yup.string().required('Address is required'),
      postal: Yup.string().required('Postal Code is required'),
      house: Yup.string().required('Street Number is required'),
      street: Yup.string().required('Street is required'),
      city: Yup.string().required('City is required'),
      province: Yup.string().required('Province is required'),
      provinceCode: Yup.string().required('Province Code is required'),
      country: Yup.string().required('Country is required'),
    }),
    onSubmit: async (values) => {
      const paymentOptionId = localStorage.getItem('selectedPaymentOption');
      if (paymentOptionId) {
        localStorage.removeItem('selectedPaymentOption');
      }
      setLoading(true);
      if (safeCharge && sessionToken) {
        try {
          safeCharge.createPayment(
            {
              sessionToken,
              cardHolderName: values.cardHolderName,
              paymentOption: cardNumberRef.current,
              billingAddress: {
                email: values.email,
                country: values.country,
              },
            },
            async (result) => {
              setLoading(false);
              if (result.transactionStatus === 'APPROVED') {
                toast.success('Card authorized successfully!', {
                  duration: 8000,
                  style: {
                    maxWidth: '327px',
                    background: `${theme === 'dark' && '#000'}`,
                    color: `${theme === 'dark' ? '#f5f5f5' : `#020b28`}`,
                    border: `${theme === 'dark' && '1px solid #f5f5f5'}`,
                  },
                });
                safeCharge.addCardUpo(
                  {
                    sessionToken,
                    useFields: true,
                    cardHolderName: values.cardHolderName,
                    billingAddress: { ...values },
                  },
                  async (result) => {
                    if (result.result === 'ADDED') {
                      try {
                        await createPaymentOption(auth?.data.token, {
                          userPaymentOptionId: String(result.userPaymentOptionId),
                        });
                        toast.success('Card added successfully!', {
                          duration: 8000,
                          style: {
                            maxWidth: '327px',
                            background: `${theme === 'dark' && '#000'}`,
                            color: `${theme === 'dark' ? '#f5f5f5' : `#020b28`}`,
                            border: `${theme === 'dark' && '1px solid #f5f5f5'}`,
                          },
                        });
                        setModal('card-successful');
                      } catch (error: any) {
                        let errorMessage = 'Failed to create payment option.';
                        if (error?.message?.includes('user_payment_option_exists')) {
                          errorMessage = 'User payment option already exists';
                        }

                        toast.error(errorMessage, {
                          duration: 8000,
                          style: {
                            maxWidth: '327px',
                            background: `${theme === 'dark' && '#000'}`,
                            color: `${theme === 'dark' ? '#f5f5f5' : `#020b28`}`,
                            border: `${theme === 'dark' && '1px solid #f5f5f5'}`,
                          },
                        });
                        //window.location.reload();
                        setModal(null);
                      }
                    } else {
                      toast.error('Failed to add card: ' + result.errorDescription, {
                        duration: 8000,
                        style: {
                          maxWidth: '327px',
                          background: `${theme === 'dark' && '#000'}`,
                          color: `${theme === 'dark' ? '#f5f5f5' : `#020b28`}`,
                          border: `${theme === 'dark' && '1px solid #f5f5f5'}`,
                        },
                      });
                      // window.location.reload();
                      setModal(null);
                    }
                  }
                );
              } else {
                toast.remove();
                toast.error('Failed to add card: ' + result.errorDescription, {
                  duration: 8000,
                  style: {
                    maxWidth: '327px',
                    background: `${theme === 'dark' && '#000'}`,
                    color: `${theme === 'dark' ? '#f5f5f5' : `#020b28`}`,
                    border: `${theme === 'dark' && '1px solid #f5f5f5'}`,
                  },
                });
                //  window.location.reload();
                setModal(null);
              }
            }
          );
        } catch (error) {
          setLoading(false);
          console.error('Error during createPayment or addCardUpo:', error);
        }
      } else {
        setLoading(false);
        console.error('SafeCharge or session token not initialized');
      }
    },
  });

  return (
    <>
      <Toaster position='top-center' reverseOrder={false} />
      <section className={styles.section}>
        <main
          className={`${styles.main} ${theme === 'dark' && styles.mainDark} ${isAutoCompleteOpen && formik.values.address !== '' && styles.mainHidden
            }`}
        >
          <FetchAddress
            addressInputRef={addressInputRef}
            formik={formik}
            isSaveManually={setIsManually}
            setIsAutocompleteOpen={setIsAutocompleteOpen}
          />
          <div className={styles.headingBox}>
            <div className={`${styles.heading} ${theme === 'dark' && styles.headingDark}`}>
              Add Card
            </div>
          </div>
          <button
            className={`${styles.cross} ${theme === 'dark' && styles.crossDark} `}
            onClick={() => setModal(null)}
          >
            ✕
          </button>

          <form onSubmit={formik.handleSubmit}>
            {/* Cardholder Name */}
            <div className={styles.formGridSingle}>
              <div
                className={`${styles.item} ${theme === 'dark' && styles.itemDark}`}
                title='Enter the full name of the cardholder'
              >
                <label htmlFor='cardHolderName'>
                  Cardholder Name <span className={styles.required}>*</span>
                </label>
                <input
                  id='cardHolderName'
                  type='text'
                  placeholder='Cardholder Name'
                  {...formik.getFieldProps('cardHolderName')}
                />
                {formik.touched.cardHolderName && formik.errors.cardHolderName && (
                  <div className={styles.error}>{formik.errors.cardHolderName}</div>
                )}
              </div>
            </div>

            <div className={styles.formGrid}>
              {/* Card Number */}
              <div className={`${styles.item} ${theme === 'dark' && styles.itemDark}`}>
                <label htmlFor='card-number'>
                  Card Number <span className={styles.required}>*</span>
                </label>
                <div id='card-number' className='input' />
                {cardErrors.ccNumber && <div className={styles.error}>{cardErrors.ccNumber}</div>}
              </div>

              {/* Expiration Date */}
              <div className={`${styles.item} ${theme === 'dark' && styles.itemDark}`}>
                <label htmlFor='card-expiry'>
                  Expiration Date <span className={styles.required}>*</span>
                </label>
                <div id='card-expiry' className='input' />
                {cardErrors.ccExpiration && (
                  <div className={styles.error}>{cardErrors.ccExpiration}</div>
                )}
              </div>

              {/* CVV */}
              <div className={`${styles.item} ${theme === 'dark' && styles.itemDark}`}>
                <label htmlFor='card-cvc'>
                  CVV <span className={styles.required}>*</span>
                </label>
                <div id='card-cvc' className='input' />
                {cardErrors.ccCvc && <div className={styles.error}>{cardErrors.ccCvc}</div>}
              </div>

              {/* Email */}

              <div
                className={`${styles.item} ${theme === 'dark' && styles.itemDark}`}
                title='Enter your billing email address'
              >
                <label htmlFor='email'>
                  Email <span className={styles.required}>*</span>
                </label>
                <input
                  id='email'
                  type='email'
                  placeholder='Email'
                  {...formik.getFieldProps('email')}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className={styles.error}>{formik.errors.email}</div>
                )}
              </div>
            </div>

            {/* Address */}
            <div className={styles.formGridSingle}>
              <div
                className={`${styles.item} ${theme === 'dark' && styles.itemDark}`}
                title='Enter the billing address associated with your card'
              >
                <div className={styles.addressOuter}>
                  <label htmlFor='address'>
                    Address <span className={styles.required}>*</span>
                  </label>
                  <button
                    type='button'
                    onClick={() => setIsManually(!isSaveManually)}
                    className={styles.buttonLabel}
                  >
                    <div className={styles.label}>{isSaveManually ? 'Save' : 'Enter manually'}</div>
                    {!isSaveManually && <EditIcon className={styles.editIcon} stroke='#868c99' />}
                  </button>
                </div>
                <input
                  id='address'
                  type='text'
                  placeholder='831 Galts Ave, Montreal, Quebec H2Y 3X4'
                  {...formik.getFieldProps('address')}
                  ref={addressInputRef}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                    }
                  }}
                />
                {formik.touched.address && formik.errors.address && (
                  <div className={styles.error}>{formik.errors.address}</div>
                )}
              </div>
            </div>
            {isSaveManually && (
              <>
                <div className={styles.formGrid}>
                  <div
                    className={`${styles.item} ${theme === 'dark' && styles.itemDark}`}
                    title='Enter the unit number (if applicable)'
                  >
                    <label htmlFor='unitNumber'>Unit Number</label>
                    <input
                      id='unitNumber'
                      type='text'
                      placeholder='12'
                      {...formik.getFieldProps('unitNumber')}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                        }
                      }}
                    />
                    {formik.touched.unitNumber && formik.errors.unitNumber && (
                      <div className={styles.error}>{formik.errors.unitNumber}</div>
                    )}
                  </div>
                  {/* Street Number */}
                  <div
                    className={`${styles.item} ${theme === 'dark' && styles.itemDark}`}
                    title='Enter the street number or building number'
                  >
                    <label htmlFor='house'>
                      Street Number <span className={styles.required}>*</span>
                    </label>
                    <input
                      id='house'
                      type='text'
                      placeholder='2217 '
                      {...formik.getFieldProps('house')}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                        }
                      }}
                    />
                    {formik.touched.house && formik.errors.house && (
                      <div className={styles.error}>{formik.errors.house}</div>
                    )}
                  </div>
                  {/* Street */}
                  <div
                    className={`${styles.item} ${theme === 'dark' && styles.itemDark}`}
                    title='Enter the street name'
                  >
                    <label htmlFor='street'>
                      Street <span className={styles.required}>*</span>
                    </label>
                    <input
                      id='street'
                      type='text'
                      placeholder='2217 Carlson Road'
                      {...formik.getFieldProps('street')}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                        }
                      }}
                    />
                    {formik.touched.street && formik.errors.street && (
                      <div className={styles.error}>{formik.errors.street}</div>
                    )}
                  </div>

                  {/* City */}
                  <div
                    className={`${styles.item} ${theme === 'dark' && styles.itemDark}`}
                    title='Enter the city name'
                  >
                    <label htmlFor='city'>
                      City <span className={styles.required}>*</span>
                    </label>
                    <input
                      id='city'
                      type='text'
                      placeholder='Montréal'
                      {...formik.getFieldProps('city')}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                        }
                      }}
                    />
                    {formik.touched.city && formik.errors.city && (
                      <div className={styles.error}>{formik.errors.city}</div>
                    )}
                  </div>

                  {/* Province */}
                  <div
                    className={`${styles.item} ${theme === 'dark' && styles.itemDark}`}
                    title='Enter the province name'
                  >
                    <label htmlFor='province'>
                      Province <span className={styles.required}>*</span>
                    </label>
                    <input
                      id='province'
                      type='text'
                      placeholder='Québec'
                      {...formik.getFieldProps('province')}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                        }
                      }}
                    />
                    {formik.touched.province && formik.errors.province && (
                      <div className={styles.error}>{formik.errors.province}</div>
                    )}
                  </div>

                  {/* Postal Code */}
                  <div
                    className={`${styles.item} ${theme === 'dark' && styles.itemDark}`}
                    title='Enter the postal code'
                  >
                    <label htmlFor='postal'>
                      Postal Code <span className={styles.required}>*</span>
                    </label>
                    <input
                      id='postal'
                      type='text'
                      placeholder='H2Y 3X4'
                      {...formik.getFieldProps('postal')}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                        }
                      }}
                    />
                    {formik.touched.postal && formik.errors.postal && (
                      <div className={styles.error}>{formik.errors.postal}</div>
                    )}
                  </div>

                  {/* Province Code */}
                  <div
                    className={`${styles.item} ${theme === 'dark' && styles.itemDark}`}
                    title='Enter the province code'
                  >
                    <label htmlFor='provinceCode'>
                      Province Code <span className={styles.required}>*</span>
                    </label>
                    <input
                      id='provinceCode'
                      type='text'
                      placeholder='AB'
                      {...formik.getFieldProps('provinceCode')}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                        }
                      }}
                    />
                    {formik.touched.provinceCode && formik.errors.provinceCode && (
                      <div className={styles.error}>{formik.errors.provinceCode}</div>
                    )}
                  </div>

                  {/* Country */}
                  <div
                    className={`${styles.item} ${theme === 'dark' && styles.itemDark}`}
                    title='Country is set to Canada'
                  >
                    <label htmlFor='country'>
                      Country <span className={styles.required}>*</span>
                    </label>
                    <input
                      id='country'
                      type='text'
                      placeholder='Country'
                      {...formik.getFieldProps('country')}
                      disabled
                    />
                    {formik.touched.country && formik.errors.country && (
                      <div className={styles.error}>{formik.errors.country}</div>
                    )}
                  </div>
                </div>
              </>
            )}
            <div className={styles.item100}>
              <button type='submit' className={styles.payButton} disabled={loading}>
                {loading ? 'Saving...' : 'Add Card'}
              </button>
            </div>
          </form>
        </main>
      </section>
    </>
  );
});

export default AddCard;
