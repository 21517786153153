import React, { createContext, useContext, useState, type ReactNode, useEffect } from 'react';
import { type ModalItem, type ModalState } from '../utils/types'; // Import ModalState

interface ModalContextProps {
  modal: ModalItem;
  setModal: (modal: ModalItem, state?: ModalState) => void;
  modalState?: ModalState | null;
}

const ModalContext = createContext<ModalContextProps | undefined>(undefined);

export const ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [modal, setModalState] = useState<ModalItem>(null);
  const [modalState, setModalStateData] = useState<ModalState | null>(null); // Track modalState

  const setModal = (modal: ModalItem, state?: ModalState) => {
    setModalState(modal);
    if (state) {
      setModalStateData(state); // Store state when passed
    }
  };

  useEffect(() => {
    if (modal != null) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [modal]);

  return (
    <ModalContext.Provider value={{ modal, setModal, modalState }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (context == null) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
