import { useEffect, useState } from 'react';
import styles from './SelectBuilding.module.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { fadeInUpAnimation } from '../../../helpers/utils/types';
import line from '../../../assets/pages/addProperty/personal-info/line.svg';
import { Spinner } from '../../../helpers/Spinner/Spinner';
import SVGCheck from '../../../helpers/SVG/SVGCheck';

import {
  generatePayNumber,
  getBuildingsBySubdomain,
  getUnitsBySubdomain,
} from '../../../auth/_requests';
import { useAuth } from '../../../auth/Auth';
import { useModal } from '../../../helpers/hooks/modalContext';
import { useTheme } from '../../../helpers/hooks/ThemeContext';
import ClickAwayListener from 'react-click-away-listener';
import BackgroundDarkImage from '../../../helpers/BackgroundImage/BackgroundDark';

const initialValues = {
  buildingName: '',
  unitNumber: '',
};
const registrationSchema = Yup.object().shape({
  buildingName: Yup.string()
    .min(2, 'Minimum 2 characters')
    .max(100, 'Maximum 100 chracters')
    .required('Building name is required'),
  unitNumber: Yup.string()
    .min(1, 'Minimum 1 character')
    .required('Unit Number is required')
    .max(50, 'Maximum 50 characters'),
});
interface props {
  isModal?: boolean;
}
const SelectBuilding = ({ isModal = false }: props) => {
  const { auth } = useAuth();
  const [loading, setLoading] = useState(false);
  const { theme } = useTheme();
  const { setModal } = useModal();
  const [, setBuildingDropdown] = useState(false);
  const [selectedBuilding, setSelectedBuilding] = useState('');
  const [selectedBuildingId, setSelectedBuildingId] = useState<number | null>(null);
  const [unitDropdown, setUnitDropdown] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState('');
  const [unitId, setUnitId] = useState('');
  const [payNumber, setPayNumber] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [unitTerm, setUnitTerm] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [buildings, setBuildings] = useState<
    { name: string; buildingNumber: string; address: string; buildingId: number }[]
  >([]);
  const [units, setUnits] = useState<
    { unitNumber: string; unitName: string | null; unitId: string }[]
  >([]);
  const navigate = useNavigate();
  const isDark = theme === 'dark';
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      values.unitNumber, values.buildingName;
      const requestBody = {
        buildingId: String(selectedBuildingId),
        unitId: unitId,
      };
      setLoading(true);
      try {
        const response = await generatePayNumber(auth?.data.token, requestBody);
        if (response.message === 'pay_number_already_exists') {
          setStatus(
            `TenantPay Number already exists for your building: ${response.data.payNumber}`
          );
        } else {
          if (isModal) {
            setModal('rnt-added', { payNumber: response.data.payNumber });
          } else {
            navigate('/rnt-added', { state: { payNumber: response.data.payNumber } });
          }
        }
        setPayNumber(response.data.payNumber);
        setLoading(false);
      } catch (error) {
        setStatus('Please fill all required fields');
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    const fetchBuildingsOptions = async () => {
      if (auth?.data.token) {
        const buildingsResponse = await getBuildingsBySubdomain(auth?.data.token, 50, searchTerm);
        const buildingValues = buildingsResponse.data.map((building) => ({
          name: building.name,
          buildingNumber: building.buildingNumber,
          address: building.fullAddress,
          buildingId: building.buildingId,
        }));
        setBuildings(buildingValues);
      }
    };

    fetchBuildingsOptions();
  }, [searchTerm, auth?.data.token]);

  // Fetch units when a building is selected
  useEffect(() => {
    const fetchUnits = async () => {
      if (auth?.data.token && selectedBuilding) {
        const unitsResponse = await getUnitsBySubdomain(
          auth.data.token,
          Number(selectedBuildingId),
          50,
          unitTerm
        );
        const unitValues = unitsResponse.data.map((unit) => ({
          unitNumber: unit.unitNumber,
          unitName: unit.unitName,
          unitId: unit.unitId,
        }));
        setUnits(unitValues);
      }
    };
    fetchUnits();
  }, [selectedBuilding, auth?.data.token, unitTerm]);
  if (loading) {
    return <Spinner />;
  }
  return (
    <section className={styles.section}>
      {!isModal && <BackgroundDarkImage />}
      <motion.main
        className={`${styles.main} ${isDark && styles.mainDark}`}
        {...(!isModal ? fadeInUpAnimation : {})}
      >
        {isModal && (
          <button
            className={`${styles.cross} ${isDark && styles.crossDark}`}
            onClick={() => setModal(null)}
          >
            ✕
          </button>
        )}
        {!isModal && (
          <div className={styles.headBox}>
            <div className={styles.boxInner}>
              <SVGCheck />
              <div className={styles.labelHead}>Profile Information</div>
            </div>
            <img src={line} className={styles.line} alt='line' />
            <div className={styles.boxInner}>
              <SVGCheck />
              <div className={styles.labelHead}>Add Property</div>
            </div>
            <img src={line} className={styles.line} alt='line' />
            <div className={styles.boxInner}>
              <div className={styles.labelUncheck}>Identity Verification</div>
            </div>
          </div>
        )}

        <div className={styles.headingBox}>
          <div className={`${styles.heading} ${isDark && styles.headingDark}`}>
            Generate a TenantPay number
          </div>
          <div className={styles.subHead}>
            Please provide your building and unit details so we can generate a TenantPay number for
            you.
          </div>
        </div>
        {formik.status && (
          <div className={styles.statusBox}>
            <div className={styles.status}>{formik.status}</div>
          </div>
        )}
        <div className={styles.formOuter}>
          <form className={styles.form} noValidate onSubmit={formik.handleSubmit}>
            <div
              className={styles.firstName}
              title='Search, then select your building name from the options'
            >
              <label className={styles.label}>
                Building Name<span className={styles.mandatory}>*</span>
              </label>
              <div className={styles.inputOuter}>
                <input
                  type='text'
                  className={`${styles.passwordWrapper} ${isDark && styles.inputDark}`}
                  placeholder='Search your building'
                  value={selectedBuilding}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setSelectedBuilding(e.target.value);
                    setBuildingDropdown(true);
                    setUnitDropdown(false);
                  }}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => {
                    setTimeout(() => {
                      setIsFocused(false);
                    }, 500);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                    }
                  }}
                />
                {isFocused &&
                  (buildings.length ? (
                    <ClickAwayListener onClickAway={() => setBuildingDropdown(false)}>
                      <div className={`${styles.industryDropdown} ${isDark && styles.outerDark}`}>
                        {buildings.map((building, index) => (
                          <div
                            className={`${styles.industryOption} ${
                              formik.values.buildingName === building.name && styles.selectedOption
                            } ${isDark && styles.outerDarkOption}`}
                            key={index}
                            onClick={() => {
                              formik.setFieldValue('buildingName', building.name);
                              setSelectedBuilding(`${building.name}`);
                              setSelectedBuildingId(building.buildingId);
                              setBuildingDropdown(false);
                              setIsFocused(false);
                            }}
                          >
                            {building.name}
                            {building.buildingNumber && `, ${building.buildingNumber}`}
                            {building.address && `, ${building.address}`}
                          </div>
                        ))}
                      </div>
                    </ClickAwayListener>
                  ) : (
                    <ClickAwayListener onClickAway={() => setBuildingDropdown(false)}>
                      <div className={`${styles.noBuildingDropdown} ${isDark && styles.outerDark}`}>
                        <div className={styles.noBuilding}>No buildings found!</div>
                      </div>
                    </ClickAwayListener>
                  ))}
              </div>

              {formik.touched.buildingName && formik.errors.buildingName && (
                <div className={styles.error}>
                  <span>{formik.errors.buildingName}</span>
                </div>
              )}
            </div>

            {formik.values.buildingName !== '' && (
              <div
                className={styles.lastName}
                title={`Search for your unit number and select from the list`}
              >
                <label className={styles.label}>
                  Unit Number<span className={styles.mandatory}>*</span>
                </label>
                <div className={styles.inputOuter}>
                  <input
                    type='text'
                    className={`${styles.passwordWrapper} ${isDark && styles.inputDark}`}
                    placeholder='Search your unit'
                    value={selectedUnit}
                    onChange={(e) => {
                      if (/^\d*$/.test(e.target.value)) {
                        setUnitTerm(e.target.value);
                        setSelectedUnit(e.target.value);
                        setUnitDropdown(true);
                        setBuildingDropdown(false);
                      }
                    }}
                    onFocus={() => {
                      setUnitDropdown(true);
                      setBuildingDropdown(false);
                    }}
                    onBlur={() => {
                      setTimeout(() => {
                        setUnitDropdown(false);
                      }, 500);
                    }}
                    onKeyDown={(e) => {
                      if (!/^\d$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Enter') {
                        e.preventDefault();
                      }
                    }}
                  />

                  {unitDropdown &&
                    (units.length ? (
                      <div className={`${styles.industryDropdown} ${isDark && styles.outerDark}`}>
                        {units.map((unit, index) => (
                          <div
                            className={`${styles.industryOption} ${
                              formik.values.unitNumber === unit.unitNumber && styles.selectedOption
                            } ${isDark && styles.outerDarkOption}`}
                            key={index}
                            onClick={() => {
                              formik.setFieldValue('unitNumber', unit.unitNumber);
                              setSelectedUnit(unit.unitNumber);
                              setUnitId(unit.unitId);
                              setUnitDropdown(false);
                            }}
                          >
                            {unit.unitName
                              ? `${unit.unitNumber}, ${unit.unitName}`
                              : `${unit.unitNumber}`}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <ClickAwayListener onClickAway={() => setUnitDropdown(false)}>
                        <div
                          className={`${styles.noBuildingDropdown} ${isDark && styles.outerDark}`}
                        >
                          <div className={styles.noBuilding}>No units found!</div>
                        </div>
                      </ClickAwayListener>
                    ))}
                </div>
                <div className={styles.linkBox}>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      if (isModal) {
                        setModal('create-unit', { buildingId: selectedBuildingId });
                      } else {
                        navigate('/generate-rnt/create-unit', {
                          state: { buildingId: selectedBuildingId },
                        });
                      }
                    }}
                  >
                    {' '}
                    Unable to find the unit? Click here to create a new one.
                  </button>
                </div>
                {formik.touched.unitNumber && formik.errors.unitNumber && (
                  <div className={styles.error}>
                    <span>{formik.errors.unitNumber}</span>
                  </div>
                )}
              </div>
            )}

            <div className={styles.buttonWrap}>
              <button
                className={`${styles.backBtn}`}
                onClick={() => {
                  if (isModal) {
                    setModal('add-property', { payNumber: payNumber || '', disableRNT: true });
                  } else {
                    navigate('/add-property', {
                      state: { payNumber: payNumber || '', disableRNT: true },
                    });
                  }
                }}
              >
                Back
              </button>
              <button
                type='submit'
                onClick={() => {}}
                className={`${styles.submitBtn} ${
                  (formik.isSubmitting || !formik.isValid) && styles.disableBtn
                }`}
              >
                Next
              </button>
            </div>
          </form>
        </div>
      </motion.main>
    </section>
  );
};
export default SelectBuilding;
