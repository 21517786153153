import { useState } from 'react';
import styles from '../SelectBuilding/SelectBuilding.module.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { fadeInUpAnimation } from '../../../helpers/utils/types';
import line from '../../../assets/pages/addProperty/personal-info/line.svg';
import { Spinner } from '../../../helpers/Spinner/Spinner';
import SVGCheck from '../../../helpers/SVG/SVGCheck';
import { createUnit } from '../../../auth/_requests';
import { useAuth } from '../../../auth/Auth';
import { useModal } from '../../../helpers/hooks/modalContext';
import { useTheme } from '../../../helpers/hooks/ThemeContext';
import BackgroundDarkImage from '../../../helpers/BackgroundImage/BackgroundDark';

const initialValues = {
  unitName: '',
  unitNumber: '',
};
const registrationSchema = Yup.object().shape({
  unitName: Yup.string()
    .min(2, 'Minimum 2 characters')
    .max(50, 'Maximum 50 chracters')
    .matches(/^[A-Za-z0-9\s]+$/, 'Unit name can only contain letters and numbers')
    .test('no-only-spaces', 'Unit name cannot be only spaces', (value) => {
      if (value === undefined) {
        return false;
      }
      return value.trim().length > 0;
    })
    .required('Unit Name is required'),
  unitNumber: Yup.string()
    .matches(/^\d+$/, 'Unit number can only contain numbers')
    .min(1, 'Minimum 1 character')
    .required('Unit Number is required')
    .max(50, 'Maximum 50 characters'),
});
interface props {
  isModal?: boolean;
}
const AddUnitNumber = ({ isModal = false }: props) => {
  const [loading, setLoading] = useState(false);
  const { setModal, modalState } = useModal();
  const { auth } = useAuth();
  const { theme } = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const buildingId = location.state?.buildingId || modalState?.buildingId;
  const isDark = theme === 'dark';
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const requestBody = {
          buildingId: buildingId,
          unitNumber: values.unitNumber,
          unitName: values.unitName,
        };
        await createUnit(auth?.data.token, requestBody);
        if (isModal) {
          setModal('select-building');
        } else {
          navigate('/generate-rnt/select-building');
        }
      } catch (error: unknown) {
        if (error instanceof Error) {
          if (error.message === 'unit_exist') {
            setStatus('Unit number already exists for the selected building');
          } else {
            setStatus('Select a building on the previous page to continue.');
          }
        }
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  if (loading) {
    return <Spinner />;
  }
  return (
    <section className={styles.section}>
      {!isModal && <BackgroundDarkImage />}
      <motion.main
        className={`${styles.main} ${isDark && styles.mainDark}`}
        {...(!isModal ? fadeInUpAnimation : {})}
      >
        {isModal && (
          <button
            className={`${styles.cross} ${isDark && styles.crossDark}`}
            onClick={() => setModal(null)}
          >
            ✕
          </button>
        )}
        {!isModal && (
          <div className={styles.headBox}>
            <div className={styles.boxInner}>
              <SVGCheck />
              <div className={styles.labelHead}>Profile Information</div>
            </div>
            <img src={line} className={styles.line} alt='line' />
            <div className={styles.boxInner}>
              <SVGCheck />
              <div className={styles.labelHead}>Add Property</div>
            </div>
            <img src={line} className={styles.line} alt='line' />
            <div className={styles.boxInner}>
              <div className={styles.labelUncheck}>Identity Verification</div>
            </div>
          </div>
        )}

        <div className={styles.headingBox}>
          <div className={`${styles.heading} ${isDark && styles.headingDark}`}>
            Create a new unit
          </div>
          <div className={styles.subHead}>
            Please provide your unit details so that we can create a new unit for you.
          </div>
        </div>
        {formik.status && (
          <div className={styles.statusBox}>
            <div className={styles.status}>{formik.status}</div>
          </div>
        )}
        <div className={styles.formOuter}>
          <form
            className={styles.form}
            noValidate
            id='kt_login_signup_form'
            onSubmit={formik.handleSubmit}
          >
            <div className={styles.lastName} title='Provide the new unit number for this property'>
              <label className={styles.label}>
                Unit Number<span className={styles.mandatory}>*</span>
              </label>
              <input
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('unitNumber')}
                placeholder='456'
                className={clsx(
                  formik.touched.unitNumber && formik.errors.unitNumber ? styles.isInvalid : '',
                  formik.touched.unitNumber && !formik.errors.unitNumber ? styles.valid : '',
                  `${styles.inputBox} ${isDark && styles.inputDark}`
                )}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                  }
                  if (
                    !/[0-9]/.test(event.key) &&
                    !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(event.key)
                  ) {
                    event.preventDefault();
                  }
                }}
              />

              {formik.touched.unitNumber && formik.errors.unitNumber && (
                <div className={styles.error}>
                  <span>{formik.errors.unitNumber}</span>
                </div>
              )}
            </div>
            <div className={styles.firstName} title='Specify the name for the new unit'>
              <label className={styles.label}>
                Unit Name<span className={styles.mandatory}>*</span>
              </label>
              <input
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('unitName')}
                placeholder='Oriental Flats'
                className={clsx(
                  formik.touched.unitName && formik.errors.unitName ? styles.isInvalid : '',
                  formik.touched.unitName && !formik.errors.unitName ? styles.valid : '',
                  `${styles.inputBox}  ${isDark && styles.inputDark}`
                )}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                  }
                }}
              />

              {formik.touched.unitName && formik.errors.unitName && (
                <div className={styles.error}>
                  <span>{formik.errors.unitName}</span>
                </div>
              )}
            </div>

            <div className={styles.buttonWrap}>
              <button
                className={`${styles.backBtn}`}
                onClick={() => {
                  if (isModal) {
                    setModal('select-building');
                  } else {
                    navigate('/generate-rnt/select-building');
                  }
                }}
              >
                Back
              </button>
              <button
                type='submit'
                onClick={() => {}}
                className={`${styles.submitBtn} ${
                  (formik.isSubmitting || !formik.isValid) && styles.disableBtn
                }`}
              >
                Next
              </button>
            </div>
          </form>
        </div>
      </motion.main>
    </section>
  );
};
export default AddUnitNumber;
