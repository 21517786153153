import clsx from 'clsx';
import { useModal } from '../../helpers/hooks/modalContext';
import { useTheme } from '../../helpers/hooks/ThemeContext';
import styles from './PrivacyPolicy.module.css';

const PrivacyPolicy = () => {
  const { setModal } = useModal();
  const { theme } = useTheme();
  const isDark = theme === 'dark';
  return (
    <div className={styles.main}>
      <div className={`${styles.header} ${isDark && styles.headerDark}`}>
        <h1 className={styles.title}>Privacy Policies</h1>
        <button className={styles.cross} onClick={() => setModal(null)}>
          ✕
        </button>
      </div>
      <div className={clsx(styles.footer, isDark && styles.footerDark)}>
        <div></div>
        <button className={styles.btn} onClick={() => setModal(null)}>
          Understood
        </button>
      </div>
      <div className={clsx(styles.section, isDark && styles.sectionDark)}>
        <p className={styles.date}>
          <strong>Effective Date:</strong> December 26, 2024
        </p>
        <h1>1. Introduction and Scope</h1>
        <p>
          This Privacy Policy (the &ldquo;<strong>Policy</strong>&rdquo;) outlines the practices of
          TenantPay Inc. (hereinafter referred to as &ldquo;<strong>TenantPay</strong>", &ldquo;
          <strong>we</strong>&rdquo;, &ldquo;<strong>our</strong>&rdquo;, or "<strong>us</strong>
          &rdquo;) regarding the processing of your personal information, as defined in the{' '}
          <u>Definition of &ldquo;personal information&rdquo;</u> section below. Specifically, the
          purpose of this Policy is to explain how and for what purposes TenantPay collects, uses,
          shares or otherwise processes personal information and how we safeguard the
          confidentiality of personal information when individuals visit our Website located at
          https://www.tenantpay.com/ (&ldquo;<strong>Website</strong>&rdquo;), when tenants add us
          as their rent payee on their online banking accounts to pay their rent, when tenants or
          landlords create a TenantPay account on our rent processing platform (the &ldquo;
          <strong>Platform</strong>&rdquo;), or when individuals otherwise interact with us.
        </p>
        <h1>2. Definition of &ldquo;personal information&rdquo;</h1>
        <p>
          In this Policy, &ldquo;personal information&rdquo; means any information that can be used,
          either alone or in combination with other information, to identify an individual. This
          includes information such as your name, mailing address, e-mail address, telephone number,
          or any other contact details. It may also include other types of more technical
          information, but only when this information can identify you as an individual. Information
          that is anonymized and cannot be associated with an identifiable individual is not
          considered to be personal information, if it is, reasonably foreseeable in the
          circumstances that this information irreversibly no longer allows you to be identified
          directly or indirectly.
        </p>
        <h1>3. Personal Information We Collect</h1>
        <p>
          The personal information we collect about you depends on the nature of your interactions
          with TenantPay (e.g., if you are a tenant or a landlord, if create an account on our
          Platform, etc.). Below is a description of the personal information we may collect about
          you.
        </p>
        <p>
          <strong>a. Information we collect about tenants:</strong>
        </p>
        <ul>
          <li>
            <strong>Contact information</strong> you provide when creating an account on our
            Platform or when you send rent payment to TenantPay through online banking. This
            information may include your name, email address, and phone number. We may also receive
            your contact information from your landlord.
          </li>
        </ul>
        <ul>
          <li>
            <strong>Lease information </strong>you provide when creating an account on our Platform,
            such as property address, start and end date of your lease, due date of your lease, and
            lease document.
          </li>
        </ul>
        <ul>
          <li>
            <strong>Demographic information </strong>you provide when creating an account on our
            Platform. This information may include your area of employment. <br />{' '}
          </li>
          <li>
            <strong>Financial information </strong>you provide when you use our Platform or when you
            send rent payments to TenantPay through online banking. This information may include
            your bank account details (e.g., account number) and details about the rent payments you
            send to TenantPay (payment methods, payments history, etc.).
          </li>
          <li>
            <strong>
              <span dir='ltr'>Other information</span>
            </strong>{' '}
            <span dir='ltr'>
              you provide when creating an account on our Platform and which is&nbsp;
            </span>
            <span dir='ltr'>
              necessary for TenantPay to comply with our regulatory Know Your Customer (KYC)
              obligations.
            </span>
            <br />
            <span dir='ltr'>
              This information may include a copy of government-issued identification and your date
              of birth
            </span>
          </li>
        </ul>
        <p>
          &nbsp;<strong>b. Information we collect about landlords:</strong>
        </p>
        <ul>
          <li>
            <strong>
              <span dir='ltr'>Building details</span>
            </strong>{' '}
            <span dir='ltr'>
              you provide when creating an account on our Platform. This information may&nbsp;
            </span>
            <span dir='ltr'>
              include the address of properties you own and manage through our Platform and the
              rent&nbsp;
            </span>
            <span dir='ltr'>
              charged and lease duration for each. We may also collect any other information
              included in the&nbsp;
            </span>
            <span dir='ltr'>
              lease agreement you can upload when adding a new property to your account on our
              Platform
            </span>
          </li>
          <li>
            <strong>Contact information </strong>you provide when creating an account on our
            Platform. This information may include your name, email, and phone number.
          </li>
          <li>
            <strong>Banking information </strong>you provide when you use our Platform. This
            information may include your bank account details (e.g., account number), payment
            methods, and the payment history of rent received from tenants.
          </li>
          <li>
            <strong>
              <span dir='ltr'>Other information</span>
            </strong>{' '}
            <span dir='ltr'>
              you provide when creating an account on our Platform and which is&nbsp;
            </span>
            <span dir='ltr'>
              necessary for TenantPay to comply with our regulatory Know Your Customer (KYC)
              obligations.
            </span>
            <br />
            <span dir='ltr'>
              This information may include a copy of government-issued identification, your date of
              birth,&nbsp;
            </span>
            <span dir='ltr'>
              shareholding documents, record of existence, directors&rsquo; registry, and related
              documents
            </span>
          </li>
        </ul>
        <p>
          <strong>c. Information we collect about landlords and tenants from third parties:</strong>
        </p>
        <p>
          <span dir='ltr'>
            We may collect information from third-party ID verification service providers to ensure
            that the&nbsp;
          </span>
          <span dir='ltr'>
            government-issued identification documents you provide us when creating an account on
            our Platform&nbsp;
          </span>
          <span dir='ltr'>are authentic, valid, and issued by recognized authorities.</span>{' '}
          <span dir='ltr'>Our collection of such information helps us&nbsp;</span>
          <span dir='ltr'>comply with our regulatory Know Your Customer (KYC) obligations</span>
        </p>
        <p>
          <strong>d. Information we collect about visitors of our Website:</strong>
        </p>
        <ul>
          <li>
            <strong>Contact information</strong>, including your name, email, whether you are a
            landlord or tenant, and any other information you may provide us when using our{' '}
            <a href='https://www.tenantpay.com/contact#contact-us'>Contact Form</a>.
          </li>
        </ul>
        <ul>
          <li>
            <strong>Information we collect automatically</strong> when you visit our Website using
            technologies such as cookies and pixels. The technical information we collect when you
            visit our Website includes . See the <a>Cookies</a> section below for more information
            on how we use cookies to collect your personal information.
          </li>
        </ul>
        <h1>4. How We Use Your Personal Information</h1>
        <p>
          We may use your personal information for general business purposes such as providing or
          delivering our services and Platform, managing our Website, preventing or reducing
          commercial risk, as well as for purposes relating to our information system or network
          security. More specifically, we may use your personal information for the following
          general business purposes:
        </p>
        <ul>
          <li>
            <strong>Managing our Website.&nbsp;</strong>We may use your personal information to
            manage and facilitate the use of our Website when you visit or access it, which may
            include using cookies and other similar technologies. See the <a>Cookies</a> section
            below for more information on how we use cookies.
          </li>
          <li>
            <strong>Providing or delivering our services.</strong>We may use your personal
            information to administer our Website or Platform, which may include transferring rent
            payments made to TenantPay as a payee to the appropriate landlords and notifying
            landlords of payments made by tenants. <br />{' '}
          </li>
          <li>
            <strong>Book a Demo</strong>: If you contact us to request a demo of our Platform, we
            will use your contact information, and any other information you choose to provide to us
            for the purpose of contacting you to facilitate your request and discuss how our
            Platform can serve your needs.
            <br />{' '}
          </li>
          <li>
            <strong>Communicating with you:</strong> If you contact us with a question or inquiry,
            we may use your personal information to respond to you. We may also send you updates,
            newsletters, offers, and promotions or other marketing communications using your email
            address or phone number. You can unsubscribe at any time by clicking the
            &ldquo;unsubscribe&rdquo; link included at the bottom of each email. Alternatively, you
            can opt out of receiving marketing communications by contacting us using the contact
            information provided in the <a>Contact Us</a> section below. Please note that you may
            continue to receive transactional or account-related communications from us. <br />{' '}
          </li>
          <li>
            <strong>For other purposes permitted or required by law.</strong> We may use personal
            information without your consent where we are legally permitted or required to do so by
            law, for example, to comply with a legal obligation or to protect the rights of
            TenantPay or to enforce or apply our terms of use and other agreements, or to detect,
            prevent and investigate suspicious, unauthorized, fraudulent, illegal or unlawful
            activity.
          </li>
        </ul>
        <h1>5. Sharing of Personal Information</h1>
        <p>
          We do not share your personal information to third parties without your consent, except as
          set forth below or as required or permitted by applicable law.
        </p>
        <ul>
          <li>
            <strong>Service providers</strong>.{' '}
            <span dir='ltr'>
              We may transfer or otherwise make available your personal information to&nbsp;
            </span>
            <span dir='ltr'>
              third parties that provide services on our behalf. We use service providers to provide
              services
            </span>
            <br />
            <span dir='ltr'>
              such as IT support, hosting the Website, storing data, providing marketing
              assistance,&nbsp;
            </span>
            <span dir='ltr'>
              conducting analytics, ID and credit verifications, processing credit card payments and
              providing&nbsp;
            </span>
            <span dir='ltr'>customer services.</span>
          </li>
        </ul>
        <ul>
          <li>
            <strong>Credit reporting agencies. </strong>If you create a tenant account on our
            Platform we may share, , your rent payment history information with our partner credit
            reporting agencies. The sharing of such information is necessary for our partners to
            monitor your payment practices and adjust the pool of information that may use to
            perform credit verifications accordingly.
          </li>
          <li>
            <strong>Legal and compliance.</strong> We may provide your personal information in
            response to a search warrant or other legally valid inquiry or order, or to another
            organization for the purposes of investigating a breach of an agreement or contravention
            of law or detecting, suppressing, or preventing fraud, or as otherwise may be required
            or permitted by applicable Canadian or other law or legal process. Your personal
            information may also be disclosed where necessary for the establishment, exercise or
            defense of legal claims and to investigate or prevent actual or suspected loss or harm
            to persons or property.
          </li>
          <li>
            <strong>
              <span dir='ltr'>Business transactions.</span>
            </strong>{' '}
            <span dir='ltr'>
              We may disclose personal information to facilitate or enable any&nbsp;
            </span>
            <span dir='ltr'>
              corporate or commercial transaction involving TenantPay to the extent permitted by
              applicable&nbsp;
            </span>
            <span dir='ltr'>law.</span>
          </li>
        </ul>
        <p>
          <span dir='ltr'>
            Note that within TenantPay, our employees may have access, in whole or in part, to your
            personal&nbsp;<span dir='ltr'>information on a need-to-know basis.</span>
          </span>
        </p>
        <h1>6. Cross-border Transfers</h1>
        <p>
          <span dir='ltr'>
            Some of our external third-party service providers are based outside of Qu&eacute;bec
            and/or Canada,&nbsp;
          </span>
          <span dir='ltr'>
            including in the United States. Their processing of your personal information will
            therefore involve a&nbsp;
          </span>
          <span dir='ltr'>
            transfer of information outside of Qu&eacute;bec and/or Canada. Organizations based
            outside of Canada may&nbsp;
          </span>
          <span dir='ltr'>
            not be subject to privacy laws that provide you with the same rights and protections as
            in Canada. As a&nbsp;
          </span>
          <span dir='ltr'>
            result, when your personal information is used or stored in a jurisdiction other than
            where you are&nbsp;
          </span>
          <span dir='ltr'>
            residing, it may be subject to the law of this foreign jurisdiction, including any law
            permitting or&nbsp;
          </span>
          <span dir='ltr'>
            requiring disclosure of the information to the government, government agencies, courts
            and law&nbsp;
          </span>
          <span dir='ltr'>enforcement in that jurisdiction.</span>
        </p>
        <h1>7.Your Privacy Rights</h1>
        <p>
          Under privacy laws applicable in the region in which you reside, you may have the
          following rights with respect to your personal information, subject to limited exceptions
          set out in such laws:
        </p>
        <ul>
          <li>
            <strong>Right to information: </strong>You have the right to ask us about our processing
            of your personal information, including about the categories of persons who have access
            to your personal information within TenantPay and the applicable retention period.
          </li>
          <li>
            <strong>Right to access. </strong>You can ask us if we hold any personal information
            about you, and if so, you can request access to that personal information.
          </li>
        </ul>
        <ul>
          <li>
            <strong>Right to correction. </strong>You have the right to ask us to rectify any
            personal information that you demonstrate to be incorrect or outdated and to complete
            any information that you demonstrate to be incomplete.
          </li>
        </ul>
        <ul>
          <li>
            <strong>Right to withdraw consent.</strong> You may withdraw your consent to our
            processing of your personal information to the extent permitted by law and our
            contractual obligations. However, you understand that in such a case, you may no longer
            be able to benefit from our products and services.
          </li>
        </ul>
        <p>
          To exercise any of your privacy rights, please contact us using the information provided
          in the <u>Contact Us</u> section below. Note that we may request certain personal
          information for the purpose of verifying the identity of the individual seeking to
          exercise their privacy rights.
        </p>
        <h1>8. Third Party Links</h1>
        <p>
          Our Website may contain links to other websites that TenantPay does not own or operate. We
          provide links to third-party websites as a convenience to the visitors of our Website.
          These links are not intended as an endorsement of or referral to the linked websites. The
          linked websites have separate and independent privacy policies, notices and terms of use.
          We do not have any control over such websites, and therefore we have no responsibility or
          liability for the manner in which the organizations that operate such linked websites may
          collect, use or disclose, secure and otherwise treat personal information. We encourage
          you to read the privacy policy of every website you visit.
        </p>
        <h1>9. Cookies</h1>
        <p>
          We use cookies, web beacons (including pixels and tags), and similar technologies on our
          Website that collect certain information about you by automated means.
        </p>
        <p>
          A cookie is a tiny element of data that our Website sends to a user&rsquo;s browser, which
          may then be stored on the user&rsquo;s hard drive so that we can recognize the
          user&rsquo;s computer or device when they return. You may set your browser to notify you
          when you receive a cookie or to not accept certain cookies. However, if you decide not to
          accept cookies from our Website, you may not be able to take advantage of all of the
          Website&rsquo;s features.
        </p>
        <p>
          We may use a third party such as Google Analytics to help us gather and analyze
          information about the areas visited on the Website (such as the pages most read, time
          spent, search terms and other engagement data) in order to evaluate and improve the user
          experience and the Website. These third parties may use cookies and other tracking
          technologies. For more information about Google Analytics or to prevent the storage and
          processing of this data (including your IP address) by Google, you can download and
          install the browser plug-in available at the following link:
        </p>
        <ul>
          <li>
            <a href='https://tools.google.com/dlpage/gaoptout?hl=en'>
              https://tools.google.com/dlpage/gaoptout?hl=en
            </a>
            .
          </li>
        </ul>
        <p>
          You can also obtain additional information on Google Analytics&rsquo; data privacy and
          security at the following links:
        </p>
        <ul>
          <li>
            <a href='https://policies.google.com/technologies/partner-sites'>
              https://policies.google.com/technologies/partner-sites
            </a>
            ; and
          </li>
          <li>
            <a href='https://support.google.com/analytics/topic/2919631'>
              https://support.google.com/analytics/topic/2919631
            </a>
            .
          </li>
        </ul>
        <h1>10. Minors</h1>
        <p>
          The Website is designed for a general audience and are not directed to children under the
          &nbsp;We do not knowingly collect or solicit personal information from children under the
          age of 16 through the Website. If we become aware that we have collected personal
          information from a child under the age of 16 through the Website, we will promptly delete
          the information from our records. If you believe that a child under the age of 16 may have
          provided us with personal information, contact us as specified in the <u>Contact Us</u>{' '}
          section.
        </p>
        <h1>11. Retention</h1>
        <p>
          We will retain your personal information for as long as necessary to fulfill the purposes
          for which the information was collected. However, we may retain your personal information
          for a longer period when required to comply with our legal and regulatory obligations
          regarding information or record retention.
        </p>
        <h1>12. Security Safeguards</h1>
        <p>
          We protect and safeguard your personal information using physical, electronic and
          procedural measures appropriate to the volume and sensitivity of the information, the
          purposes for which it is used, and the format in which it is stored, including safeguards
          to protect your information against loss and theft, as well as unauthorized access,
          disclosure, copying, use, or modification. Specifically, we implement the following
          measures to protect your personal information, including:
        </p>
        <ul>
          <li>
            Internal policies and procedures that define the roles and responsibilities of our
            employees, representatives, and service providers throughout the life cycle of an item
            of information and limit their access to that information on a
            &ldquo;need-to-know&rdquo; basis;
          </li>
          <li>
            Physical, electronic and procedural safeguards that comply with relevant standards to
            protect personal information;
          </li>
          <li>
            If the information is collected or stored electronically, technical safeguards such as
            encryption, firewalls, passwords, anti-virus software and similar measures;
          </li>
          <li>
            A designated privacy officer to monitor TenantPay&rsquo;s compliance with applicable
            privacy laws;
          </li>
          <li>
            Procedures for receiving, investigating, and responding to complaints or inquiries about
            TenantPay&rsquo;s information handling practices; and
          </li>
          <li>
            Contractual protections to ensure that service providers with whom we share personal
            information maintain adequate protections and security standards.
          </li>
        </ul>
        <p>
          If you have reason to believe that personal information has been compromised or accessed,
          used or disclosure unlawfully, please contact us using the information provided in the{' '}
          <u>Contact Us</u> section.
        </p>
        <h1>13. Updates to this Policy</h1>
        <p>
          This Policy is current as of the Effective Date set forth above and may be amended from
          time to time at our sole discretion. We recommend that you periodically review this Policy
          as it may be updated or revised from time to time. If we make material changes, we will
          take reasonable steps to notify you of those changes, including by posting the revised
          Policy on our Website with an adequate notice.
        </p>
        <h1>14. Contact Us</h1>
        <p>
          If you have any questions regarding our processing of your personal information or about
          this Policy, please contact our Privacy Officer in writing at{' '}
          <a href='mailto:support@tenantpay.com'>support@tenantpay.com</a>
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
