import styles from './VerificationSuccess.module.css';
import { motion } from 'framer-motion';
import { fadeInUpAnimation } from '../../../helpers/utils/types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BackgroundImage from '../../../helpers/BackgroundImage/BackgroundImage';
import SVGCheck from '../../../helpers/SVG/SVGCheck';
import { useTheme } from '../../../helpers/hooks/ThemeContext';
import { useDomainStatus } from '../../../helpers/hooks/DomainStatus';
import BackgroundDarkImage from '../../../helpers/BackgroundImage/BackgroundDark';

export function VerificationSuccess() {
  const navigate = useNavigate();
  const { theme } = useTheme();
  const { domainStatus } = useDomainStatus();
  const isDark = theme === 'dark' && domainStatus === 'valid';
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate('/dashboard');
    }, 5000);

    return () => clearTimeout(timeoutId);
  });
  return (
    <section className={styles.section}>
      {isDark ? <BackgroundDarkImage /> : <BackgroundImage />}
      <motion.main className={`${styles.main} ${isDark && styles.mainDark}`} {...fadeInUpAnimation}>
        <SVGCheck isBigCheck={true} />
        <div className={styles.headingBox}>
          <div className={`${styles.heading} ${isDark && styles.headingDark}`}>Success!</div>
          <div className={styles.subHead}>
            You have successfully completed the verification process.
          </div>
        </div>
      </motion.main>
    </section>
  );
}
