/* eslint-disable @typescript-eslint/no-explicit-any */
import styles from './CardSettings.module.css';
import { PaymentOption } from '../../../helpers/utils/types';
import { useModal } from '../../../helpers/hooks/modalContext';
import { useAuth } from '../../../auth/Auth';
import { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { getPaymentOptions } from '../../../auth/_requests';

import visa from '../../../assets/pages/payment/visa.png';
import mastercard from '../../../assets/pages/payment/mastercard.png';
import amex from '../../../assets/pages/payment/amex.png';
import { useTheme } from '../../../helpers/hooks/ThemeContext';

import { Spinner } from '../../../helpers/Spinner/Spinner';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Trash } from 'lucide-react';
import PaymentNav from '../../paymentPage/PaymentNav/PaymentNav';
import CardDesign from '../../paymentPage/PaymentSection/component/CardDesign/CardDesign';
import CardCarousal from '../../paymentPage/CardSection/CardCarousal/CardCarousal';

const CardSettings: React.FC = () => {
  const { setModal } = useModal();

  const { auth } = useAuth();
  const [paymentOptions, setPaymentOptions] = useState<PaymentOption[]>([]);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState<PaymentOption | null>(null);

  const [, setSelectedMethod] = useState<string | null>(
    localStorage.getItem('selectedPaymentOption')
  );

  const { theme } = useTheme();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const paymentOptionId = localStorage.getItem('selectedPaymentOption');
    if (paymentOptionId) {
      const foundOption = paymentOptions.find(
        (option) => option.paymentOptionId === paymentOptionId
      );
      if (foundOption) {
        setSelectedPaymentOption(foundOption);
      }
    }
  }, [paymentOptions]);

  useEffect(() => {
    const fetchPaymentOptions = async () => {
      if (!auth?.data.token) return;
      try {
        setLoading(true);
        const options: PaymentOption[] = await getPaymentOptions(auth.data.token);
        setPaymentOptions(options);
        if (options.length > 0) {
          if (!selectedPaymentOption) {
            handleSelectMethod(options[0].paymentOptionId);
            setSelectedPaymentOption(options[0]);
          }
        }
        const paymentOptionId = localStorage.getItem('selectedPaymentOption');
        if (paymentOptionId) {
          const foundOption = options.find((option) => option.paymentOptionId === paymentOptionId);
          if (foundOption) {
            setSelectedPaymentOption(foundOption);
            setSelectedMethod(paymentOptionId);
          }
        }
      } catch (error) {
        setLoading(false);
        toast.error('Error fetching payment options: ' + error, {
          duration: 8000,
          style: {
            maxWidth: '327px',
            background: `${theme === 'dark' && '#000'}`,
            color: `${theme === 'dark' ? '#f5f5f5' : `#020b28`}`,
            border: `${theme === 'dark' && '1px solid #f5f5f5'}`,
          },
        });
      } finally {
        setLoading(false);
      }
    };
    fetchPaymentOptions();
  }, [auth]);
  const handleSelectMethod = async (paymentOptionId: string) => {
    setSelectedMethod(paymentOptionId);
    localStorage.setItem('selectedPaymentOption', paymentOptionId);
    const selectedOption = paymentOptions?.find(
      (option) => option.paymentOptionId === paymentOptionId
    );

    if (selectedOption) {
      setSelectedPaymentOption({ ...selectedOption });
    }
  };
  /*  const handleCancel = () => {
    setModal('return-to-dashboard');
  }; */
  const handleActiveIndexChange = (swiper: any) => {
    const activeIndex = swiper.activeIndex;
    const activePaymentOption = paymentOptions?.[activeIndex];

    if (activePaymentOption) {
      handleSelectMethod(activePaymentOption.paymentOptionId);
    }
  };
  const handleRemove = () => {
    setModal('remove-card', { editCard: selectedPaymentOption as PaymentOption });
  };

  if (loading) {
    return <Spinner widthFull='!bg-transparent' />;
  }
  return (
    <>
      <Toaster position='top-center' reverseOrder={false} />
      <PaymentNav />
      <main className={styles.main}>
        {paymentOptions.length === 0 ? ( // Check if there are no payment options
          <div className={styles.noPaymentOptions}>
            <p>No payment options available, please add a card to make payment.</p>
            <button onClick={() => setModal('add-payment-method')}>
              <CardDesign noCard={true} />
            </button>
          </div>
        ) : (
          <section className={styles.section}>
            <div style={{ flex: 2 }}>
              {paymentOptions.length <= 2 && (
                <Swiper
                  style={{
                    marginBottom: '2rem',
                    width: '100%', // Use 100% width of the container
                    maxWidth: '280px', // Limit to a maximum width if needed
                    maxHeight: '30vh',
                    flex: 2,
                  }}
                >
                  <SwiperSlide>
                    {' '}
                    <button onClick={() => setModal('add-payment-method')}>
                      <CardDesign addCard={true} />
                    </button>
                  </SwiperSlide>
                </Swiper>
              )}
              <CardCarousal
                paymentOptions={paymentOptions}
                onActiveIndexChange={handleActiveIndexChange}
              ></CardCarousal>
            </div>

            <div style={{ flex: 3 }}>
              {selectedPaymentOption && (
                <div
                  className={`${styles.paymentForm} ${theme === 'dark' && styles.paymentFormDark}`}
                >
                  <p
                    className={`${styles.cardInformation} ${
                      theme === 'dark' && styles.cardInformationDark
                    }`}
                  >
                    {' '}
                    Card Information
                    <button onClick={handleRemove}>
                      <Trash />
                    </button>
                  </p>

                  <form>
                    <div className='form-group'>
                      <label>Card Number</label>
                      <div className={styles.inputWrapper}>
                        <input
                          type='text'
                          value={`**** **** **** ${selectedPaymentOption.cardEndingDigits}`}
                          disabled
                          className={styles.cardInput}
                        />
                        {selectedPaymentOption.cardBrand === 'Visa' && (
                          <img src={visa} alt='Visa' className={styles.cardLogo} />
                        )}
                        {selectedPaymentOption.cardBrand === 'Mastercard' && (
                          <img src={mastercard} alt='MasterCard' className={styles.cardLogo} />
                        )}
                        {selectedPaymentOption.cardBrand === 'Amex' && (
                          <img src={amex} alt='American Express' className={styles.cardLogo} />
                        )}
                      </div>
                    </div>
                    <div className='form-group'>
                      <label>Cardholder Name</label>
                      <input
                        type='text'
                        value={selectedPaymentOption.cardHolderName || ''}
                        onChange={(e) =>
                          setSelectedPaymentOption({
                            ...selectedPaymentOption,
                            cardHolderName: e.target.value,
                          })
                        }
                        disabled
                      />
                    </div>

                    <div className='form-group'>
                      <label>Expiration Date</label>
                      <input
                        type='text'
                        value={`${selectedPaymentOption.expiryMonth || ''}/${
                          selectedPaymentOption.expiryYear || ''
                        }`}
                        onChange={(e) => {
                          const [month, year] = e.target.value.split('/');
                          setSelectedPaymentOption({
                            ...selectedPaymentOption,
                            expiryMonth: parseInt(month, 10) || 0,
                            expiryYear: parseInt(year, 10) || 0,
                          });
                        }}
                        disabled
                      />
                    </div>
                  </form>
                </div>
              )}
            </div>
          </section>
        )}
      </main>
    </>
  );
};

export default CardSettings;
