import React, { useEffect, useState } from 'react';
import styles from './OrderSummary.module.css';
import { useTheme } from '../../../helpers/hooks/ThemeContext';
import { usePayment } from '../../../helpers/hooks/PaymentContext';
import { buildingDetailsResponse } from '../../../helpers/utils/types';
import { useAuth } from '../../../auth/Auth';
import { getBuildingDetailsById } from '../../../auth/_requests';
import PropertyDetails from '../PropertyDetails/PropertyDetails';
import { Spinner } from '../../../helpers/Spinner/Spinner';
import { Button, Tooltip, Whisper } from 'rsuite';
import info from '../../../assets/pages/payment/about.png';
import { formatDateToDisplayUTCLong } from '../../../helpers/utils/utils';
import { useModal } from '../../../helpers/hooks/modalContext';

interface OrderSummaryProps {
  paymentType: string;
  tenantBuildingId: string | undefined;
}

const OrderSummary: React.FC<OrderSummaryProps> = ({ paymentType, tenantBuildingId }) => {
  const { auth } = useAuth();
  const { theme } = useTheme();
  const { setModal } = useModal();
  const { paymentSummary } = usePayment();
  const [buildingDetails, setBuildingDetails] = useState<buildingDetailsResponse | undefined>(
    undefined
  );
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (tenantBuildingId) {
      const fetchBuildingDetails = async () => {
        try {
          setLoading(true);
          const details = await getBuildingDetailsById(auth?.data?.token, tenantBuildingId);
          setBuildingDetails(details);
        } catch (e) {
          setLoading(false);
          console.error('Error fetching building details', e);
        } finally {
          setLoading(false);
        }
      };

      fetchBuildingDetails();
    }
  }, [tenantBuildingId]);

  useEffect(() => {}, [paymentSummary]);

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-CA', {
      style: 'currency',
      currency: 'CAD',
    }).format(amount || 0);
  };

  const textColorClass = theme === 'dark' ? styles.textDark : styles.textLight;
  if (loading) {
    return <Spinner widthFull='!bg-transparent' />;
  }
  return (
    <div className={styles.main}>
      <div>
        <PropertyDetails buildingDetails={buildingDetails?.data[0]}></PropertyDetails>
      </div>
      <div
        className={`${styles.orderSummary} ${theme === 'dark' && styles.orderSummaryDark}`} // Apply the text color class here
      >
        <h2 className={`${styles.title} ${theme === 'dark' && styles.titleDark}`}>Order summary</h2>

        <div className='space-y-4'>
          {paymentType === 'subscription' && (
            <div>
              <div
                className={`${styles.spaceBetween} ${theme === 'dark' && styles.spaceBetweenDark}`}
              >
                <div className={`${styles.tooltipWrap} signUpBtn`}>
                  <p>First Payment Date</p>
                  <Whisper
                    trigger='hover'
                    placement={'top'}
                    controlId={`control-id-top`}
                    speaker={
                      <Tooltip>
                        <p>
                          Rent payments are processed in advance to ensure timely delivery to the
                          landlord, typically 4 days before the monthly due date.
                        </p>
                        <strong>Processing schedule based on monthly due date:</strong>
                        <ul style={{ margin: 0, paddingLeft: '16px' }}>
                          <li>
                            <strong>Due on the 1st or 2nd:</strong> Processed on the 27th of the
                            previous month.
                          </li>
                          <li>
                            <strong>Due on the 3rd or 4th:</strong> Processed on the 28th of the
                            previous month.
                          </li>
                          <li>
                            <strong>Due between the 5th and 28th:</strong> Processed 4 days before
                            the due date.
                          </li>
                        </ul>
                      </Tooltip>
                    }
                  >
                    <Button appearance='subtle' className={styles.btnImg}>
                      <img src={info} className={styles.infoIcon} alt='info' />
                    </Button>
                  </Whisper>
                </div>
                <span className={textColorClass}>
                  <strong>
                    {' '}
                    {paymentSummary?.subscriptionPeriod?.firstPaymentDate ||
                      '*No Payment Scheduled'}
                  </strong>
                </span>
              </div>
              <div
                className={`${styles.spaceBetween} ${theme === 'dark' && styles.spaceBetweenDark}`}
              >
                <div className={styles.rentOuter}>
                  <p>Payment Cycle</p>
                  <div className={styles.btnOuter}>
                    <button
                      className={`${styles.scheduleBtn}`}
                      onClick={() => {
                        setModal('payment-schedule', {
                          buildingId: Number(tenantBuildingId),
                          propertyAmount: paymentSummary.data.totalAmount,
                        });
                      }}
                    >
                      {'View Rent Schedule'}
                    </button>
                  </div>
                </div>
                <span className={textColorClass}>
                  <strong>
                    {' '}
                    {paymentSummary?.subscriptionPeriod?.recurringPeriod?.month === '1'
                      ? 'Monthly'
                      : paymentSummary?.subscriptionPeriod?.recurringPeriod?.month
                      ? `${paymentSummary?.subscriptionPeriod?.recurringPeriod?.month} months`
                      : '*Payment Cycle Unavailable'}
                  </strong>
                </span>
              </div>
              <div
                className={`${styles.spaceBetween} ${theme === 'dark' && styles.spaceBetweenDark}`}
              >
                <p>Subscription Ends</p>
                <span className={textColorClass}>
                  <strong>
                    {paymentSummary?.subscriptionPeriod?.endDate
                      ? formatDateToDisplayUTCLong(paymentSummary?.subscriptionPeriod?.endDate)
                      : '*To Be Determined'}
                  </strong>
                </span>
              </div>
            </div>
          )}
          <hr className={styles.separator} />
          <div className={styles.amountContainer}>
            <span
              className={`${styles.rentAmountLabel} ${
                theme === 'dark' && styles.rentAmountLabelDark
              }`}
            >
              Rent Amount
            </span>
            <span className={`${styles.rentAmount} ${theme === 'dark' && styles.rentAmountDark}`}>
              {' '}
              <strong>
                {' '}
                {formatCurrency(
                  paymentSummary?.data?.amount || buildingDetails?.data[0].building.rentAmount
                )}
              </strong>
            </span>
          </div>
          <div
            className={`${styles.spaceBetween} ${theme === 'dark' && styles.spaceBetweenDark} ${
              styles.flexRow
            }`}
          >
            <p>
              Processing Fee{' '}
              {paymentSummary?.data?.processingFee && (
                <span>
                  {(() => {
                    const { fixed, percentage, percentageRate } = paymentSummary.data.processingFee;
                    if (fixed && fixed !== '0' && percentage && percentage !== '0') {
                      return ` ( ${formatCurrency(fixed)} + ${percentageRate}%: ${formatCurrency(
                        percentage
                      )})`;
                    } else if (fixed && fixed !== '0') {
                      return ``;
                    } else if (percentage && percentage !== '0') {
                      return ` (${percentageRate}%: ${formatCurrency(percentage)})`;
                    }
                    return null;
                  })()}
                </span>
              )}
            </p>
            <span className={textColorClass}>
              <strong>
                {paymentSummary?.data?.processingFee?.total
                  ? formatCurrency(paymentSummary?.data?.processingFee?.total)
                  : '*To be calculated'}
              </strong>
            </span>
          </div>

          <div className={styles.totalAmountContainer}>
            <span
              className={`${styles.totalAmountLabel} ${
                theme === 'dark' && styles.totalAmountLabelDark
              }`}
            >
              Total Amount
            </span>
            <span className={`${styles.totalAmount} ${textColorClass}`}>
              {paymentSummary?.data?.totalAmount
                ? formatCurrency(paymentSummary?.data?.totalAmount)
                : formatCurrency(buildingDetails?.data[0].building.rentAmount || 0)}
            </span>
          </div>
          <hr className={styles.separator} />
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
