import { useLogo } from '../../../../helpers/hooks/LogoContext';
import { useModal } from '../../../../helpers/hooks/modalContext';
import { useTheme } from '../../../../helpers/hooks/ThemeContext';
import styles from '../../../../helpers/Modals/SuccessfulProperty/SuccessfulProperty.module.css';

const SuccessfulCard = () => {
  const { logoUrl, logoLoading } = useLogo();
  const { setModal } = useModal();
  const { theme } = useTheme();
  return (
    <main className={`relative ${styles.main} ${theme === 'dark' && styles.mainDark} `}>
      <button
        className={`${styles.closeIcon} ${theme === 'dark' && styles.closeIconDark} `}
        onClick={() => {
          window.location.reload();
          setModal(null);
        }}
      >
        ✕
      </button>
      <section className={styles.section}>
        <div className={styles.message}>
          {!logoLoading && <img src={logoUrl} className={styles.logo} alt='logo' />}
          <h2 className={`${styles.heading} ${theme === 'dark' && styles.headingDark}`}>
            Card added successfully!
          </h2>
          <p className={styles.messageContent}>You have successfully added the Card.</p>
          <button
            className={styles.closeBtn}
            onClick={() => {
              window.location.reload();
              setModal(null);
            }}
          >
            Complete Payment
          </button>
        </div>
      </section>
    </main>
  );
};
export default SuccessfulCard;
