import React, { useEffect, useState } from 'react';
import styles from './PropertyDetails.module.css';
import locIcon from '../../../assets/pages/dashboard/loc.svg';
import cells from '../../../assets/pages/dashboard/cells.svg';
import calendarIcon from '../../../assets/pages/dashboard/calendar.svg';
import locDark from '../../../assets/pages/dashboard/locDark.svg';
import { formatDateToDisplayUTCLong } from '../../../helpers/utils/utils';
import { buildingDetails } from '../../../helpers/utils/types';
import cellsDark from '../../../assets/pages/dashboard/cellsDark.svg';
import calendarDark from '../../../assets/pages/dashboard/calenderDark.png';
import { getTransactionsStatus } from '../../../auth/_requests';
import { useAuth } from '../../../auth/Auth';
import { useTheme } from '../../../helpers/hooks/ThemeContext';
import smallBuilding from '../../../assets/pages/dashboard/smallBuilding.png';
import largeBuilding from '../../../assets/pages/dashboard/largeBuilding.png';
import home from '../../../assets/pages/dashboard/home.png';
import clsx from 'clsx';
interface PropertyDetailsProps {
  buildingDetails: buildingDetails | undefined;
}

const PropertyDetails: React.FC<PropertyDetailsProps> = ({ buildingDetails }) => {
  const { auth } = useAuth();
  const { theme } = useTheme();
  const [loading, setLoading] = useState(false);
  const [nextPayment, setNextPayment] = useState('');
  const isDark = theme === 'dark';
  useEffect(() => {
    const fetchTransactions = async () => {
      if (buildingDetails) {
        try {
          setLoading(true);

          const response = await getTransactionsStatus(
            String(buildingDetails?.building?.tenantBuildingId),
            auth?.data.token
          );
          const payments = response?.data || [];
          const today = new Date();

          const upcoming = payments.filter((payment) => {
            const paymentDate = new Date(payment.paymentDate);
            // Future and unpaid
            return (
              paymentDate.getTime() >= today.setHours(0, 0, 0, 0) && payment.hasPayment === false
            );
          });

          setNextPayment(upcoming[0].paymentDate);
        } catch (error) {
          console.error('Failed to fetch transactions:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchTransactions();
  }, [auth?.data.token, buildingDetails?.building.tenantBuildingId]);

  return (
    <div className={`${styles.outerContainer} ${isDark && styles.darkCard}`}>
      <div className={styles.propertyDetails}>
        <div className={styles.details}>
          <p className={`${styles.propertyName} ${isDark && styles.propertyWhite}`}>
            {buildingDetails?.building.buildingName || 'N/A'}
          </p>
          <div className={styles.addressUnitContainer}>
            <div className={styles.addressWrapper}>
              <img
                src={isDark ? locDark : locIcon}
                alt='location'
                className={styles.locationIcon}
              />
              <p className={`${styles.propertyAddress} ${isDark && styles.propertyWhite}`}>
                {buildingDetails?.address.fullAddress || 'N/A'}
              </p>
            </div>
            {buildingDetails?.building.unitNumber && (
              <div className={styles.unitWrapper}>
                <img src={isDark ? cellsDark : cells} alt='cells' className={styles.cellsIcon} />
                <p className={`${styles.unitNumber} ${isDark && styles.propertyWhite}`}>
                  Unit Number: {buildingDetails.building.unitNumber}
                </p>
              </div>
            )}
            <p className={`${styles.nextPayment} ${isDark && styles.propertyWhite}`}>
              <img
                src={isDark ? calendarDark : calendarIcon}
                alt='calendar'
                className={styles.calendar}
              />
              Payment due:{' '}
              <span className={styles.dueDate}>
                {loading ? (
                  'Loading'
                ) : (
                  <>{nextPayment ? formatDateToDisplayUTCLong(nextPayment) : 'No payment due'}</>
                )}
              </span>
            </p>
          </div>
        </div>
      </div>
      <img
        src={
          buildingDetails?.building?.unitNumber
            ? Number(buildingDetails?.building?.unitNumber) > 700
              ? largeBuilding // Show large building if unitNo is greater than 700
              : smallBuilding // Show small building if unitNo is less than or equal to 700
            : home // Show home if unitNo is not present
        }
        loading='lazy'
        className={clsx(
          styles.homeImg,
          buildingDetails?.building?.unitNumber
            ? Number(buildingDetails?.building?.unitNumber) > 700
              ? styles.largeBuilding
              : styles.smallBuilding
            : styles.homeImg
        )}
        alt='home'
      />
    </div>
  );
};

export default PropertyDetails;
