import { useRef, useState, ChangeEvent, useEffect } from 'react';
import styles from './VerifyEmail.module.css';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/Auth';
import { sendVerificationCodeEmail, verifyVerificationCodeEmail } from '../../auth/_requests';
import { motion } from 'framer-motion';
import { fadeInUpAnimation } from '../../helpers/utils/types';
import BackgroundImage from '../../helpers/BackgroundImage/BackgroundImage';
import { useLogo } from '../../helpers/hooks/LogoContext';
import { useTheme } from '../../helpers/hooks/ThemeContext';
import { useDomainStatus } from '../../helpers/hooks/DomainStatus';
import BackgroundDarkImage from '../../helpers/BackgroundImage/BackgroundDark';

export function VerifyEmail() {
  const { auth, currentUser, logout } = useAuth();
  const { logoUrl, logoLoading } = useLogo();
  const { theme } = useTheme();
  const { domainStatus } = useDomainStatus();
  const inputRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];
  const [inputValues, setInputValues] = useState(['', '', '', '']);
  const [errorMessage, setErrorMessage] = useState('');
  const [validMessage, setValidMessage] = useState('');
  const codeSentRef = useRef(false);
  const [loading, setLoading] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const isDark = theme === 'dark' && domainStatus === 'valid';

  const navigate = useNavigate();

  const handleInputChange = (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.slice(0, 1);
    if (!/^\d$/.test(value)) {
      value = '';
    }
    const newValues = [...inputValues];
    newValues[index] = value;
    setInputValues(newValues);
    if (value.length === 1 && index < 3) {
      inputRefs[index + 1].current?.focus();
    }
  };

  const handleVerify = async () => {
    const code = inputValues.join('');
    const isValidCode = code.length === 4 && /^\d{4}$/.test(code);
    if (isValidCode) {
      setErrorMessage('');
      setLoading(true);
      try {
        const email = currentUser?.user?.email || '';
        const token = auth?.data.token || '';
        const response = await verifyVerificationCodeEmail(email, token, code);
        if (response.message === 'verification_successful') {
          setValidMessage('Your email has been verified');
          setIsEmailVerified(true);
          setTimeout(() => {
            navigate('/verify-phone');
          }, 2000);
        } else {
          setErrorMessage(response.errorMessage || 'Verification failed');
        }
      } catch (error) {
        setErrorMessage('Error verifying code, Please enter a valid code');
      } finally {
        setLoading(false);
      }
    } else {
      setErrorMessage('Please enter a valid code');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (['+', '-'].includes(e.key)) {
      e.preventDefault();
    } else if (e.key === 'Enter') {
      handleVerify();
    } else if (e.key === 'Backspace') {
      if (inputValues[index] === '' && index > 0) {
        inputRefs[index - 1].current?.focus();
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('Text');
    const digits = pastedData.replace(/\D/g, '').substring(0, 4).split('');

    const newValues = [...inputValues];
    newValues[0] = digits[0] || '';
    newValues[1] = digits[1] || '';
    newValues[2] = digits[2] || '';
    newValues[3] = digits[3] || '';
    setInputValues(newValues);
    inputRefs[digits.length > 0 ? digits.length - 1 : 0].current?.focus();
  };

  useEffect(() => {
    if (currentUser?.user?.email && auth?.data.token && !codeSentRef.current) {
      sendVerificationCodeEmail(currentUser.user.email, 4, auth.data.token);
      codeSentRef.current = true;
    }
  }, [currentUser, auth]);

  return (
    <section className={styles.section}>
      {isDark ? <BackgroundDarkImage /> : <BackgroundImage />}
      <motion.main className={`${styles.main} ${isDark && styles.mainDark}`} {...fadeInUpAnimation}>
        {!logoLoading && <img src={logoUrl} className={styles.logo} alt='logo' />}
        <div className={styles.headingBox}>
          <div className={`${styles.heading} ${isDark && styles.headingDark}`}>Verify Email</div>
          <div className={styles.subHead}>
            Enter the verification code sent to{' '}
            <span className={styles.mail}>{currentUser?.user?.email}</span> to continue.
          </div>
        </div>
        <div className={styles.codeBox}>
          <div className={styles.codeBoxInner}>
            <div className={styles.inputBoxes}>
              {inputRefs.map((ref, index) => (
                <input
                  key={index}
                  type='number'
                  className={`${styles.numberBox} ${isDark && styles.darkInput}`}
                  ref={ref}
                  value={inputValues[index]}
                  onChange={handleInputChange(index)}
                  maxLength={1}
                  min={0}
                  max={9}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  onPaste={handlePaste}
                  onWheel={(e) => (e.target as HTMLInputElement).blur()}
                />
              ))}
            </div>
            {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
            {validMessage && (
              <div className={`${styles.validMessage} ${isDark && styles.validDark}`}>
                {validMessage}
              </div>
            )}
          </div>
          <button
            className={`${styles.disableBtn} ${
              !isEmailVerified && inputValues.every((val) => val !== '') ? styles.submitBtn : ''
            } ${loading && styles.disableBtn} ${isEmailVerified && styles.disableBtn}`}
            onClick={handleVerify}
            disabled={isEmailVerified || inputValues.some((val) => val === '') || loading}
          >
            {!loading && <span className='indicator-label'>Verify</span>}
            {loading && (
              <span className={styles.indicatorProgress} style={{ display: 'block' }}>
                Please wait...
                <span className={styles.spinner}></span>
              </span>
            )}
          </button>
        </div>
        <Link to='/sign-up' className={styles.signUpBox} onClick={() => logout()}>
          Sign up with different email
        </Link>
      </motion.main>
    </section>
  );
}
