import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  type ReactNode,
  type Dispatch,
  type SetStateAction,
} from 'react';
import { getBuildingDetails, getTenantDetails, getTransactionsStatus } from '../../auth/_requests';
import { useAuth } from '../../auth/Auth';
import { buildingDetails, PaymentData } from '../utils/types';

interface IntroData {
  propertyBooked: number;
  leaseExpiry: string | number;
  nextRent: number;
  nextPaymentDate: string;
  isNextPaymentOverdue: boolean;
}

interface PropertyData {
  name: string;
  rnt: string;
  leaseStartDate: string;
  leaseDate: string;
  address: string;
  unitNo: string;
  nextPayment: number;
  rentAmount: number;
  tenantBuildingId: number;
  paymentDates: string[];
  paymentDateFuture: string[];
  nextBillingDate: string | null;
  tenantSubscriptions?: [];
}

interface DashboardContextProps {
  introData: IntroData | null;
  setIntroData: Dispatch<SetStateAction<IntroData | null>>;
  propertyData: PropertyData[] | null;
  setPropertyData: Dispatch<SetStateAction<PropertyData[] | null>>;
  updateCounter: number;
  setUpdateCounter: Dispatch<SetStateAction<number>>;
  paymentStatus: Record<number, PaymentData[]>;
  setPaymentStatus: Dispatch<SetStateAction<Record<number, PaymentData[]>>>;
  loading: boolean;
  error: Error | null;
}

const DashboardContext = createContext<DashboardContextProps | undefined>(undefined);

export const DashboardProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [introData, setIntroData] = useState<IntroData | null>(null);
  const [propertyData, setPropertyData] = useState<PropertyData[] | null>(null);
  const [updateCounter, setUpdateCounter] = useState<number>(0);
  const { auth } = useAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const [paymentStatus, setPaymentStatus] = useState<Record<number, PaymentData[]>>({});
  const getEarliestUnpaidPaymentDue = (
    paymentStatus: Record<number, PaymentData[]>
  ): { date: Date; buildingId: number } | null => {
    let earliestPayment: { date: Date; buildingId: number } | null = null;

    //Loop through each building's payment status
    for (const buildingId in paymentStatus) {
      const payments = paymentStatus[buildingId];

      // Filter for future unpaid payments
      payments.forEach((payment) => {
        const paymentDate = new Date(payment.paymentDate);
        if (paymentDate.getTime() > new Date().setHours(0, 0, 0, 0) && !payment.hasPayment) {
          // Check if this is the earliest unpaid payment
          if (!earliestPayment || paymentDate < earliestPayment.date) {
            earliestPayment = { date: paymentDate, buildingId: Number(buildingId) };
          }
        }
      });
    }

    // Return the buildingId of the earliest unpaid payment due, or null if none found
    return earliestPayment ?? null;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!auth?.data?.token) return;

      try {
        setLoading(true);
        const tenantResponse = await getTenantDetails(auth.data.token);
        const { isEmailVerified, isPhoneVerified } = tenantResponse.data.user;
        const isIdentityVerified = tenantResponse.data.tenant.isIdentityVerified;
        const isVerified =
          isEmailVerified === 1 && isPhoneVerified === 1 && isIdentityVerified === 1;

        if (!isVerified) {
          setLoading(false);
          return;
        }
        const [buildingResponse] = await Promise.all([getBuildingDetails(auth.data.token)]);

        const properties: PropertyData[] = buildingResponse.data.map(
          (property: buildingDetails) => {
            return {
              name: property.building.buildingName,
              leaseStartDate: property.building.leaseStartDate,
              leaseDate: property.building.leaseEndDate,
              address: property.address.fullAddress,
              unitNo: property.building.unitNumber,
              nextPayment: property.building.dueDate,
              rentAmount: property.building.rentAmount,
              tenantBuildingId: Number(property.building.tenantBuildingId),
              rnt: property.building.payNumber,
              paymentDates: property.building.paymentDates,
              paymentDateFuture: property.building.paymentDates.filter(
                (date) => new Date(date) > new Date()
              ),
              tenantSubscriptions: property.tenantSubscriptions,
              nextBillingDate: property?.building?.nextBillingDate,
            };
          }
        );

        const paymentStatusPromises = properties.map((property) =>
          allPaymentStatus(String(property.tenantBuildingId))
        );
        const paymentStatuses = await Promise.all(paymentStatusPromises);
        const paymentStatusMap = properties.reduce((acc, property, index) => {
          acc[property.tenantBuildingId] = paymentStatuses[index] || [];
          return acc;
        }, {} as Record<number, PaymentData[]>);
        setPaymentStatus(paymentStatusMap);

        const earliestPayment = getEarliestUnpaidPaymentDue(paymentStatusMap);

        const nextRent = earliestPayment
          ? properties.find((p) => p.tenantBuildingId === earliestPayment.buildingId)?.rentAmount ??
            0
          : 0;
        const leaseExpiry = earliestPayment
          ? properties.find((p) => p.tenantBuildingId === earliestPayment.buildingId)?.leaseDate ??
            0
          : 0;

        const nextPaymentDate = earliestPayment
          ? earliestPayment.date.toISOString().split('T')[0]
          : '';
        const intro: IntroData = {
          propertyBooked: tenantResponse.data.tenantBuildings.length,
          leaseExpiry,
          nextRent,
          nextPaymentDate,
          isNextPaymentOverdue: false,
        };

        setIntroData(intro);
        setPropertyData(properties);
      } catch (err) {
        setError(err as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [auth, updateCounter]);

  const allPaymentStatus = async (tenantBuildingId: string) => {
    try {
      const response = await getTransactionsStatus(tenantBuildingId, auth?.data.token);
      if (!response?.data?.length) return [];

      return response.data.map((transaction) => ({
        paymentDate: transaction.paymentDate,
        hasPayment: transaction.hasPayment,
        amountPaid: 0,
        responseTimestamp: '',
        rentAmount: '',
        processingFee: '',
        fixedProcessingFee: '',
      }));
    } catch (error) {
      console.error('Error fetching transactions status', error);
      return [];
    }
  };

  return (
    <DashboardContext.Provider
      value={{
        introData,
        setIntroData,
        propertyData,
        setPropertyData,
        updateCounter,
        setUpdateCounter,
        loading,
        error,
        paymentStatus,
        setPaymentStatus,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboard = () => {
  const context = useContext(DashboardContext);
  if (context == null) {
    throw new Error('useDashboard must be used within a DashboardProvider');
  }
  return context;
};
