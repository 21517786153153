import { useState } from 'react';
import styles from './ResetPasswordModal.module.css';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { ResetPassword } from '../../../auth/_requests';
import passwordOff from '../../../assets/pages/login/password-off.svg';
import passwordOn from '../../../assets/pages/login/password-on.svg';
import { PasswordInput } from '../../../helpers/PasswordInput/PasswordInput';
import { useLogo } from '../../../helpers/hooks/LogoContext';
import toast, { Toaster } from 'react-hot-toast';
import { useModal } from '../../hooks/modalContext';
import { useAuth } from '../../../auth/Auth';
import { useTheme } from '../../hooks/ThemeContext';
import darkLogo from '../../../assets/helpers/navbar/tenantpay-white.png';
import { useDomainStatus } from '../../hooks/DomainStatus';

const loginSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .min(8, 'Minimum 8 characters')
    .max(15, 'Maximum 15 characters')
    .matches(/[a-z]/, 'Must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Must contain at least one uppercase letter')
    .matches(/[0-9]/, 'Must contain at least one number')
    .matches(/[\W_]/, 'Must contain at least one special character')
    .matches(/^\S*$/, 'Password cannot contain spaces')
    .required('Current Password is required'),
  password: Yup.string()
    .min(8, 'Minimum 8 characters')
    .max(15, 'Maximum 15 characters')
    .matches(/[a-z]/, 'Must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Must contain at least one uppercase letter')
    .matches(/[0-9]/, 'Must contain at least one number')
    .matches(/[\W_]/, 'Must contain at least one special character')
    .matches(/^\S*$/, 'Password cannot contain spaces')
    .required('Password is required'),

  changePassword: Yup.string()
    .min(3, 'Minimum 8 characters')
    .max(50, 'Maximum 15 characters')
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
});

const initialValues = {
  oldPassword: '',
  password: '',
  changePassword: '',
};

export function ResetPasswordModal() {
  const { setModal } = useModal();
  const { domainStatus } = useDomainStatus();
  const { theme } = useTheme();
  const { auth } = useAuth();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState(false);
  const { logoUrl, logoLoading } = useLogo();
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const requestBody = {
          passwordData: {
            oldPassword: values.oldPassword,
            newPassword: values.password,
            confirmPassword: values.changePassword,
          },
        };
        const response = await ResetPassword(auth?.data.token, requestBody);
        if (response?.message === 'success') {
          toast.remove();
          toast.success('Password changed successfully!', {
            duration: 6000,
            style: {
              maxWidth: '327px',
              background: `${theme === 'dark' && '#000'}`,
              color: `${theme === 'dark' ? '#f5f5f5' : `#020b28`}`,
              border: `${theme === 'dark' && '1px solid #f5f5f5'}`,
            },
          });
          setModal(null);
        } else {
          toast.remove();
          if (response?.message === 'invalid_password') {
            toast.error('The current password is incorrect. Please check it and try again.', {
              duration: 5000,
              style: {
                maxWidth: '327px',
                background: `${theme === 'dark' && '#000'}`,
                color: `${theme === 'dark' ? '#f5f5f5' : `#020b28`}`,
                border: `${theme === 'dark' && '1px solid #f5f5f5'}`,
              },
            });
          }
        }
      } catch (error) {
        console.error(error);
        setStatus('The current password is incorrect. Please check it and try again.');
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  return (
    <section className={styles.section}>
      <Toaster position='top-center' reverseOrder={false} />
      <button
        className={`${styles.cross} ${theme === 'dark' && styles.crossDark} `}
        onClick={() => setModal(null)}
      >
        ✕
      </button>
      <main className={`${styles.main} ${theme === 'dark' && styles.mainDark}`}>
        {!logoLoading && (
          <img
            src={theme === 'dark' && domainStatus === 'invalid' ? darkLogo : logoUrl}
            className={styles.logo}
            alt='logo'
          />
        )}
        <div className={styles.headingBox}>
          <div className={`${styles.heading} ${theme === 'dark' && styles.headingDark}`}>
            Reset Your Password
          </div>
          <div className={styles.subHead}>Set a new password for your account</div>
        </div>
        <form className={styles.form} onSubmit={formik.handleSubmit} noValidate>
          <div className={styles.formUpper}>
            <div className={styles.formBoxes}>
              <div className={styles.formBoxesInner}>
                {formik.status && (
                  <div className={styles.statusBox}>
                    <div className={styles.status}>{formik.status}</div>
                  </div>
                )}

                <div
                  className={styles.inputOuter}
                  title='Enter your current password to create a new password'
                >
                  <div className={styles.label}>
                    Current Password<span className={styles.mandatory}>*</span>
                  </div>
                  <div
                    className={clsx(
                      formik.touched.oldPassword && formik.errors.oldPassword
                        ? styles.isInvalid
                        : '',
                      formik.touched.oldPassword && !formik.errors.oldPassword ? styles.valid : '',
                      styles.emailInput
                    )}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                      }
                    }}
                  >
                    <PasswordInput
                      value={formik.values.password}
                      onChange={(newPassword: string) => {
                        formik.setFieldValue('oldPassword', newPassword);
                      }}
                      onBlur={formik.handleBlur('oldPassword')}
                      showPassword={oldPassword}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                          formik.handleSubmit();
                        }
                      }}
                      isLogin={true}
                      placeholder='Enter current password'
                    />
                    <button
                      onClick={(event) => {
                        event.preventDefault();
                        setOldPassword(!oldPassword);
                      }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                        }
                      }}
                    >
                      {!oldPassword && <img src={passwordOff} alt='passwordOff' />}
                      {oldPassword && <img src={passwordOn} alt='passwordOn' />}
                    </button>
                  </div>
                  {formik.touched.oldPassword && formik.errors.oldPassword && (
                    <div className={styles.emailError}>
                      <span>{formik.errors.oldPassword}</span>
                    </div>
                  )}
                </div>
                <div
                  className={styles.inputOuter}
                  title='Password must be 8-15 characters long, including at least one uppercase letter, one lowercase letter, one number, one special character, and no spaces.'
                >
                  <div className={styles.label}>
                    New Password<span className={styles.mandatory}>*</span>
                  </div>
                  <div
                    className={clsx(
                      formik.touched.password && formik.errors.password ? styles.isInvalid : '',
                      formik.touched.password && !formik.errors.password ? styles.valid : '',
                      styles.emailInput
                    )}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                      }
                    }}
                  >
                    <PasswordInput
                      value={formik.values.password}
                      onChange={(newPassword: string) => {
                        formik.setFieldValue('password', newPassword);
                      }}
                      onBlur={formik.handleBlur('password')}
                      showPassword={showPassword}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                          formik.handleSubmit();
                        }
                      }}
                      isLogin={true}
                      placeholder='Enter new password'
                    />
                    <button
                      onClick={(event) => {
                        event.preventDefault();
                        setShowPassword(!showPassword);
                      }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                        }
                      }}
                    >
                      {!showPassword && <img src={passwordOff} alt='passwordOff' />}
                      {showPassword && <img src={passwordOn} alt='passwordOn' />}
                    </button>
                  </div>
                  {formik.touched.password && formik.errors.password && (
                    <div className={styles.emailError}>
                      <span>{formik.errors.password}</span>
                    </div>
                  )}
                </div>
                <div className={styles.inputOuter} title='Must match the password exactly'>
                  <div className={styles.label}>
                    Confirm New Password<span className={styles.mandatory}>*</span>
                  </div>
                  <div
                    className={clsx(
                      formik.touched.changePassword && formik.errors.changePassword
                        ? styles.isInvalid
                        : '',
                      formik.touched.changePassword && !formik.errors.changePassword
                        ? styles.valid
                        : '',
                      styles.passwordInput
                    )}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                      }
                    }}
                  >
                    <PasswordInput
                      value={formik.values.changePassword}
                      onChange={(newPassword: string) => {
                        formik.setFieldValue('changePassword', newPassword);
                      }}
                      onBlur={formik.handleBlur('changePassword')}
                      showPassword={showConfirmPassword}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                          formik.handleSubmit();
                        }
                      }}
                      isLogin={true}
                      placeholder='Confirm new password'
                    />
                    <button
                      onClick={(event) => {
                        event.preventDefault();
                        setShowConfirmPassword(!showConfirmPassword);
                      }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                        }
                      }}
                    >
                      {!showConfirmPassword && <img src={passwordOff} alt='passwordOff' />}
                      {showConfirmPassword && <img src={passwordOn} alt='passwordOn' />}
                    </button>
                  </div>
                  {formik.touched.changePassword && formik.errors.changePassword && (
                    <div className={styles.emailError}>
                      <div className='fv-help-block'>
                        <span>{formik.errors.changePassword}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='d-grid'>
            <button
              type='submit'
              className={`${styles.submitBtn} ${
                (formik.isSubmitting || !formik.isValid) && styles.disableBtn
              } ${(formik.isSubmitting || formik.isValid) && styles.btn}`}
            >
              {!loading && <span className='indicator-label'>Change Password</span>}
              {loading && (
                <span className={styles.indicatorProgress} style={{ display: 'block' }}>
                  Please wait...
                  <span className={styles.spinner}></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </main>
    </section>
  );
}
