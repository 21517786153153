import styles from './NotFound.module.css';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { fadeInUpAnimation } from '../../helpers/utils/types';
import BackgroundImage from '../../helpers/BackgroundImage/BackgroundImage';
import SVGAsset from '../../helpers/SVG/SVGAsset';
import { useTheme } from '../../helpers/hooks/ThemeContext';
import { useDomainStatus } from '../../helpers/hooks/DomainStatus';
import BackgroundDarkImage from '../../helpers/BackgroundImage/BackgroundDark';

const NotFound = () => {
  const navigate = useNavigate();
  const { theme } = useTheme();
  const { domainStatus } = useDomainStatus();
  const isDark = theme === 'dark' && domainStatus === 'valid';
  const handleBackBtn = () => {
    navigate('/dashboard');
  };
  return (
    <section className={styles.section}>
      {isDark ? <BackgroundDarkImage /> : <BackgroundImage />}
      <motion.main className={`${styles.main} ${isDark && styles.mainDark}`} {...fadeInUpAnimation}>
        <SVGAsset isPending={false} className={styles.errorIcon} />
        <div className={styles.headingBox}>
          <div className={`${styles.heading} ${isDark && styles.headingDark}`}>Page Not Found</div>
          <div className={styles.subHead}>
            We couldn't find what you're looking for.
            <p>We appreciate your understanding.</p>
          </div>
        </div>
        <button className={styles.backBtn} onClick={handleBackBtn}>
          Return to Home
        </button>
      </motion.main>
    </section>
  );
};
export { NotFound };
