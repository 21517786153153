import React, { useEffect } from 'react';
import styles from './Modal.module.css';
import ClickAwayListener from 'react-click-away-listener';
import { useModal } from '../hooks/modalContext';
import { type ModalItem } from '../utils/types';

interface ModalProps {
  children: React.ReactNode;
  background?: string;
  id: ModalItem;
  closeOnClickAway?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  children,
  id,
  background = 'bg-[rgba(128,132,147,0.8)]',
  closeOnClickAway = true,
}: ModalProps) => {
  const { modal, setModal } = useModal();
  const isOpen = modal === id;

  const onClose = () => {
    setModal(null);
  };
  useEffect(() => {
    if (isOpen) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = '';
    }
    return () => {
      document.documentElement.style.overflow = '';
    };
  }, [isOpen]);
  return (
    <>
      {isOpen && (
        <div className={styles.modalWrapper}>
          <div className={`${styles.modalBackground} ${background}`} />
          {closeOnClickAway ? (
            <ClickAwayListener onClickAway={onClose}>
              <div className={styles.modalContent}>{children}</div>
            </ClickAwayListener>
          ) : (
            <div className={styles.modalContent}>{children}</div>
          )}
        </div>
      )}
    </>
  );
};

export default Modal;
