import styles from './TransactionDetail.module.css';
import { useModal } from '../../hooks/modalContext';
import master from '../../../assets/pages/payment/mastercard.png';
import visa from '../../../assets/pages/payment/visa.png';
import amex from '../../../assets/pages/payment/amex.png';
import { formatDateToDisplayUTCLong } from '../../utils/utils';
import { useState } from 'react';
import clsx from 'clsx';
import { useTheme } from '../../hooks/ThemeContext';

const TransactionDetail = () => {
  const { modalState, setModal } = useModal();
  const { theme } = useTheme();
  const { transactionDetails } = modalState || {};
  const [isOpen, setOpen] = useState(false);
  const dateTimeString = transactionDetails?.transactionDate;
  const dateObj = new Date(dateTimeString || '');
  const date = dateObj.toISOString().split('T')[0];
  const time = dateObj.toISOString().split('T')[1].split('.')[0];
  const isDark = theme === 'dark';
  const getCardImage = (cardCompany: string) => {
    switch (cardCompany?.toLowerCase()) {
      case 'mastercard':
        return master;
      case 'visa':
        return visa;
      case 'amex':
        return amex;
      default:
        return visa;
    }
  };
  const getCardName = (cardCompany: string) => {
    switch (cardCompany.toLowerCase()) {
      case 'mastercard':
        return 'Mastercard';
      case 'visa':
        return 'Visa Card';
      case 'amex':
        return 'American Express Card';
      default:
        return 'Visa Card';
    }
  };
  return (
    <main className={styles.main}>
      <div className={`${styles.header} ${theme === 'dark' && styles.headerDark}`}>
        <h1 className={styles.title}>Transaction Details</h1>
        <button className={styles.cross} onClick={() => setModal(null)}>
          ✕
        </button>
      </div>

      <section className={clsx(styles.section, isDark && styles.sectionDark)}>
        <div className={styles.dateOuter}>
          <div className={styles.label}>Payment Status:</div>
          <div
            className={`${styles.date} ${
              (transactionDetails?.status === 'Pending' ||
                transactionDetails?.status === 'Failed') &&
              styles.pendingBg
            } ${transactionDetails?.status === 'Success' && styles.completedBg}`}
          >
            {transactionDetails?.status}
          </div>
        </div>
        <div className={styles.dateOuter}>
          <div className={styles.label}>Payment Date:</div>
          <div className={`${styles.date} ${isDark && styles.dateDark}`}>
            {formatDateToDisplayUTCLong(date)}
          </div>
        </div>
        <div className={styles.dateOuter}>
          <div className={styles.label}>Payment Time:</div>
          <div className={`${styles.date} ${isDark && styles.dateDark}`}>{`${time} UTC`}</div>
        </div>
        <div className={styles.dateOuter}>
          <div className={styles.label}>Payment Mode:</div>
          <div className={`${styles.date} ${isDark && styles.dateDark}`}>
            {`${getCardName(transactionDetails?.cardCompany || '')}`}
          </div>
        </div>
        <div className={styles.dateOuter}>
          <div className={styles.label}>Card Type:</div>
          <div className={styles.imageOuter}>
            <img
              src={getCardImage(transactionDetails?.cardCompany || '')}
              alt='card'
              className={styles.cardImage}
            />
          </div>
        </div>

        {isOpen && (
          <>
            <div className={styles.dateOuter}>
              <div className={styles.label}>Rent Amount:</div>
              <div
                className={`${styles.date} ${isDark && styles.dateDark}`}
              >{`$${transactionDetails?.rentAmount.toLocaleString()}`}</div>
            </div>
            {transactionDetails?.fixedFee !== '0.00' && (
              <div className={styles.dateOuter}>
                <div className={styles.label}>Fixed Processing Fee:</div>
                <div
                  className={`${styles.date} ${isDark && styles.dateDark}`}
                >{`$${transactionDetails?.fixedFee.toLocaleString()}`}</div>
              </div>
            )}
            {transactionDetails?.percentageProcessingFee !== '0.00' && (
              <div className={styles.dateOuter}>
                <div
                  className={styles.label}
                >{`Processing Fee (${transactionDetails?.percentageRate}%):`}</div>
                <div
                  className={`${styles.date} ${isDark && styles.dateDark}`}
                >{`$${transactionDetails?.percentageProcessingFee}`}</div>
              </div>
            )}
            <div className={styles.dateOuter}>
              <div className={styles.label}>Total Processing Fee:</div>
              <div
                className={`${styles.date} ${isDark && styles.dateDark}`}
              >{`$${transactionDetails?.processingFee.toLocaleString()}`}</div>
            </div>
          </>
        )}
        <hr className={`${styles.line}`} />
        <div className={styles.dateOuter}>
          <div className={styles.labelBold}>Total Amount Paid:</div>
          <div className={styles.imageOuter}>
            <div
              className={`${styles.rent} ${isDark && styles.dateDark}`}
            >{`$${transactionDetails?.amountPaid.toLocaleString()}`}</div>
          </div>
        </div>
        {!isOpen ? (
          <button
            className={styles.summaryBtn}
            onClick={() => {
              setOpen(!isOpen);
            }}
          >
            {'View Details'}
          </button>
        ) : (
          <button
            className={styles.summaryBtn}
            onClick={() => {
              setOpen(!isOpen);
            }}
          >
            {'Hide Details'}
          </button>
        )}
      </section>
      <div className={clsx(styles.footer, isDark && styles.footerDark)}>
        <div></div>
        <button className={styles.btn} onClick={() => setModal(null)}>
          Close
        </button>
      </div>
    </main>
  );
};

export default TransactionDetail;
