import { Suspense, lazy, useEffect, useState } from 'react';
import Navbar from '../../helpers/Navbar/Navbar';
import { Spinner } from '../../helpers/Spinner/Spinner';
import { UserProfileProvider } from '../../helpers/hooks/userProfile';
import { tenantDetails } from '../../helpers/utils/types';
import bgImage from '../../assets/pages/dashboard/bg4.jpg';
import darkBg from '../../assets/pages/dashboard/bg3black.jpg';
import { useTheme } from '../../helpers/hooks/ThemeContext';
import Footer from '../../helpers/Footer/Footer';
const Form = lazy(() => import('./Form/Form'));
const Profile: React.FC<{ tenant?: tenantDetails }> = ({ tenant }) => {
  const { theme } = useTheme();
  const [backgroundUrl, setBackgroundUrl] = useState<string>();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const selectedBg = theme === 'dark' ? darkBg : bgImage;
    const img = new Image();
    img.src = selectedBg;
    img.onload = () => {
      setBackgroundUrl(selectedBg);
    };
  }, [theme]);

  if (!backgroundUrl) {
    return <Spinner />;
  }

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${backgroundUrl})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundAttachment: 'fixed',
          minHeight: '100vh',
          paddingTop: '100px',
          paddingBottom: '50px',
          color: '#fff',
          transition: 'background-image 0.5s ease-in-out',
        }}
      >
        <Navbar />
        <UserProfileProvider>
          <Suspense fallback={<Spinner widthFull='!bg-transparent' />}>
            <Form tenant={tenant} />
          </Suspense>
        </UserProfileProvider>
      </div>
      <Footer />
    </>
  );
};

export { Profile };
