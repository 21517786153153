import { useState, ChangeEvent, useRef } from 'react';
import styles from './PasswordInput.module.css';
import { useTheme } from '../hooks/ThemeContext';

interface Props {
  value: string;
  showPassword: boolean;
  onChange: (password: string) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  isLogin?: boolean;
  placeholder: string;
}

const PasswordInput = ({
  value,
  onChange,
  onBlur,
  onKeyDown,
  showPassword,
  placeholder,
  isLogin = false,
}: Props) => {
  const [actualPassword, setActualPassword] = useState(value);
  const { theme } = useTheme();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    setActualPassword(newPassword);
    onChange(newPassword);
  };

  return (
    <input
      type={showPassword ? 'text' : 'password'}
      placeholder={placeholder}
      value={actualPassword}
      onChange={handleChange}
      autoComplete='off'
      className={`${styles.passwordInputBox} ${theme === 'dark' && isLogin && styles.darkInput}`}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      ref={inputRef}
    />
  );
};

export { PasswordInput };
