import { useState } from 'react';
import styles from './SignUp.module.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { getUserByToken, register } from '../../auth/_requests';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/Auth';
import passwordOff from '../../assets/pages/login/password-off.svg';
import passwordOn from '../../assets/pages/login/password-on.svg';
import { Checkbox } from '../../helpers/Checkbox/Checkbox';
import { PasswordInput } from '../../helpers/PasswordInput/PasswordInput';
import { motion } from 'framer-motion';
import { fadeInUpAnimation } from '../../helpers/utils/types';
import { useLogo } from '../../helpers/hooks/LogoContext';
import { formatPhoneNumber } from '../../helpers/utils/utils';
import { useModal } from '../../helpers/hooks/modalContext';
import { isPhoneNumber } from 'class-validator';
import { useTheme } from '../../helpers/hooks/ThemeContext';
import { useDomainStatus } from '../../helpers/hooks/DomainStatus';
import { Button, Tooltip, Whisper } from 'rsuite';
import info from '../../assets/pages/payment/about.png';

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  phone: '',
  changepassword: '',
  phoneExtension: '1',
  acceptTerms: false,
};

const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(2, 'Minimum 2 characters')
    .max(50, 'Maximum 50 characters')
    .required('First name is required')
    .test(
      'no-only-spaces',
      'First name must contain at least one letter or number',
      (value) => typeof value === 'string' && value.trim().length > 0
    )
    .matches(/^[a-zA-Z0-9 ]*$/, 'First name must only include letters and numbers'),
  lastname: Yup.string()
    .min(1, 'Minimum 1 character')
    .max(50, 'Maximum 50 characters')
    .required('Last name is required')
    .test(
      'no-only-spaces',
      'Last name must contain at least one letter or number',
      (value) => typeof value === 'string' && value.trim().length > 0
    )
    .matches(/^[a-zA-Z0-9 ]*$/, 'Last name must only include letters and numbers'),
  email: Yup.string()
    .email('Invalid email format')
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Email is required'),
  password: Yup.string()
    .min(8, 'Minimum 8 characters')
    .max(15, 'Maximum 15 characters')
    .matches(/[a-z]/, 'Must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Must contain at least one uppercase letter')
    .matches(/[0-9]/, 'Must contain at least one number')
    .matches(/[\W_]/, 'Must contain at least one special character')
    .matches(/^\S*$/, 'Password cannot contain spaces')
    .required('Password is required'),
  phone: Yup.string()
    .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits')
    .required('Phone number is required'),
  changepassword: Yup.string()
    .min(3, 'Minimum 8 characters')
    .max(50, 'Maximum 15 characters')
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
});
export function SignUp() {
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();
  const { setModal } = useModal();
  const { logoUrl, logoLoading } = useLogo();
  const { theme } = useTheme();
  const { domainStatus } = useDomainStatus();
  const [showPassword, setShowPassword] = useState(false);
  const [showconfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const [isInValid, setInValid] = useState(false);
  const isDark = theme === 'dark' && domainStatus === 'valid';
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if (!values.acceptTerms) {
        setStatus('Please agree to the terms and conditions to proceed.');
        setSubmitting(false);
        return;
      }
      setLoading(true);
      try {
        const { data: auth } = await register(
          values.email,
          values.firstname,
          values.lastname,
          values.phone,
          values.password,
          values.phoneExtension
        );
        saveAuth(auth);
        const { data: user } = await getUserByToken(auth.data.token);
        setCurrentUser(user);
        localStorage.setItem('isSignUp', 'true');
        navigate('/verify-email');
      } catch (error: unknown) {
        console.error(error);
        saveAuth(undefined);
        if (error instanceof Error) {
          if (error.message === 'email already registered') {
            setStatus('This email is linked to an existing account, please login.');
          } else {
            setStatus('The registration details are incorrect');
          }
        } else {
          setStatus('The registration details are incorrect');
        }
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  return (
    <section className={styles.section}>
      <motion.main className={`${styles.main} ${isDark && styles.mainDark}`} {...fadeInUpAnimation}>
        {!logoLoading && <img src={logoUrl} className={styles.logo} alt='logo' />}
        <div className={styles.headingBox}>
          <div className={`${styles.heading} ${isDark && styles.headingDark}`}>
            Create a New Account
          </div>
          <div className={styles.subHead}>
            Elevate your rental payment game with TenantPay – fast, easy, and reliable.
          </div>
        </div>
        {formik.status && (
          <div className={styles.statusBox}>
            <div className={styles.status}>{formik.status}</div>
          </div>
        )}
        <div className={styles.formOuter}>
          <form
            className={styles.form}
            noValidate
            id='kt_login_signup_form'
            onSubmit={formik.handleSubmit}
          >
            <div className={styles.nameBox}>
              <div className={styles.firstName} title='Enter your first name'>
                <label className={styles.label}>
                  First Name<span className={styles.mandatory}>*</span>
                </label>
                <input
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('firstname')}
                  placeholder='Jonathan'
                  className={clsx(
                    formik.touched.firstname && formik.errors.firstname ? styles.isInvalid : '',
                    formik.touched.firstname && !formik.errors.firstname ? styles.valid : '',
                    `${styles.inputBox} ${isDark && styles.darkInput}`
                  )}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      formik.handleSubmit();
                    }
                  }}
                />

                {formik.touched.firstname && formik.errors.firstname && (
                  <div className={styles.error}>
                    <span>{formik.errors.firstname}</span>
                  </div>
                )}
              </div>
              <div className={styles.lastName} title='Enter your last name'>
                <label className={styles.label}>
                  Last Name<span className={styles.mandatory}>*</span>
                </label>
                <input
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('lastname')}
                  placeholder='Smith'
                  className={clsx(
                    formik.touched.lastname && formik.errors.lastname ? styles.isInvalid : '',
                    formik.touched.lastname && !formik.errors.lastname ? styles.valid : '',
                    `${styles.inputBox} ${isDark && styles.darkInput}`
                  )}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      formik.handleSubmit();
                    }
                  }}
                />
                {formik.touched.lastname && formik.errors.lastname && (
                  <div className={styles.error}>
                    <span>{formik.errors.lastname}</span>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.nameBox}>
              <div className={styles.firstName} title='Enter your email to register'>
                <label className={styles.label}>
                  Email<span className={styles.mandatory}>*</span>
                </label>
                <input
                  type='email'
                  autoComplete='off'
                  {...formik.getFieldProps('email')}
                  placeholder='info@tenantpay.com'
                  className={clsx(
                    formik.touched.email && formik.errors.email ? styles.isInvalid : '',
                    formik.touched.email && !formik.errors.email ? styles.valid : '',
                    `${styles.inputBox} ${isDark && styles.darkInput}`
                  )}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      formik.handleSubmit();
                    }
                  }}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className={styles.error}>
                    <span>{formik.errors.email}</span>
                  </div>
                )}
              </div>
              <div className={styles.lastName}>
                <div className={styles.tooltipWrap}>
                  <label className={styles.label}>
                    Phone<span className={styles.mandatory}>*</span>
                  </label>
                  <Whisper
                    trigger='hover'
                    placement={'right'}
                    controlId={`control-id-top`}
                    speaker={
                      <Tooltip>
                        <p>
                          Your phone number will be used for Multi-Factor Authentication (MFA) to
                          send verification codes and will also be stored in your profile.
                        </p>
                      </Tooltip>
                    }
                  >
                    <div className={'signUpBtn'}>
                      <Button appearance='subtle' className={styles.btnImg}>
                        <img src={info} className={styles.infoIcon} alt='info' />
                      </Button>
                    </div>
                  </Whisper>
                </div>
                <div
                  className={clsx(
                    formik.touched.phone && (formik.errors.phone || isInValid)
                      ? styles.isInvalid
                      : '',
                    formik.touched.phone && !formik.errors.phone && !isInValid ? styles.valid : '',
                    `${styles.phoneInputBox} ${isDark && styles.darkInput}`
                  )}
                >
                  <div className={styles.dropdown}>+1</div>
                  <input
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('phone')}
                    className={`${styles.phoneInput} ${isDark && styles.darkInput}`}
                    placeholder='(639) 212-4561'
                    maxLength={14}
                    value={formatPhoneNumber(formik.values.phone)}
                    onChange={(e) => {
                      const formattedPhone = e.target.value.replace(/[^\d]/g, '').slice(0, 10);
                      formik.setFieldValue('phone', formattedPhone);
                      if (formattedPhone.length === 10) {
                        if (!isPhoneNumber(formattedPhone, 'CA')) {
                          setInValid(true);
                        } else {
                          setInValid(false);
                          formik.setFieldError('phone', '');
                        }
                      } else {
                        setInValid(false);
                      }
                    }}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                        formik.handleSubmit();
                      }
                    }}
                  />
                </div>
                {formik.touched.phone && formik.errors.phone && (
                  <div className={styles.error}>
                    <span>{formik.errors.phone}</span>
                  </div>
                )}
                {formik.touched.phone && isInValid && (
                  <div className={styles.error}>
                    <span>{'Please provide a valid Canadian phone number'}</span>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.nameBox}>
              <div
                className={styles.firstName}
                title='Password must be 8-15 characters long, including at least one uppercase letter, one lowercase letter, one number, one special character, and no spaces.'
              >
                <label className={styles.label}>
                  Password<span className={styles.mandatory}>*</span>
                </label>
                <div
                  className={clsx(
                    formik.touched.password && formik.errors.password ? styles.isInvalid : '',
                    formik.touched.password && !formik.errors.password ? styles.valid : '',
                    styles.passwordWrapper
                  )}
                >
                  <PasswordInput
                    value={formik.values.password}
                    onChange={(newPassword: string) => {
                      formik.setFieldValue('password', newPassword);
                    }}
                    onBlur={formik.handleBlur('password')}
                    showPassword={showPassword}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                        formik.handleSubmit();
                      }
                    }}
                    placeholder='Enter a password'
                  />

                  <button
                    onClick={(event) => {
                      event.preventDefault();
                      setShowPassword(!showPassword);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                      }
                    }}
                  >
                    {!showPassword && <img src={passwordOff} alt='passwordOff' />}
                    {showPassword && <img src={passwordOn} alt='passwordOn' />}
                  </button>
                </div>
                {formik.touched.password && formik.errors.password && (
                  <div className={styles.error}>
                    <span>{formik.errors.password}</span>
                  </div>
                )}
              </div>
              <div className={styles.firstName} title='Must match the password exactly'>
                <label className={styles.label}>
                  Confirm Password<span className={styles.mandatory}>*</span>
                </label>
                <div
                  className={clsx(
                    formik.touched.changepassword && formik.errors.changepassword
                      ? styles.isInvalid
                      : '',
                    formik.touched.changepassword && !formik.errors.changepassword
                      ? styles.valid
                      : '',
                    styles.passwordWrapper
                  )}
                >
                  <PasswordInput
                    value={formik.values.changepassword}
                    onChange={(newPassword: string) => {
                      formik.setFieldValue('changepassword', newPassword);
                    }}
                    onBlur={formik.handleBlur('changepassword')}
                    showPassword={showconfirmPassword}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                        formik.handleSubmit();
                      }
                    }}
                    placeholder='Confirm your password'
                  />

                  <button
                    onClick={(event) => {
                      event.preventDefault();
                      setShowConfirmPassword(!showconfirmPassword);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                      }
                    }}
                  >
                    {!showconfirmPassword && <img src={passwordOff} alt='passwordOff' />}
                    {showconfirmPassword && <img src={passwordOn} alt='passwordOn' />}
                  </button>
                </div>
                {formik.touched.changepassword && formik.errors.changepassword && (
                  <div className={styles.error}>
                    <span>{formik.errors.changepassword}</span>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.privacyBox}>
              <Checkbox
                checked={formik.values.acceptTerms}
                onChange={() => formik.setFieldValue('acceptTerms', !formik.values.acceptTerms)}
              />
              <div className={styles.termsContent}>
                By creating an account you agree to the{' '}
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setModal('terms-services');
                  }}
                  className={styles.blueText}
                >
                  Terms of Service
                </button>{' '}
                and{' '}
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setModal('privacy-policy');
                  }}
                  className={styles.blueText}
                >
                  Privacy Policy
                </button>
              </div>
              {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                <div className={styles.error}>
                  <span role='alert'>{formik.errors.acceptTerms}</span>
                </div>
              )}
            </div>
            <div className='text-center'>
              <button
                type='submit'
                disabled={isInValid}
                className={`${styles.submitBtn} ${
                  (formik.isSubmitting ||
                    !formik.isValid ||
                    !formik.values.acceptTerms ||
                    isInValid) &&
                  styles.disableBtn
                }`}
              >
                {!loading && <span>Next</span>}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait... <span className={styles.spinner}></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
        <div className={styles.loginBox}>
          <div className={styles.loginFirst}>Already have an account?</div>
          <Link to='/login' className={styles.loginSecond}>
            Login
          </Link>
        </div>
      </motion.main>
    </section>
  );
}
