import { useNavigate } from 'react-router-dom';
import { useLogo } from '../../../../helpers/hooks/LogoContext';
import { useModal } from '../../../../helpers/hooks/modalContext';
import styles from '../../../../helpers/Modals/SuccessfulProperty/SuccessfulProperty.module.css';
import { useTheme } from '../../../../helpers/hooks/ThemeContext';

const SuccessfulSubscription = () => {
  const { logoUrl, logoLoading } = useLogo();
  const { setModal } = useModal();
  const navigate = useNavigate();
  const { theme } = useTheme();
  const isDark = theme === 'dark';

  return (
    <main className={`${styles.main} ${isDark && styles.mainDark}`}>
      <section className={styles.section}>
        <div className={styles.message}>
          {!logoLoading && <img src={logoUrl} className={styles.logo} alt='logo' />}
          <h2 className={`${styles.heading} ${isDark && styles.headingDark}`}>
            Subscription added successfully!
          </h2>
          <div className={styles.messageContent}>
            Your subscription has been successfully set up.
          </div>

          <button
            className={styles.closeBtn}
            onClick={() => {
              navigate('/dashboard');
              setModal(null);
            }}
          >
            Return to Dashboard
          </button>
        </div>
      </section>
    </main>
  );
};
export default SuccessfulSubscription;
